import React, { useEffect, useState } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Button, Container, Grid, TextField, Modal, Typography, MenuItem, ListItemIcon, Divider, FormHelperText, IconButton } from '@mui/material';
import { connect, useDispatch } from 'react-redux';
import { add_dropdown_type, create_dropdown_type, delete_dropdown_type, get_dropdown_types, update_dropdown_type } from '../../redux.js/actions';
import LoadingSpinner from '../../components/loader/LoadingSpinner';
import { headingColor } from '../../util/colors';
import { Cancel, Delete, Update } from '@mui/icons-material';
import { DROPDOWN_TYPES } from '../../redux.js/actionType';
import { hasPermission } from '../../util/Fun';

const Dropdowns_type = (props) => {
    const dispatch = useDispatch()
    const [data, setData] = useState({ openModal: false, title: '', type: '', selectedDropdownType: null, isLoading: false });
    const [errors, setErrors] = useState({ title: '', type: '' });

    const columns = [
        { field: 'dropdown_type_id', headerName: 'Id', flex: 1 },
        { field: 'title', headerName: 'Title', flex: 1 },
        { field: 'type', headerName: 'Type', flex: 1 },
        {
            field: 'is_active', headerName: 'is Active', flex: 1,
            renderCell: (params) => (
                <>
                    {params.row.is_active == 1 ?
                        <a style={{ color: "green" }}>Acitve</a> :
                        <a style={{ color: 'red' }}>Disable</a>
                    }

                </>
            ),
        },
        {
            field: 'action',
            headerName: 'Action',
            flex: 1,
            renderCell: (params) => (
                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}>
                    {hasPermission("update_dropdown_type", props.user_data.permissions) &&
                        <IconButton onClick={() => onPressUpdate(params.row)}>
                            <Update fontSize="small" />
                        </IconButton>
                    }
                    {hasPermission("delete_dropdown_type", props.user_data.permissions) &&
                        <IconButton onClick={() => handleDelete(params.row.dropdown_type_id)}>
                            <Delete fontSize="small" />
                        </IconButton>
                    }
                </div>
            ),
        },
    ];


    const fetchDropdownTypes = async () => {
        try {
            setData(prevData => ({ ...prevData, isLoading: true }));
            const result = await props.get_dropdown_types(`api/get_dropdowns_types?id=${props.user_data.id}`, props.token);
            dispatch({ type: DROPDOWN_TYPES, payload: result.success ? result.data : [] })
            setData(prevData => ({ ...prevData, isLoading: false }));
        } catch (error) {
            console.error('Error fetching dropdown types:', error);
            setData(prevData => ({ ...prevData, isLoading: false }));
        }
    };

    const handleOpenModal = () => {
        setData(prevData => ({ ...prevData, openModal: true }));
    };

    const handleCloseModal = () => {
        setData(prevData => ({ ...prevData, openModal: false, selectedDropdownType: null, title: '', type: '' }));
        setErrors({ title: '', type: '' });
    };

    const handleInputChange = (e) => {
        setData(prevData => ({ ...prevData, [e.target.name]: e.target.value }));
        setErrors(prevErrors => ({ ...prevErrors, [e.target.name]: '' }));
    };

    const handleAddDropdownType = async () => {
        if (data.title.trim() === '') {
            setErrors(prevErrors => ({ ...prevErrors, title: 'Title is required' }));
            return;
        }
        if (data.type.trim() === '') {
            setErrors(prevErrors => ({ ...prevErrors, type: 'Type is required' }));
            return;
        }
        setData(prevData => ({ ...prevData, isLoading: true }));
        await props.create_dropdown_type(`api/create_dropdown_type?id=${props.user_data.id}`, props.token, { title: data.title, type: data.type });
        handleCloseModal();
        fetchDropdownTypes();
    };

    const handleUpdateDropdownType = async () => {
        if (!data.selectedDropdownType || data.title.trim() === '') {
            setErrors(prevErrors => ({ ...prevErrors, title: 'Title is required' }));
            return;
        }
        if (data.type.trim() === '') {
            setErrors(prevErrors => ({ ...prevErrors, type: 'Type is required' }));
            return;
        }
        setData(prevData => ({ ...prevData, isLoading: true }));
        await props.update_dropdown_type(`api/update_dropdown_type?id=${props.user_data.id}&dropdown_type_id=${data.selectedDropdownType.dropdown_type_id}`, props.token, { title: data.title, type: data.type });
        fetchDropdownTypes();
        handleCloseModal();
    };

    const handleDelete = async (dropdownTypeId) => {
        setData(prevData => ({ ...prevData, isLoading: true }));
        await props.delete_dropdown_type(`api/delete_dropdown_type?id=${props.user_data.id}`, props.token, { dropdown_type_id: dropdownTypeId });
        fetchDropdownTypes();
        handleCloseModal();
    };

    const onPressUpdate = (row) => {
        setData(prevData => ({ ...prevData, selectedDropdownType: row, title: row.title, type: row.type, openModal: true }));
    };

    return (
        <Container sx={{ pt: 5 }}>
            <Grid container columns={12} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                <h4 style={{ color: headingColor, fontWeight: 'bold' }}>Dropdown Types</h4>
                {hasPermission("create_dropdown_type", props.user_data.permissions) &&
                    <Button variant="outlined" onClick={handleOpenModal}>Create New Dropdown Type</Button>
                }
            </Grid>
            <DataGrid
                rows={props.dropdown_types}
                columns={columns}
                slots={{ toolbar: GridToolbar }}
                autoHeight
                rowHeight={50}
                getRowId={(row) => row.dropdown_type_id}
            />
            {data.isLoading && <LoadingSpinner />}
            <Modal
                open={data.openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Container sx={style}>
                    <Typography variant="h6" component="h2">{data.selectedDropdownType ? 'Update Dropdown Type' : 'Create New Dropdown Type'}</Typography>
                    <TextField
                        size='small'
                        label="Title"
                        variant="outlined"
                        fullWidth
                        name="title"
                        value={data.title}
                        onChange={handleInputChange}
                        error={!!errors.title}
                        helperText={errors.title}
                        sx={{ mt: 2 }}
                    />
                    <TextField
                        size='small'
                        label="Type"
                        variant="outlined"
                        fullWidth
                        name="type"
                        value={data.type}
                        onChange={handleInputChange}
                        error={!!errors.type}
                        helperText={errors.type}
                        sx={{ mt: 2 }}
                    />
                    <Button variant="contained" onClick={data.selectedDropdownType ? handleUpdateDropdownType : handleAddDropdownType} sx={{ mt: 2 }}>
                        {data.selectedDropdownType ? 'Update Dropdown Type' : 'Add Dropdown Type'}
                    </Button>
                </Container>
            </Modal>
        </Container>
    );
};

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const mapStateToPros = (state) => ({
    user_data: state.AuthReducer.user_data,
    token: state.AuthReducer.token,
    dropdown_types: state.rootReducer.dropdown_types
});

const mapDispatchToProps = {
    get_dropdown_types: get_dropdown_types,
    create_dropdown_type: create_dropdown_type,
    update_dropdown_type: update_dropdown_type,
    delete_dropdown_type: delete_dropdown_type,
};

export default connect(mapStateToPros, mapDispatchToProps)(Dropdowns_type);
