import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';

export default function Bar_chart() {
  return (
    <BarChart
      xAxis={[
        {
          id: 'barCategories',
          data: ['bar 1', 'bar 2', 'bar 3','bar 4','bar 5','bar 6','bar 7','bar 8','bar 9'],
          scaleType: 'band',
        },
      ]}
      series={[
        {
          data: [1,2,3,4,5,6,7,8,9],
        },
      ]}
    
    />
  );
}