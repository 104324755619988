import axios from 'axios';
import { api_base_url } from '../util/Constant';
import { toast } from 'react-toastify';

const Post = async ({ endPoint, token, data, timeout = 30000, content_Type = "application/json" }) => {
    console.log(endPoint,token,data)
    try {
        const response = await axios({
            method: 'post',
            url: api_base_url + endPoint,
            headers: token ? {
                'Authorization': token ? `bearer ${token}` : '',
                'Content-Type': content_Type
            } :
                {
                    'Content-Type': content_Type
                }
            ,
            data: data,
            timeout: timeout,
        });
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            if (error?.response) {
                if (error.response.status === 403) {
                    toast("Authorization error: You don't have permission to perform this action.", { type: 'error' });
                } else {
                    toast("Server error: Please try again later.", { type: 'error' });
                }
            } else if (error?.code === 'ECONNABORTED') {
                toast("Request timeout: Please try again.", { type: 'error' });
            } else {
                toast("Network error: Please check your internet connection.", { type: 'error' });
            }
        }
        console.log(error)
        return error.response;
    }
};

export default Post;
