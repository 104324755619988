import React, { useState, useEffect, useRef } from 'react';
import {
    AppBar,
    Dialog,
    IconButton,
    Slide,
    Toolbar,
    Typography,
    TextField,
    Button,
    Box,
    Grid,
    Paper,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    ListItemAvatar,
    Avatar,
    Checkbox,
} from '@mui/material';
import { Cancel, AddCircle } from '@mui/icons-material';
import { connect } from 'react-redux';
import axios from 'axios'; // Import Axios for HTTP requests
import { create_Steps } from '../../redux.js/actions';
import LoadingSpinner from '../loader/LoadingSpinner';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function ServiceTracking({ existingSteps = [], serviceId, isOpen, onClose, create_Steps, user_data, token }) {
    const [steps, setSteps] = useState([]);
    const [newStep, setNewStep] = useState('');
    const [fileRequired, setFileRequired] = useState(false);
    const [departmentName, setDepartmentName] = useState('');
    const [isLoading, setIsloading] = useState(false);
    const [deletedSteps, setDeletedSteps] = useState([]);
    const [editingStepIndex, setEditingStepIndex] = useState(null);
    const isInitialLoad = useRef(true);

    useEffect(() => {
        if (existingSteps.length > 0) {
            setSteps(existingSteps);
        }
    }, [existingSteps]);

    const handleAddStep = () => {
        if (newStep.trim() === '' || departmentName.trim() === '') {
            alert('Step description and department name cannot be empty.');
            return;
        }

        const step = {
            service_id: serviceId,
            step_description: newStep.trim(),
            file_required: fileRequired,
            department_name: departmentName.trim(),
            created_at: new Date().toISOString(), // Ensure the created_at field is present
        };

        if (editingStepIndex !== null) {
            const updatedSteps = [...steps];
            if (updatedSteps[editingStepIndex].step_id) {
                step.is_modify = true;
                step.step_id = updatedSteps[editingStepIndex].step_id;
            }
            updatedSteps[editingStepIndex] = step;
            setSteps(updatedSteps);
            setEditingStepIndex(null);
        } else {
            setSteps((prevSteps) => [...prevSteps, step]);
        }

        setNewStep('');
        setDepartmentName('');
        setFileRequired(false);
    };

    const handleDeleteStep = (index) => {
        const stepToDelete = steps[index];
        if (stepToDelete.step_id) {
            setDeletedSteps((prevDeleted) => [...prevDeleted, stepToDelete.step_id]);
        }
        const updatedSteps = [...steps];
        updatedSteps.splice(index, 1);
        setSteps(updatedSteps);
    };

    const handleEditStep = (index) => {
        const stepToEdit = steps[index];
        setNewStep(stepToEdit.step_description);
        setDepartmentName(stepToEdit.department_name);
        setFileRequired(stepToEdit.file_required);
        setEditingStepIndex(index);
    };

    const handleCancelEdit = () => {
        setNewStep('');
        setDepartmentName('');
        setFileRequired(false);
        setEditingStepIndex(null);
    };

    const handleSaveSteps = async () => {
        try {
            setIsloading(true);
    
            // Filter modified existing steps
            const modifiedSteps = steps.filter(step => step.is_modify);
    
            // Filter newly added steps (they won't have a step_id)
            const newSteps = steps.filter(step => !step.step_id);
    
            let result = await create_Steps(`api/add_steps?id=${user_data.id}`, token, { steps: [...modifiedSteps, ...newSteps], deletedSteps: deletedSteps });
            setIsloading(false);
            if (result) {
                setSteps([]);
                setDeletedSteps([]);
                setEditingStepIndex(null);
                onClose();
            }
        } catch (error) {
            console.error('Error saving steps:', error);
            // Handle error: display an error message or log it
        }
    };
    

    return (
        <Dialog fullScreen open={isOpen} onClose={onClose} TransitionComponent={Transition}>
            {isLoading && <LoadingSpinner />}
            <div style={{ backgroundColor: '#f0f0f0', height: '100vh', overflow: 'auto' }}>
                <AppBar position="sticky" style={{ backgroundColor: '#1976d2' }}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                            <Cancel />
                        </IconButton>
                        <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
                            Service Tracking
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Box p={2}>
                    <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
                        Add Steps for Service ID: {serviceId}
                    </Typography>
                    <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Enter step description"
                            value={newStep}
                            onChange={(e) => setNewStep(e.target.value)}
                            style={{ marginBottom: '20px' }}
                        />
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Enter department name"
                            value={departmentName}
                            onChange={(e) => setDepartmentName(e.target.value)}
                            style={{ marginBottom: '20px' }}
                        />
                        <Grid container spacing={2} alignItems="center">
                            <Grid item>
                                <Checkbox
                                    checked={fileRequired}
                                    onChange={(e) => setFileRequired(e.target.checked)}
                                    color="primary"
                                    inputProps={{ 'aria-label': 'file required checkbox' }}
                                />
                            </Grid>
                            <Grid item>
                                <Typography variant="body1" style={{ marginLeft: '10px' }}>
                                    File Submission Required
                                </Typography>
                            </Grid>
                            <Grid item style={{ marginLeft: 'auto' }}>
                                {editingStepIndex !== null ? (
                                    <>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            startIcon={<AddCircle />}
                                            onClick={handleAddStep}
                                        >
                                            Update Step
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            style={{ marginLeft: '10px' }}
                                            onClick={handleCancelEdit}
                                        >
                                            Cancel
                                        </Button>
                                    </>
                                ) : (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<AddCircle />}
                                        onClick={handleAddStep}
                                    >
                                        Add Step
                                    </Button>
                                )}
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    style={{ marginLeft: '10px' }}
                                    onClick={handleSaveSteps}
                                >
                                    Save Steps
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Typography variant="h6" mt={3}>
                        Current Steps
                    </Typography>
                    <List style={{ width: '100%', marginBottom: '20px' }}>
                        {steps.map((step, index) => (
                            <ListItem key={index} style={{ backgroundColor: '#ffffff', marginBottom: '10px' }} onClick={() => handleEditStep(index)}>
                                <ListItemAvatar>
                                    <Avatar>{index + 1}</Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={step.step_description}
                                    secondary={`${step.department_name} - ${step.file_required ? 'File required' : 'No file required'}`}
                                />
                                <ListItemSecondaryAction>
                                    <IconButton edge="end" aria-label="delete" onClick={(e) => { e.stopPropagation(); handleDeleteStep(index); }}>
                                        <Cancel />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </div>
        </Dialog>
    );
}

const mapStateToProps = (state) => ({
    user_data: state.AuthReducer.user_data,
    token: state.AuthReducer.token,
});
const mapDispatchToProps = {
    create_Steps: create_Steps,
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceTracking);
