import React, { useState } from 'react';
import { Box, Button, MenuItem, Select, FormControl, InputLabel, Typography, Divider } from '@mui/material';
import CustomModal from '../Common/CustomModal';
import { connect } from 'react-redux';

const AssignTask = (props) => {
    const [selectedUser, setSelectedUser] = useState('');

    const handleChange = (event) => {
        setSelectedUser(event.target.value);
    };

    const onSave = () => {
        props.handleSubmit(selectedUser)
        setSelectedUser('')
    }

    return (
        <CustomModal open={props.isAssign} handleClose={props.handleClose}>
            <Box sx={{ p: 2 }}>
                <FormControl fullWidth size="small" sx={{ mb: 2, minWidth: 200 }}>
                    <InputLabel>Select User</InputLabel>
                    <Select value={selectedUser} onChange={handleChange} label="Select User">
                        {props.active_portal_user.map((user) => (
                            <MenuItem key={user.id} value={user.id}>
                                <Box sx={{ width: "100%" }}>
                                    <Typography variant="body1">{user.full_name}</Typography>
                                    <Divider />
                                    <Typography variant="body2" color="textSecondary">
                                        {user.email}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        {user.district_name}
                                    </Typography>
                                </Box>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Button variant="contained" color="primary" onClick={() => onSave()}>
                    Assign Task
                </Button>
            </Box>
        </CustomModal>
    );
};


const mapStateToPros = (state) => ({
    active_portal_user: state.rootReducer.active_portal_user
})



export default connect(mapStateToPros, null)(AssignTask)