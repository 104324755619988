import { Box, ButtonBase, Container, Divider, Grid, Paper, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { headingColor } from '../../util/colors'
import LoadingSpinner from '../../components/loader/LoadingSpinner'
import { connect } from 'react-redux'
import { get_all_services } from '../../redux.js/actions'
import { groupByCategory } from '../../util/Fun'
import { useNavigate } from 'react-router-dom'

function NewTask(props) {
  const navigate = useNavigate();
  const [services, setServices] = useState([])
  const [isLoading, setIslaoding] = useState(false)
  useEffect(() => {
    getServices()
  }, [])
  const getServices = async () => {
    setIslaoding(true)
    let result = await props.get_all_services(`api/get_All_Services?id=${props.user_data.id}`, props.token);
    setIslaoding(false)
    setServices(result.success ? groupByCategory(result.data) : []);
  };

  const handleNavigation = (item) => {
    navigate('/Registration_Form ', { state: { item }});
  }
  return (
    <Container sx={{ pt: 5 }}>
      <Grid columns={12} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant="h5" sx={{ mb: 1, color: headingColor }}>Service Registration</Typography>
      </Grid>
      <Divider />
      {isLoading && <LoadingSpinner />}

      <Grid sx={{ flexGrow: 1, mt: 2 }}>
        {services.map((v, i) => (
          <React.Fragment key={i}>
            <Typography variant="h5" sx={{ mb: 1, color: headingColor }}>{v.category}</Typography>
            <Box sx={{ display: 'flex' }}>
              {v.services.map((item, index) => (
                <ButtonBase
                  key={index}
                  sx={{
                    width: 200,
                    height: 200,
                    m: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    p: 2,
                  }}
                  onClick={() => handleNavigation(item)}
                >
                  <Paper elevation={2} sx={{ width: 200, height: 200, m: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', p: 2 }}>
                    <Box
                      component="img"
                      sx={{ width: '100%', height: 100, objectFit: 'contain', mb: 1 }}
                      src={item.icon}
                      alt={item.title}
                    />
                    <Typography sx={{ fontSize: 18, textAlign: 'center', mb: 1 }}>
                      {item.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      component="p"
                      sx={{
                        textAlign: 'center',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: 1,
                        WebkitBoxOrient: 'vertical',
                      }}
                    >
                      {item.description}
                    </Typography>
                  </Paper>
                </ButtonBase>

              ))}
            </Box>

          </React.Fragment>
        ))}
      </Grid>
    </Container>
  )
}

const mapStateToPros = (state) => ({
  user_data: state.AuthReducer.user_data,
  token: state.AuthReducer.token,
});

const mapDispatchToProps = {
  get_all_services: get_all_services,
};

export default connect(mapStateToPros, mapDispatchToProps)(NewTask);