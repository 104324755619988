import { AppBar, Badge, Box, Button, Dialog, Grid, IconButton, ListItemIcon, MenuItem, Paper, Slide, Toolbar, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import DashboardCard from '../components/Dashboard_card'
import { Cancel, ContentPasteOffSharp, Notifications, PendingActionsRounded, QuickreplyRounded, TaskAlt, Today, Update } from '@mui/icons-material'
import Pie_chart from '../components/charts/Pie_chart'
import Bar_chart from '../components/charts/Bar_chart'
import { DataGrid, GridToolbar, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton, GridRowHeightParams } from '@mui/x-data-grid'
import { connect } from 'react-redux'
import { assign_task, get_all_registration, get_dashboardCount, get_flowup, setFormData } from '../redux.js/actions'
import { formatNotificationTime, hasPermission } from '../util/Fun'
import CustomModal from '../components/Common/CustomModal'
import AssignTask from '../components/dashboard/AssignTask'
import AccountMenu from '../components/Common/MoreMenu'
import FlowUp from '../components/dashboard/FlowUp'
import LoadingSpinner from '../components/loader/LoadingSpinner'
import Tracking from '../components/dashboard/Tracking'
import ViewInstantDetail from '../components/dashboard/ViewInstantDetail'
import Editinstance from '../components/dashboard/Editinstance'
import UserFlowUp from '../components/dashboard/UserFlowUp'
import { format } from 'date-fns'

const Dashboard = (props) => {
  const [is_assign, setIs_assign] = useState(null)
  const [is_flowUp, setIs_flowUp] = useState(null)
  const [is_tracking, setIs_Tracking] = useState(null)
  const [isViewInstance, setIsViewInstance] = useState(null)
  const [isEditInstance, setIsEditInstance] = useState(null)
  const [folowUps, setFolowUps] = useState([])
  const [isLoading, setIsloading] = useState(false)
  const [userFlowUp, setUserFlowUp] = useState(null)

  const sortRegistrations = (registrations) => {
    return registrations.sort((a, b) => {
      if (b.notifications - a.notifications !== 0) {
        return b.notifications - a.notifications;
      }
      if (new Date(b.instance.created_at) - new Date(a.instance.created_at) !== 0) {
        return new Date(b.instance.created_at) - new Date(a.instance.created_at);
      }
      return b.instance.id - a.instance.id;
    });
  };

  const rows = props.all_registration ? sortRegistrations(props.all_registration).map((data, index) => ({
    id: index,
    service_id: data.service.id,
    registration_id: data.instance.id,
    instant_created_by: data.instance.instant_created_by,
    full_name: data.user.full_name,
    email: data.user.email,
    service_title: data.service.title,
    status: data.instance.status,
    created_at: data.instance.created_at,
    assign_to_name: data.assign.full_name,
    assign_to_email: data.assign.email,
    client_id: data.user.id,
    notifications: data.notifications
  })) : [];
  useEffect(() => {
    if (hasPermission("get_all_registration", props.user_data.permissions)) {
      LoadAllRegistration()
    }
    if (hasPermission("get_dashboardCount", props.user_data.permissions)) {
      props.get_dashboardCount(`api/get_dashboardCount?id=${props.user_data.id}`, props.token);
    }
  }, []);


  const LoadAllRegistration = () => {
    props.get_all_registration(`api/get_all_registration?id=${props.user_data.id}&role_id=${props.user_data.role_id}`, props.token);
  }

  const handleFlowup = async (id) => {
    if (hasPermission("add_flowup", props.user_data?.permissions)) {
      setIsloading(true)
      let result = await props.get_flowup(`api/get_flowup?id=${props.user_data.id}&instance_id=${id}`, props.token);
      if (result.success) {
        setFolowUps(result.data)
        setIs_flowUp(id)
      }
      setIsloading(false)
    }
  };

  const handleUserFlowUp = async (item) => {
    setUserFlowUp(item)
  }

  const handleAssign = async (value) => {
    if (value && is_assign) {
      await props.assign_task(`api/assign_task?id=${props.user_data.id}`, props.token, {
        instant_id: is_assign,
        userId: value
      });
      props.get_all_registration(`api/get_all_registration?id=${props.user_data.id}`, props.token);
    }
    setIs_assign(null)
  };

  const handleTracking = (data) => {
    setIs_Tracking(data)
  };

  const handleView = (id) => {
    setIsViewInstance(id)
  };

  const handleEdit = (id) => {
    setIsEditInstance(id)
  };

  let hasTaskPermission = hasPermission("assign_task", props.user_data?.permissions)
  const columns = [
    { field: 'registration_id', headerName: 'REG ID' },
    { field: 'full_name', headerName: 'UserName', flex: 1 },
    { field: 'email', headerName: 'Email', flex: 1 },
    { field: 'service_title', headerName: 'ServiceName', flex: 1 },
    {
      field: 'created_at', headerName: 'RegistrationDate', flex: 1, renderCell: (params) => {
        return (
          <Typography>{formatNotificationTime(params.row.created_at)}</Typography>
        )
      }
    },
    {
      field: 'assign_to_name',
      headerName: 'Assign To',
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2">{params.row.assign_to_name}</Typography>
            <Typography variant="body2" color="textSecondary">{params.row.assign_to_email}</Typography>
          </div>
        )
      }
    },
    // { field: 'assign_to_email', headerName: 'Assign To Email', flex: 1 },
    { field: 'status', headerName: 'Status' },
    {
      field: 'customColumn',
      headerName: 'Actions',
      renderCell: (params) => {
        return (

          <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
            {hasPermission("instance_notifications_count", props.user_data?.permissions) &&
              <Badge color="secondary" badgeContent={params?.row?.notifications} onClick={() => handleUserFlowUp(params.row)} style={{ cursor: 'pointer' }}>
                <Notifications />
              </Badge>
            }
            <AccountMenu>
              {hasTaskPermission && params.row.instant_created_by == props.user_data.id &&
                <MenuItem onClick={() => setIs_assign(params.row.registration_id)}>
                  <ListItemIcon>
                    <Update fontSize="small" />
                  </ListItemIcon>
                  ASSIGN
                </MenuItem>
              }
              {hasPermission("get_steps", props.user_data?.permissions) &&
                <MenuItem onClick={() => handleTracking(params.row)}>
                  <ListItemIcon>
                    <Update fontSize="small" />
                  </ListItemIcon>
                  TRACKING
                </MenuItem>
              }
              {/* <MenuItem onClick={() => handleUserFlowUp(params.row)}>
              <ListItemIcon>
                <Update fontSize="small" />
              </ListItemIcon>
              USER FLOWUP
            </MenuItem> */}
              {
                hasPermission("get_flowup", props.user_data?.permissions) &&
                <MenuItem onClick={() => handleFlowup(params.row.registration_id)}>
                  <ListItemIcon>
                    <Update fontSize="small" />
                  </ListItemIcon>
                  FLOWUP
                </MenuItem>
              }
              <MenuItem onClick={() => handleEdit(params.row.registration_id)}>
                <ListItemIcon>
                  <Update fontSize="small" />
                </ListItemIcon>
                EDIT
              </MenuItem>
              <MenuItem onClick={() => handleView(params.row.registration_id)}>
                <ListItemIcon>
                  <Update fontSize="small" />
                </ListItemIcon>
                VIEW
              </MenuItem>
            </AccountMenu>
          </div>
        )
      }

    },
  ];

  return (
    <React.Fragment>
      {isLoading && <LoadingSpinner />}
      <Box component="main" sx={{ flexGrow: 1, pt: 6, pl: 5, pr: 3 }}>
        <Grid container spacing={2} columns={12}>
          <Grid item lg={3} md={3} xs={12} sm={6}>
            <Paper elevation={3} sx={{ p: 1.5 }}>
              <DashboardCard title="Completed" icon={<TaskAlt fontSize='large' />} />
              <span className='card-value'>{props?.dashboard_count?.total_completed}</span>
            </Paper>
          </Grid>
          <Grid item lg={3} md={3} xs={12} sm={6}>
            <Paper elevation={3} sx={{ p: 1.5 }}>
              <DashboardCard title="Today Task" icon={<Today fontSize='large' />} />
              <span className='card-value'>{props?.dashboard_count?.today_registrations}</span>
            </Paper>
          </Grid>
          <Grid item lg={3} md={3} xs={12} sm={6}>
            <Paper elevation={3} sx={{ p: 1.5 }}>
              <DashboardCard title="Ugent Task" icon={<QuickreplyRounded fontSize='large' />} />
              <span className='card-value'>{props?.dashboard_count?.total_urgent}</span>
            </Paper>
          </Grid>
          <Grid item lg={3} md={3} xs={12} sm={6}>
            <Paper elevation={3} sx={{ p: 1.5 }}>
              <DashboardCard title="Total Pending" icon={<PendingActionsRounded fontSize='large' />} />
              <span className='card-value'>{props?.dashboard_count?.total_pending}</span>
            </Paper>
          </Grid>
        </Grid>

        <Grid container columns={12} spacing={2}>
          {hasPermission("bar_chart", props.user_data.permissions) &&
            <Grid item lg={7} md={7} xs={12} sm={7} sx={{ mt: 3 }}>
              <Paper elevation={3} sx={{ flex: 1, alignItems: 'center', justifyContent: 'center', display: 'flex', height: 400 }}>
                <Bar_chart />
              </Paper>
            </Grid>
          }
          {hasPermission("pie_chart", props.user_data.permissions) &&
            <Grid item lg={5} md={5} xs={12} sm={5} sx={{ mt: 3 }}>
              <Paper elevation={3} sx={{ flex: 1, alignItems: 'center', justifyContent: 'center', display: 'flex', height: 400 }}>
                <Pie_chart />
              </Paper>
            </Grid>
          }
        </Grid>

        <Box sx={{ pt: 5, pb: 5, flexGrow: 1, width: '100%' }}>
          <DataGrid rows={rows}
            columns={columns}
            slots={{ toolbar: GridToolbar }}
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            pagination
            pageSizeOptions={[10, 25, 50, 100]}
          />
        </Box>

      </Box>
      {is_assign &&
        <AssignTask
          handleClose={() => setIs_assign(null)}
          isAssign={is_assign ? true : false}
          handleSubmit={(value) => handleAssign(value)}
        />
      }
      {is_flowUp &&
        <FlowUp
          data={folowUps}
          onClose={() => setIs_flowUp(null)}
          is_flowUp={is_flowUp}
        />
      }
      {is_tracking &&
        <Tracking
          onClose={() => setIs_Tracking(null)}
          isOpen={is_tracking ? true : false}
          service={is_tracking}
        />
      }
      {isViewInstance &&
        <ViewInstantDetail
          onClose={() => setIsViewInstance(null)}
          isOpen={isViewInstance ? true : false}
          instance_id={isViewInstance}
        />
      }
      {isEditInstance &&
        <Editinstance
          onClose={() => setIsEditInstance(null)}
          isOpen={isEditInstance ? true : false}
          instance_id={isEditInstance}
          onSuccess={() => [LoadAllRegistration(), setIsEditInstance(null)]}
        />
      }
      {userFlowUp &&
        <UserFlowUp
          onCLose={() => setUserFlowUp(null)}
          item={userFlowUp}
        />
      }

    </React.Fragment>

  )
}


const mapStateToPros = (state) => ({
  token: state.AuthReducer.token,
  user_data: state.AuthReducer.user_data,
  service_categories: state.rootReducer.service_categories,
  all_registration: state.rootReducer.all_registration,
  dashboard_count: state.rootReducer.dashboard_count,
  active_portal_user: state.rootReducer.active_portal_user
})
const mapStateToDispatch = {
  get_all_registration: get_all_registration,
  get_dashboardCount: get_dashboardCount,
  assign_task: assign_task,
  get_flowup: get_flowup,
}


export default connect(mapStateToPros, mapStateToDispatch)(Dashboard)