import React, { useEffect } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Dashboard from '../pages/Dashboard';
import MiniDrawer from '../components/side_bar/SideBar';
import Users from '../pages/Users';
import PortalUsers from '../pages/Portal_users';
import Login from '../pages/Login';
import { connect, useDispatch } from 'react-redux';
import Roles from '../pages/Roles/Roles';
import { get_current_version } from '../redux.js/actions/AuthAction';
import NotFound from '../pages/NotFound';
import NewUser from '../pages/NewUser';
import LoadingSpinner from '../components/loader/LoadingSpinner';
import AddUpdateService from '../pages/services/AddUpdateService';
import ViewServices from '../pages/services/ViewServices';
import Permissions from '../pages/Roles/Permissions';
import Pages from '../pages/Roles/Pages';
import Dropdowns from '../pages/Dropdown/Dropdowns';
import Dropdowns_type from '../pages/Dropdown/Dropdowns_type';
import Registration_Form from '../pages/services/Registration_Form';
import { ADD_NOTIFICATION, ALL_DROPDOWNS, DROPDOWN_TYPES, INPUT_TYPES, NOTIFICATIONS, TRUST_CATEGORY, USER_ROLE } from '../redux.js/actionType';
import { get_dropdown_types, get_dropdowns_by_key, get_active_portal_user } from '../redux.js/actions';
import NewTask from '../pages/services/NewTask';
import { checkPagesPermission, hasPermission } from '../util/Fun';
import Complaints from '../pages/complaints/Complaints';
import socket from '../config/SockerConfig';
import MessageCenter from '../pages/messageCenter/MessageCenter';

const MainRoute = (props) => {
    const dispatch = useDispatch()
    useEffect(() => {
        if (props.is_login) {
            socket.on('connect', () => {
                let data = { id: props.user_data.id, socket_id: socket.id };
                socket.emit('update_socket_id', data);
                socket.on("notification", (data) => {
                    dispatch({ type: ADD_NOTIFICATION, payload: data })
                })
            });

            GetDropdownTypes();
            Get_active_portal_user();
            GetRolesDropdown();
            getAllDropdowns();
            getAllNotifications();
        }
    }, [props.is_login])

    const GetRolesDropdown = async () => {
        const result = await props.get_dropdown_types(`api/get_dropdowns_by_key?id=${props.user_data.id}&key=get_roles_dropdowns`, props.token);
        dispatch({ type: ALL_DROPDOWNS, payload: result.success ? { roles: result.data } : [] })
    }
    const GetDropdownTypes = async () => {
        const result = await props.get_dropdown_types(`api/get_dropdowns_types?id=${props.user_data.id}`, props.token);
        dispatch({ type: DROPDOWN_TYPES, payload: result.success ? result.data : [] })
    }
    const Get_active_portal_user = async () => {
        await props.get_active_portal_user(`api/get_active_portal_user?id=${props.user_data.id}`, props.token);
    }

    const getAllDropdowns = async () => {
        let result = await props.get_dropdowns_by_key(`api/get_dropdowns_by_key?id=${props.user_data.id}&key=districts,inputTypes,trustRegistrationType,servicesCategory,trustRadio,trustCheckboxes,trustDropdowns,trustRegistrationActions`, props.token);
        // console.log("result of all dropdwon",result)
        dispatch({ type: ALL_DROPDOWNS, payload: result.success ? result.data : [] });
    }

    const getAllNotifications = async () => {
        let result = await props.get_dropdowns_by_key(`api/get_all_notification?id=${props.user_data.id}`, props.token);
        dispatch({ type: NOTIFICATIONS, payload: result.success ? result.data : [] });
    }

    return (
        <BrowserRouter>
            <Routes>
                {!props.is_login ?
                    <Route path="/*" element={<Login />} />
                    :
                    <Route path="/*" element={<MiniDrawerWithRoutes {...props} />} />
                }
            </Routes>
        </BrowserRouter>
    );
};
const MiniDrawerWithRoutes = (props) => {
    return (
        <MiniDrawer>
            <Routes>
                {checkPagesPermission("Dashboard", props.user_data.pages) &&
                    <Route index element={<Dashboard />} />
                }
                {checkPagesPermission("Users", props.user_data.pages) &&
                    <Route path="users" element={<Users />} />
                }
                {hasPermission('service_instant', props.user_data.permissions) &&
                    <Route path="newTask" element={<NewTask />} />
                }
                {hasPermission('create_account', props.user_data.permissions) &&
                    <Route path="NewUser" element={<NewUser />} />
                }
                {hasPermission("get_portal_user", props.user_data.permissions) &&
                    <Route path="portal_users" element={<PortalUsers />} />
                }
                {hasPermission("get_roles", props.user_data.permissions) &&
                    <Route path="roles" element={<Roles />} />
                }
                {checkPagesPermission("Permissions", props.user_data.pages) &&
                    <Route path="permissions" element={<Permissions />} />
                }
                {checkPagesPermission("Pages", props.user_data.pages) &&
                    <Route path="Pages" element={<Pages />} />
                }
                {checkPagesPermission("Dropdowns", props.user_data.pages) &&
                    <Route path="dropdowns" element={<Dropdowns />} />
                }
                {checkPagesPermission("Dropdowns Type", props.user_data.pages) &&
                    <Route path="dropdowns_type" element={<Dropdowns_type />} />
                }
                <Route path="Registration_Form" element={<Registration_Form />} />

                <Route path="AddUpdateService" element={<AddUpdateService />} />

                {/* <Route path="MessageCenter" element={<MessageCenter/>} /> */}

                {hasPermission("get_All_Services", props.user_data.permissions) &&
                    <Route path="ViewServices" element={<ViewServices />} />
                }
                <Route path="Complaints" element={<Complaints />} />
                <Route path='*' element={<NotFound />} />
            </Routes>
            {props.global_loader &&
                <LoadingSpinner />
            }
        </MiniDrawer>
    );
};

const mapStateToProps = (state) => ({
    is_login: state.AuthReducer.is_login,
    current_version: state.AuthReducer.current_version,
    global_loader: state.AuthReducer.global_loader,
    user_data: state.AuthReducer.user_data,
    token: state.AuthReducer.token,
});

const mapStateToDispatch = {
    get_current_version: get_current_version,
    get_dropdown_types: get_dropdown_types,
    get_dropdowns_by_key: get_dropdowns_by_key,
    get_active_portal_user: get_active_portal_user,
};

export default connect(mapStateToProps, mapStateToDispatch)(MainRoute);
