import React, { useEffect, useState } from 'react';
import { FormControl, FormHelperText, InputLabel, Grid, Box, Container, Button, TextField, Select, MenuItem, Dialog, AppBar, Toolbar, IconButton, Typography, Slide } from '@mui/material';
import { connect, useDispatch } from 'react-redux';
import { Cancel } from '@mui/icons-material';
import LoadingSpinner from '../loader/LoadingSpinner';
import { Update_User } from '../../redux.js/actions';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const UpdateUser = ({ isOpen, onClose, formData, all_dropdowns, user_data, Update_User, token }) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false)
    const [values, setValues] = useState({
        full_name: '',
        email: '',
        phone: '',
        address: '',
        username: '',
        password: '',
        confirm_password: '',
        role_id: '',
        user_type: '',
        district: '',
    });
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (formData) {
            setValues({
                full_name: formData.full_name || '',
                email: formData.email || '',
                phone: formData.phone || '',
                address: formData.address || '',
                username: formData.username || '',
                password: '',
                confirm_password: '',
                role_id: formData.role_id || '',
                user_type: formData.user_type || '',
                district: formData.district || '',
            });
        }
    }, [formData]);

    const handleChange = name => event => {
        setValues({ ...values, [name]: event.target.value });
        setErrors({ ...errors, [name]: false });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        let formErrors = {};
        let hasErrors = false;

        // Validate required fields
        if (!values.full_name) {
            formErrors.full_name = "Full name is required.";
            hasErrors = true;
        }
        if (!values.phone) {
            formErrors.phone = "Phone is required.";
            hasErrors = true;
        }
        if (values.password && values.password !== values.confirm_password) {
            formErrors.confirm_password = "Confirm password does not match with password.";
            hasErrors = true;
        }

        if (hasErrors) {
            setErrors(formErrors);
        } else {
            setIsLoading(true);
            // Prepare updated fields to send to API
            const updatedFields = {
                id: formData.id
            };
            for (const key in values) {
                // Exclude confirm_password and empty values
                if (key !== 'confirm_password' && values[key] !== formData[key] && values[key] !== '') {
                    updatedFields[key] = values[key];
                }
            }

            let result = await Update_User(`api/update_user?id=${user_data.id}`, token, updatedFields);
            setIsLoading(false);
            if (result.success) {
                onClose()
            }
        }
    };


    return (
        <Dialog fullScreen open={isOpen} onClose={onClose} TransitionComponent={Transition}>
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                        <Cancel />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Update User
                    </Typography>
                </Toolbar>
            </AppBar>
            {isLoading && <LoadingSpinner />}
            <Container sx={{ mt: 5 }}>
                <form onSubmit={handleSubmit}>
                    <Box sx={{ flexGrow: 1, pt: 1 }}>
                        <Grid container spacing={{ xs: 0, md: 1 }} columns={{ xs: 1, sm: 8, md: 12 }}>
                            <Grid item xs={1} sm={4} md={4}>
                                <TextField
                                    size='small'
                                    label="Name"
                                    fullWidth
                                    value={values.full_name}
                                    onChange={handleChange("full_name")}
                                    margin="dense"
                                    error={Boolean(errors.full_name)}
                                    type='text'
                                />
                                {errors.full_name && <FormHelperText error>{errors.full_name}</FormHelperText>}
                            </Grid>
                            <Grid item xs={1} sm={4} md={4}>
                                <TextField
                                    disabled={true}
                                    id="standard-name"
                                    label="Email"
                                    fullWidth
                                    value={values.email}
                                    size='small'
                                    margin="dense"
                                    error={Boolean(errors.email)}

                                />
                                {errors.email && <FormHelperText error>{errors.email}</FormHelperText>}
                            </Grid>
                            <Grid item xs={1} sm={4} md={4}>
                                <TextField
                                    label="Phone"
                                    type='number'
                                    fullWidth
                                    value={values.phone}
                                    onChange={handleChange("phone")}
                                    size='small'
                                    margin="dense"
                                    error={Boolean(errors.phone)}
                                />
                                {errors.phone && <FormHelperText error>{errors.phone}</FormHelperText>}
                            </Grid>
                            <Grid item xs={1} sm={4} md={4}>
                                <TextField
                                    label="Address"
                                    fullWidth
                                    value={values.address}
                                    onChange={handleChange("address")}
                                    size='small'
                                    margin="dense"
                                    error={Boolean(errors.address)}
                                />
                                {errors.address && <FormHelperText error>{errors.address}</FormHelperText>}
                            </Grid>
                            {(formData.user_type !== 'super_admin' && formData.user_type !== 'user') &&
                                <Grid item xs={1} sm={4} md={4}>
                                    <FormControl sx={{ mt: 1 }} size='small' fullWidth>
                                        <InputLabel>Role Type</InputLabel>
                                        <Select
                                            fullWidth
                                            size='small'
                                            margin="dense"
                                            value={values.role_id}
                                            label="Role Type"
                                            onChange={handleChange("role_id")}
                                            error={Boolean(errors.role_id)}
                                        >
                                            {all_dropdowns.roles && all_dropdowns.roles.length > 0 ? (
                                                all_dropdowns.roles.map((v, i) => (
                                                    <MenuItem key={i} value={v.role_id}>{v.role_name}</MenuItem>
                                                ))
                                            ) : null}
                                        </Select>
                                        {errors.role_id && <FormHelperText error>{errors.role_id}</FormHelperText>}
                                    </FormControl>
                                </Grid>
                            }
                            {values.user_type !== 'user' && values.user_type &&
                                <Grid item xs={1} sm={4} md={4}>
                                    <FormControl sx={{ mt: 1 }} size='small' fullWidth>
                                        <InputLabel>Select Districts</InputLabel>
                                        <Select
                                            fullWidth
                                            size='small'
                                            margin="dense"
                                            value={values.district}
                                            label="Select Districts"
                                            onChange={handleChange("district")}
                                            error={Boolean(errors.district)}
                                        >
                                            {all_dropdowns.districts && all_dropdowns.districts.length > 0 ? (
                                                all_dropdowns.districts.map((v, i) => (
                                                    <MenuItem key={i} value={v.dropdown_id}>{v.title}</MenuItem>
                                                ))
                                            ) : null}
                                        </Select>
                                        {errors.district && <FormHelperText error>{errors.district}</FormHelperText>}
                                    </FormControl>
                                </Grid>
                            }
                            <Grid item xs={1} sm={4} md={4}>
                                <TextField
                                    label="Password"
                                    fullWidth
                                    value={values.password}
                                    onChange={handleChange("password")}
                                    size='small'
                                    margin="dense"
                                    error={Boolean(errors.password)}
                                />
                                {errors.password && <FormHelperText error>{errors.password}</FormHelperText>}
                            </Grid>
                            <Grid item xs={1} sm={4} md={4}>
                                <TextField
                                    label="Confirm Password"
                                    fullWidth
                                    value={values.confirm_password}
                                    onChange={handleChange("confirm_password")}
                                    size='small'
                                    margin="dense"
                                    error={Boolean(errors.confirm_password)}
                                />
                                {errors.confirm_password && <FormHelperText error>{errors.confirm_password}</FormHelperText>}
                            </Grid>
                        </Grid>
                        <Button variant="contained" color="primary" type="submit" sx={{ mt: 2 }}>
                            UPDATE USER
                        </Button>
                    </Box>
                </form>
            </Container>
        </Dialog>
    );
};

const mapStateToProps = (state) => ({
    user_data: state.AuthReducer.user_data,
    token: state.AuthReducer.token,
    all_dropdowns: state.rootReducer.all_dropdowns,
});

const mapDispatchToProps = {
    Update_User: Update_User,
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateUser);
