import { ListItemButton } from '@mui/material';
import React from 'react';

function List_item_button({ open, onClick, children }) {
    return (
        <ListItemButton
            sx={{
                minHeight: 40,
                height: 'auto',
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
                py: 0
            }}
            onClick={onClick}
        >
            {children}
        </ListItemButton>
    );
}

export default List_item_button;
