// Multi_button.js

import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

function Multi_button({ totalCount = 1, updatedCount }) {
    const [count, setCount] = useState(totalCount);
    useEffect(() => {
        setCount(totalCount)
    }, [totalCount])
    const increaseCount = () => {
        const newCount = count + 1;
        setCount(newCount);
        updatedCount(newCount);
    };

    const decreaseCount = () => {
        if (count > 1) {
            const newCount = count - 1;
            setCount(newCount);
            updatedCount(newCount);
        }
    };

    return (
        <div>
            <Button variant="contained" color="primary" onClick={increaseCount} startIcon={<AddIcon />}>
                Increase
            </Button>
            <span style={{ padding: 10, fontWeight: 'bold', color: "#ccc" }}>{count}</span>
            <Button variant="contained" color="secondary" onClick={decreaseCount} startIcon={<RemoveIcon />}>
                Decrease
            </Button>
        </div>
    );
}

export default Multi_button;
