import { Box, Button, Checkbox, Container, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, InputLabel, MenuItem, OutlinedInput, Radio, RadioGroup, Select, TextField, Typography } from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { headingColor } from '../../util/colors';
import { connect, useDispatch } from 'react-redux';
import { add_service_instant, get_dropdowns_by_key } from '../../redux.js/actions';
import Multi_button from '../../components/Common/Multi_button';
import { v4 as uuidv4 } from 'uuid';
import SearchUser from '../../components/Common/SearchUser';

function Registration_Form(props) {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate()
    const { item } = location.state || {};
    const [parameters, setParameters] = useState([]);
    const [formState, setFormState] = useState({});
    const [dropdownOptions, setDropdownOptions] = useState({});
    const [errors, setErrors] = useState({});
    const [multiCount, setMultiCount] = useState({});
    const [registration_for_user, setRegistration_for_user] = useState(null)
    const InputTypes = props.all_dropdowns?.inputTypes || []

    useEffect(() => {
        if (item) {
            const row = item.parameters.map(param => transformParameter(param));
            setParameters(row);
            const dropdownKeys = extractDropdownKeys(row);
            fetchDropdownOptions(dropdownKeys);
        }
    }, [item]);

    const transformParameter = (param) => {
        const transformedParam = {
            ...param,
            unique_id: uuidv4(), // Add a unique ID
            required: stringToBoolean(param.required),
            options: param.options ? parseOptions(param.options) : {},
            ...parseOptions(param.options),
            sub_parameters: param.sub_parameters ? transformSubParameters(param.sub_parameters) : []
        };

        if (checkInputType(param.type) === 'multi') {
            transformedParam.sub_parameters = [[...transformedParam.sub_parameters]];
        }

        return transformedParam;
    };

    const transformSubParameters = (subParams) => {
        return subParams.map(subParam => {
            const transformedSubParam = {
                ...subParam,
                group_by: 1,
                unique_id: uuidv4(), // Add a unique ID for sub-parameter
                required: stringToBoolean(subParam.required),
                options: subParam.options ? parseOptions(subParam.options) : {},
                ...parseOptions(subParam.options),
                sub_parameters: subParam.sub_parameters ? transformSubParameters(subParam.sub_parameters) : []
            };

            if (checkInputType(subParam.type) === 'multi') {
                transformedSubParam.sub_parameters = [[...transformedSubParam.sub_parameters]];
            }

            return transformedSubParam;
        });
    };

    const stringToBoolean = (value) => value == 1;

    const parseOptions = (options) => {
        if (!options) return {};
        const parsedOptions = {};
        try {
            const parsed = JSON.parse(options);
            Object.entries(parsed).forEach(([key, value]) => {
                if (key === 'key') parsedOptions.key = value;
                else if (key === 'file') parsedOptions.fileFormats = value;
                else if (key === 'image') parsedOptions.imageFormats = value;
            });
        } catch (e) {
            console.error('Failed to parse options:', e);
        }
        return parsedOptions;
    };

    const extractDropdownKeys = (params) => {
        const keys = new Set();

        const processParams = (params) => {
            params.forEach(param => {
                if (checkInputType(param.type) === 'dropdown' && param.options?.key) {
                    keys.add({ key: param.options.key, parameter_id: param.parameter_id });
                }
                if (checkInputType(param.type) === 'multi' && Array.isArray(param.sub_parameters)) {
                    param.sub_parameters.forEach(subParamArray => {
                        if (Array.isArray(subParamArray)) {
                            processParams(subParamArray);
                        } else {
                            processParams([subParamArray]);
                        }
                    });
                }
                if (param.sub_parameters && param.sub_parameters.length > 0 && checkInputType(param.type) !== 'multi') {
                    processParams(param.sub_parameters);
                }
            });
        };

        processParams(params);
        return [...keys];
    };
    const fetchDropdownOptions = async (dropdownKeys) => {
        const fetchedOptions = {};
        for (let dropdown of dropdownKeys) {
            let dropdownKey = checkDropdownKey(dropdown.key);
            // const response = await props.get_dropdowns_by_key(`api/get_dropdowns_by_key?id=${props.user_data.id}&key=${dropdownKey}`, props.token);
            fetchedOptions[dropdown.key] = props.all_dropdowns[dropdownKey] || [];
        }
        setDropdownOptions(fetchedOptions);
    };

    const handleInputChange = (unique_id, parameter_id, group_by, value) => {
        setFormState(prevState => ({
            ...prevState,
            [unique_id]: {
                value,
                parameter_id,
                group_by
            }
        }));
    };
    const handleFileChange = (unique_id, parameter_id, group_by, value) => {
        setFormState(prevState => ({
            ...prevState,
            [unique_id]: {
                value,
                parameter_id,
                group_by
            }
        }));
    };


    const validateForm = () => {
        const newErrors = {};

        const validateParameters = (params) => {
            params.forEach(param => {
                if (param.required && !formState[param.unique_id]?.value) {
                    newErrors[param.unique_id] = `${param.label} is required`;
                }
                if (param.sub_parameters) {
                    if (Array.isArray(param.sub_parameters[0])) {
                        param.sub_parameters.forEach(subParams => {
                            subParams.forEach(subParam => {
                                if (subParam.required && !formState[subParam.unique_id]?.value) {
                                    newErrors[subParam.unique_id] = `${subParam.label} is required`;
                                }
                            });
                        });
                    } else {
                        validateParameters(param.sub_parameters);
                    }
                }
            });
        };

        validateParameters(parameters);
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };


    const handleSubmit = () => {
        if (validateForm()) {
            if (!registration_for_user) {
                return alert("Please first search user client.");
            }

            const data = {
                service_id: item.service_id,
                user_id: registration_for_user,
                parameters: []
            };

            Object.keys(formState).forEach(key => {
                const { value, parameter_id, group_by } = formState[key];
                const parameterData = { value, parameter_id };
                if (group_by !== undefined) {
                    parameterData.group_by = group_by;
                }
                data.parameters.push(parameterData);
            });

            const formData = new FormData();
            formData.append('data', JSON.stringify(data));

            // Append all files separately
            Object.keys(formState).forEach(key => {
                const { value, parameter_id } = formState[key];
                if (value instanceof FileList) {
                    for (let i = 0; i < value.length; i++) {
                        formData.append(parameter_id, value[i]);
                    }
                }
            });

            let result = props.add_service_instant(`api/service_instant?id=${props.user_data.id}`, formData, props.token);
            if (result) {
                setFormState({});
                setMultiCount({});
                navigate('/');
            }
        }
    };



    const checkInputType = (value) => {
        const input = InputTypes.find(v => v.dropdown_id == value);
        return input?.value || "";
    };

    const checkDropdownKey = (value) => {
        const input = props.dropdown_types.find(v => v.dropdown_type_id == value);
        return input?.type || "";
    };
    const handleMultiCountChange = (uniqueId, newCount) => {
        setParameters(prevParams => {
            const updatedParams = prevParams.map(param => {
                if (param.unique_id !== uniqueId) return param;

                const currentCount = Array.isArray(param.sub_parameters[0])
                    ? param.sub_parameters.length
                    : param.sub_parameters.length;
                let newSubParameters = Array.isArray(param.sub_parameters[0])
                    ? param.sub_parameters.map(group => [...group])
                    : [...param.sub_parameters];

                if (newCount > currentCount) {
                    // Add new sub-parameters with unique values
                    for (let i = currentCount; i < newCount; i++) {
                        let newSubParamGroup = newSubParameters[0].map(subParam => {
                            let newSubParam = { ...subParam };
                            newSubParam.unique_id = uuidv4(); // Generate new UUID for each new sub-parameter
                            newSubParam.isNew = true; // Mark as new
                            newSubParam.group_by = newCount;
                            return newSubParam;
                        });
                        newSubParameters.push(newSubParamGroup);
                    }
                } else if (newCount < currentCount) {
                    // Remove extra sub-parameters
                    const idsToRemove = newSubParameters.slice(newCount).flat().map(subParam => subParam.unique_id);
                    setFormState(prevFormState => {
                        const updatedFormState = { ...prevFormState };
                        idsToRemove.forEach(id => {
                            delete updatedFormState[id];
                        });
                        return updatedFormState;
                    });
                    newSubParameters = newSubParameters.slice(0, newCount);
                }

                return {
                    ...param,
                    sub_parameters: newSubParameters,
                };
            });

            return updatedParams;
        });

        setMultiCount(prevState => ({
            ...prevState,
            [uniqueId]: newCount
        }));
    };


    const renderParameter = (parameter, key) => {
        let xs = 12;
        let md = 12;
        let sm = 12;

        if (checkInputType(parameter.type) === 'header' || checkInputType(parameter.type) === 'multi') {
            xs = 12; md = 12; sm = 12;
        } else if (
            checkInputType(parameter.type) === 'text'
            || checkInputType(parameter.type) === 'dropdown'
            || checkInputType(parameter.type) === 'image'
            || checkInputType(parameter.type) === 'file'
            || checkInputType(parameter.type) === 'tel'
            || checkInputType(parameter.type) === 'email'
        ) {
            xs = 12; md = 6; sm = 4;
        }

        return (
            <Grid item xs={xs} sm={md} md={sm} key={key}>
                {checkInputType(parameter.type) === 'header' ? (
                    <Grid item xs={12}>
                        <Typography variant="h6" sx={{ color: headingColor }}>
                            {parameter.label}
                        </Typography>
                    </Grid>
                ) : checkInputType(parameter.type) === 'multi' ? (
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="h6" sx={{ color: headingColor }}>
                            {parameter.label}
                        </Typography>
                        <Multi_button
                            totalCount={multiCount[parameter.unique_id] || 1}
                            updatedCount={(count) => handleMultiCountChange(parameter.unique_id, count)}
                        />
                    </Grid>
                ) : checkInputType(parameter.type) === 'text' ? (
                    <TextField
                        fullWidth
                        size="small"
                        label={parameter.label}
                        variant="outlined"
                        value={formState[parameter.unique_id]?.value || ''}
                        onChange={(e) => handleInputChange(parameter.unique_id, parameter.parameter_id, parameter.group_by, e.target.value)}
                        error={!!errors[parameter.unique_id]}
                        helperText={errors[parameter.unique_id]}
                    />
                ) : checkInputType(parameter.type) === 'checkbox' ? (
                    <Grid item xs={12}>
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox checked={formState[parameter.unique_id]?.value || false} onChange={(e) => handleInputChange(parameter.unique_id, parameter.parameter_id, parameter.group_by, e.target.checked)} />}
                                label={parameter.label}
                            />
                            {errors[parameter.unique_id] && (
                                <Typography color="error" variant="caption">{errors[parameter.unique_id]}</Typography>
                            )}
                        </FormGroup>
                    </Grid>
                ) : checkInputType(parameter.type) === 'radio' ? (
                    <Grid item xs={12}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">{parameter.label}</FormLabel>
                            <RadioGroup
                                row
                                aria-label={parameter.label}
                                name={parameter.unique_id}
                                value={formState[parameter.unique_id]?.value || ''}
                                onChange={(e) => handleInputChange(parameter.unique_id, parameter.parameter_id, parameter.group_by, e.target.value)}
                            >
                                {parameter.options.map((option) => (
                                    <FormControlLabel
                                        key={option}
                                        value={option}
                                        control={<Radio />}
                                        label={option}
                                    />
                                ))}
                            </RadioGroup>
                            {errors[parameter.unique_id] && (
                                <Typography color="error" variant="caption">{errors[parameter.unique_id]}</Typography>
                            )}
                        </FormControl>
                    </Grid>
                ) : checkInputType(parameter.type) === 'dropdown' ? (
                    <FormControl fullWidth size="small" error={!!errors[parameter.unique_id]}>
                        <InputLabel id="demo-simple-select-label">{parameter.label}</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label={parameter.label}
                            value={formState[parameter.unique_id]?.value || ''}
                            onChange={(e) => handleInputChange(parameter.unique_id, parameter.parameter_id, parameter.group_by, e.target.value)}
                        >
                            {dropdownOptions[parameter.options.key] && dropdownOptions[parameter.options.key].map(option => (
                                <MenuItem key={option.dropdown_id} value={option.dropdown_id}>{option.title}</MenuItem>
                            ))}
                        </Select>
                        {errors[parameter.unique_id] && (
                            <Typography color="error" variant="caption">{errors[parameter.unique_id]}</Typography>
                        )}
                    </FormControl>
                ) : checkInputType(parameter.type) === 'image' ? (
                    <Grid item xs={12}>
                        <OutlinedInput
                            fullWidth
                            size="small"
                            type="file"
                            label={parameter.label}
                            onChange={(e) => handleFileChange(parameter.unique_id, parameter.parameter_id, parameter.group_by, e.target.files)}
                            error={!!errors[parameter.unique_id]}
                        />
                        {errors[parameter.unique_id] && (
                            <Typography color="error" variant="caption">{errors[parameter.unique_id]}</Typography>
                        )}
                    </Grid>
                ) : checkInputType(parameter.type) === 'file' ? (
                    <Grid item xs={12}>
                        <OutlinedInput
                            fullWidth
                            size="small"
                            type="file"
                            label={parameter.label}
                            onChange={(e) => handleFileChange(parameter.unique_id, parameter.parameter_id, parameter.group_by, e.target.files)}
                            error={!!errors[parameter.unique_id]}
                        />
                        {errors[parameter.unique_id] && (
                            <Typography color="error" variant="caption">{errors[parameter.unique_id]}</Typography>
                        )}
                    </Grid>
                ) : checkInputType(parameter.type) === 'tel' ? (
                    <TextField
                        fullWidth
                        size="small"
                        label={parameter.label}
                        variant="outlined"
                        value={formState[parameter.unique_id]?.value || ''}
                        type={checkInputType(parameter.type)}
                        onChange={(e) => handleInputChange(parameter.unique_id, parameter.parameter_id, parameter.group_by, e.target.value)}
                        error={!!errors[parameter.unique_id]}
                        helperText={errors[parameter.unique_id]}
                    />
                ) : checkInputType(parameter.type) === 'email' ? (
                    <TextField
                        fullWidth
                        size="small"
                        label={parameter.label}
                        variant="outlined"
                        value={formState[parameter.unique_id]?.value || ''}
                        type={checkInputType(parameter.type)}
                        onChange={(e) => handleInputChange(parameter.unique_id, parameter.parameter_id, parameter.group_by, e.target.value)}
                        error={!!errors[parameter.unique_id]}
                        helperText={errors[parameter.unique_id]}
                    />
                ) : null}
                {parameter.sub_parameters && parameter.sub_parameters.length > 0 && (
                    <>
                        <Grid container spacing={2} sx={{ mt: 1 }}>
                            {!Array.isArray(parameter.sub_parameters[0]) && // Only render if it's not a multi array
                                parameter.sub_parameters.map((subParam, index) => (
                                    <Fragment key={index}>
                                        {renderParameter(subParam, index)}
                                    </Fragment>
                                ))
                            }
                        </Grid>
                        {Array.isArray(parameter.sub_parameters[0]) && // Only render if it's a multi array
                            parameter.sub_parameters.map((subParam, index) => (
                                <Grid key={index} container sx={{ border: '1px solid #ccc', borderRadius: '4px', p: 2, mt: 1 }} spacing={1}>
                                    {subParam.map((sub, i) => renderParameter(sub, i))}
                                </Grid>
                            ))
                        }
                    </>
                )}
            </Grid>
        );
    };



    return (
        <Container>
            <Box sx={{ pt: 5 }}>
                <Typography variant="h4" gutterBottom>
                    Registration Form
                </Typography>
                <SearchUser
                    onSelect={(id) => setRegistration_for_user(id)}
                />
                <Grid container spacing={3}>
                    {parameters.map((parameter, index) =>
                        renderParameter(parameter, index)
                    )}
                </Grid>
                <Divider sx={{ my: 2 }} />
                <Box sx={{ textAlign: 'right' }}>
                    <Button variant="contained" color="primary" onClick={handleSubmit}>
                        Submit
                    </Button>
                </Box>
            </Box>
        </Container>
    );
}

const mapStateToPros = (state) => ({
    user_data: state.AuthReducer.user_data,
    token: state.AuthReducer.token,
    dropdown_types: state.rootReducer.dropdown_types,
    all_dropdowns: state.rootReducer.all_dropdowns
});

const mapDispatchToProps = {
    get_dropdowns_by_key: get_dropdowns_by_key,
    add_service_instant: add_service_instant
};

export default connect(mapStateToPros, mapDispatchToProps)(Registration_Form);
