import React, { useEffect, useState, useCallback, useMemo, memo } from 'react';
import { connect } from 'react-redux';
import { create_dropdown, delete_dropdown, get_dropdown_types, get_dropdowns, update_dropdown } from '../../redux.js/actions';
import { Button, Container, Divider, FormControl, Grid, InputLabel, ListItemIcon, MenuItem, Modal, Select, TextField, Typography } from '@mui/material';
import { headingColor } from '../../util/colors';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import LoadingSpinner from '../../components/loader/LoadingSpinner';
import AccountMenu from '../../components/Common/MoreMenu';
import { Delete, Update } from '@mui/icons-material';
import debounce from 'lodash.debounce';
import { hasPermission } from '../../util/Fun';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const Data_Grid = memo(({ rows, columns }) => {
  return (
    <DataGrid
      rows={rows}
      columns={columns}
      autoHeight
      rowHeight={50}
      getRowId={(row) => row.dropdown_id}
      slots={{ toolbar: GridToolbar }}
      initialState={{
        pagination: { paginationModel: { pageSize: 10 } },
      }}
      pagination
      pageSizeOptions={[10, 25, 50, 100]}

    />
  )
})

function Dropdowns(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [value, setValue] = useState('');
  const [dropdownTypeId, setDropdownTypeId] = useState('');
  const [rows, setRows] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedDropdown, setSelectedDropdown] = useState(null);
  const [dropdownTypes, setDropdownTypes] = useState(props.dropdown_types);
  const [errors, setErrors] = useState({ title: '', value: '', dropdown_type_id: '' });
  const columns = useMemo(() => [
    { field: 'dropdown_id', headerName: 'Id', flex: 1 },
    { field: 'title', headerName: 'Title', flex: 1 },
    { field: 'value', headerName: 'Value', flex: 1 },
    { field: 'dropdown_type_name', headerName: 'Dropdown Type', flex: 1 },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      renderCell: (params) => (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}>
          <AccountMenu>
            {hasPermission("update_dropdown", props.user_data.permissions) &&
              <MenuItem onClick={() => onPressUpdate(params.row)}>
                <ListItemIcon>
                  <Update fontSize="small" />
                </ListItemIcon>
                Update
              </MenuItem>
            }
            {hasPermission("delete_dropdown", props.user_data.permissions) &&
              <div>
                <Divider />
                <MenuItem onClick={() => handleDeleteDropdown(params.row.dropdown_id)}>
                  <ListItemIcon>
                    <Delete fontSize="small" />
                  </ListItemIcon>
                  Delete
                </MenuItem>
              </div>
            }
          </AccountMenu>
        </div>
      ),
    },
  ], []);

  useEffect(() => {
    getDropdowns();
  }, []);


  const getDropdowns = async () => {
    setIsLoading(true);
    let result = await props.get_dropdowns(`api/get_dropdowns?id=${props.user_data.id}`, props.token);
    setIsLoading(false);
    if (result.success) {
      setRows(result.data);
    }
  };


  const onPressUpdate = (row) => {
    setSelectedDropdown(row);
    setTitle(row.title);
    setValue(row.value);
    setDropdownTypeId(row.dropdown_id);
    setOpenModal(true);
  };

  const handleDeleteDropdown = async (id) => {
    setIsLoading(true);
    await props.delete_dropdown(`api/delete_dropdown?id=${props.user_data.id}`, props.token, { dropdown_id: id });
    getDropdowns();
  };

  const handleAddDropdown = async () => {
    if (validateForm()) {
      setIsLoading(true);
      await props.create_dropdown(`api/create_dropdown?id=${props.user_data.id}`, props.token, {
        title,
        value,
        dropdown_type: dropdownTypeId
      });
      getDropdowns();
      handleCloseModal();
    }
  };

  const handleUpdateDropdown = async () => {
    if (validateForm()) {
      let data = {
        dropdown_type: dropdownTypeId,
        title: title,
        value: value
      }
      setIsLoading(true);
      await props.update_dropdown(`api/update_dropdown?id=${props.user_data.id}&dropdown_id=${selectedDropdown.dropdown_id}`, props.token, data);
      getDropdowns();
      handleCloseModal();
    }
  };

  const validateForm = () => {
    let isValid = true;
    let newErrors = { title: '', value: '', dropdown_type_id: '' };

    if (!title) {
      newErrors.title = 'Title is required';
      isValid = false;
    }
    if (!value) {
      newErrors.value = 'Value is required';
      isValid = false;
    }
    if (!dropdownTypeId) {
      newErrors.dropdown_type_id = 'Dropdown type is required';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedDropdown(null);
    setTitle('');
    setValue('');
    setDropdownTypeId('');
    setErrors({ title: '', value: '', dropdown_type_id: '' });
  };

  return (
    <Container sx={{ pt: 5 }}>
      <Grid container columns={12} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <h4 style={{ color: headingColor, fontWeight: 'bold' }}>Dropdowns Data</h4>
        {hasPermission("create_dropdown", props.user_data.permissions) &&
          <Button variant="outlined" onClick={() => setOpenModal(true)}>Insert Dropdowns</Button>
        }
      </Grid>


      <Data_Grid
        rows={rows}
        columns={columns}
      />

      {isLoading && <LoadingSpinner />}

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Container sx={style}>
          <Typography variant="h6" component="h2">{selectedDropdown ? 'Update Dropdown' : 'Add New Dropdown'}</Typography>
          <TextField
            id='title'
            size='small'
            label="Title"
            variant="outlined"
            fullWidth
            name="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            error={!!errors.title}
            helperText={errors.title}
            sx={{ mt: 2 }}
          />
          <TextField
            size='small'
            label="Value"
            variant="outlined"
            fullWidth
            name="value"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            error={!!errors.value}
            helperText={errors.value}
            sx={{ mt: 2 }}
          />
          <FormControl fullWidth size='small' sx={{ mt: 2 }}>
            <InputLabel id="dropdown-type-select-label">Dropdown Type</InputLabel>
            <Select
              labelId="dropdown-type-select-label"
              id="dropdown-type-select"
              value={dropdownTypeId}
              name="dropdown_type_id"
              label="Dropdown Type"
              onChange={(e) => setDropdownTypeId(e.target.value)}
              error={!!errors.dropdown_type_id}
            >
              {dropdownTypes.map((v, i) => (
                <MenuItem key={i} value={v.dropdown_type_id}>{v.title}</MenuItem>
              ))}
            </Select>
            {errors.dropdown_type_id && <Typography color="error">{errors.dropdown_type_id}</Typography>}
          </FormControl>
          <Button variant="contained" onClick={selectedDropdown ? handleUpdateDropdown : handleAddDropdown} sx={{ mt: 2 }}>
            {selectedDropdown ? 'Update Dropdown' : 'Add Dropdown'}
          </Button>
        </Container>
      </Modal>
    </Container>
  );
}



const mapStateToPros = (state) => ({
  user_data: state.AuthReducer.user_data,
  token: state.AuthReducer.token,
  dropdown_types: state.rootReducer.dropdown_types
});

const mapDispatchToProps = {
  get_dropdowns,
  create_dropdown,
  update_dropdown,
  delete_dropdown,
  get_dropdown_types
};

export default connect(mapStateToPros, mapDispatchToProps)(Dropdowns);
