import io from 'socket.io-client';
import { api_base_url } from '../util/Constant';

// Configuration options for Socket.IO client
const socketConfig = {
    url: api_base_url,
    options: {
        transports: ['websocket'], // Specify the transport method
        reconnection: true,       // Enable automatic reconnection
        reconnectionAttempts: Infinity, // Number of reconnection attempts (Infinity means unlimited attempts)
        reconnectionDelay: 1000,  // Delay before attempting to reconnect (in milliseconds)
        reconnectionDelayMax: 5000, // Maximum delay between reconnection attempts (in milliseconds)
        randomizationFactor: 0.5, // Factor to randomize reconnection delay
        timeout: 20000,           // Connection timeout (in milliseconds)
    }
};

const socket = io(socketConfig.url, socketConfig.options);

export default socket;
