import { Box, Button, Checkbox, Container, FormControlLabel, Grid, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import vector_image from '../assets/images/login_banner.svg'
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { loginAction } from '../redux.js/actions/AuthAction';
import Toast_Container from '../components/Toast_Container';
import LoadingSpinner from '../components/loader/LoadingSpinner'
const Login = (props) => {
    const navigate = useNavigate();
    const [states, setStates] = useState({
        email: '',
        password: '',
        isLoading: false,
    });
    const [keepLoggedIn, setKeepLoggedIn] = useState(false)
    const [errors, setErrors] = useState({
        email: '',
        password: '',
    });

    const handleSubmitForm = async (event) => {
        event.preventDefault();
        const newErrors = {};

        // Validate email
        if (!states.email) {
            newErrors.email = 'Email is required';
        }

        if (!states.password) {
            newErrors.password = 'Password is required';
        }

        setErrors(newErrors);

        const isValid = Object.values(newErrors).every((error) => !error);

        if (!isValid) {
            console.log('Form has validation errors. Please correct them.');
        } else {
            setStates((prev) => ({ ...prev, isLoading: true }));
            let result = await props.loginAction("api/admin_account_login", { email: states.email, password: states.password });
            if (result) {
                setStates((prev) => ({ ...prev, isLoading: false, email: "", password: "" }));
            } else {
                setStates((prev) => ({ ...prev, isLoading: false }));
            }
        }
    };
    return (
        <Container maxWidth={false}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Box p={2} className={"custom-box"} >
                        <div className='custom-box-left-inner'>
                            <h1 style={{ textAlign: 'left' }} className='text-color-primary'>Login</h1>
                            <form onSubmit={handleSubmitForm} style={{ textAlign: 'left' }}>
                                <TextField
                                    label="Email"
                                    type="email"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={states.email}
                                    onChange={(e) => setStates(prev => ({ ...prev, email: e.target.value }))}
                                    required
                                    id={'input_style_email'}
                                />
                                <p className="text-danger">{errors.email}</p>
                                <TextField
                                    label="Password"
                                    type="password"
                                    variant="outlined"
                                    fullWidth
                                    margin="none"
                                    value={states.password}
                                    onChange={(e) => setStates(prev => ({ ...prev, password: e.target.value }))}
                                    required
                                    id='input_style_password'
                                />
                                <p className="text-danger">{errors.password}</p>
                                <FormControlLabel
                                    control={<Checkbox checked={keepLoggedIn} onChange={() => setKeepLoggedIn(!keepLoggedIn)} />}
                                    label="Keep me logged in"
                                />
                                <Box mt={2}>
                                    <Button type="submit" variant="contained" fullWidth className={'button_style'}>
                                        Login
                                    </Button>
                                </Box>
                            </form>
                        </div>
                    </Box>
                </Grid>

                {/* Second Box */}
                <Grid item xs={12} md={6}>
                    <Box p={2} className={"custom-box"} >
                        <img src={vector_image} alt='svg image' className='login_vector_image' />
                    </Box>
                </Grid>
            </Grid>
            {/* <Toast_Container /> */}

            {states.isLoading && <LoadingSpinner />}
        </Container >
    )
}

const mapStateToProps = () => ({

})
const mapStateToDispatch = {
    loginAction: loginAction
}
export default connect(mapStateToProps, mapStateToDispatch)(Login)