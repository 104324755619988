import React, { useEffect, useState } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { AppBar, Box, Button, Checkbox, Container, Dialog, Divider, FormControlLabel, Grid, IconButton, List, ListItemIcon, MenuItem, Modal, Slide, Toolbar, Typography } from '@mui/material';
import { connect } from 'react-redux';
import { get_roles, get_role_permissions, update_role_permissions, get_permissions } from '../../redux.js/actions';
import LoadingSpinner from '../../components/loader/LoadingSpinner';
import { halfWhite, headingColor } from '../../util/colors';
import AccountMenu from '../../components/Common/MoreMenu';
import { Cancel, CookieSharp, Delete, Height, Update } from '@mui/icons-material';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const Roles = (props) => {
  const [states, setStates] = useState({
    isLoading: false,
    selectedRole: null,
    openModal: false,
    rows: [],
    permissions: [],
    groupedRolePermissions: [],
    currentRolesPermission: []
  });
  const columns = [
    { field: 'role_id', headerName: 'Id', flex: 1 },
    { field: 'role_name', headerName: 'Role Name', flex: 1 },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      renderCell: (params) => (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex' }}>
          <AccountMenu>
            <MenuItem onClick={() => onPressUpdate(params.row)}>
              <ListItemIcon>
                <Update fontSize="small" />
              </ListItemIcon>
              Assign Permissions
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => { }}>
              <ListItemIcon>
                <Delete fontSize="small" />
              </ListItemIcon>
              Delete Role
            </MenuItem>
          </AccountMenu>
        </div>
      ),
    },
  ];
  useEffect(() => {
    GetPermissions();
    GetRoles();
  }, []);

  const GetRoles = async () => {
    setStates(prev => ({ ...prev, isLoading: true }));
    let result = await props.get_roles(`api/get_roles?id=${props.user_data.id}`, props.token);
    setStates(prev => ({ ...prev, isLoading: false }));
    if (result) {
      setStates(prev => ({ ...prev, rows: result.data }));
    } else {
      setStates(prev => ({ ...prev, rows: [] }));
    }
  };

  const GetPermissions = async () => {
    setStates(prev => ({ ...prev, isLoading: true }));
    let result = await props.get_permissions(`api/get_permissions?id=${props.user_data.id}`, props.token);
    setStates(prev => ({ ...prev, isLoading: false }));
    if (result) {
      setStates(prev => ({ ...prev, permissions: result.data }));
    }
  };

  const handleCloseModal = () => {
    setStates(prevData => ({
      ...prevData,
      openModal: false,
      selectedRole: null
    }));
  };

  const onPressUpdate = async (row) => {
    setStates(prev => ({ ...prev, isLoading: true }));
    let result = await props.get_roles(`api/get_role_permissions?id=${props.user_data.id}&role_id=${row.role_id}`, props.token);
    setStates(prev => ({ ...prev, isLoading: false }));
    if (result) {
      let grounded = groupByPageName(mergeDataWithChecked(states.permissions, result.data))
      setStates(prev => ({ ...prev, groupedRolePermissions: grounded }));
      setStates(prevData => ({
        ...prevData,
        selectedRole: row,
        openModal: true,
        currentRolesPermission: result.data
      }));
    }
  };

  function groupByPageName(data) {
    const groupedData = {};

    data.forEach(item => {
      const key = item.page_name || "Other";

      if (!groupedData[key]) {
        groupedData[key] = [];
      }

      groupedData[key].push(item);
    });

    return groupedData;
  }
  const mergeDataWithChecked = (data1, data2) => {
    return data1.map(item1 => {
      const matchingItem = data2.find(item2 => item1.permission_id === item2.permission_id);
      return {
        ...item1,
        checked: matchingItem ? true : false
      };
    });
  };



  const onCheckedParent = (event, key) => {
    const isChecked = event.target.checked;
    setStates(prevState => ({
      ...prevState,
      groupedRolePermissions: {
        ...prevState.groupedRolePermissions,
        [key]: prevState.groupedRolePermissions[key].map(permission => ({
          ...permission,
          checked: isChecked
        }))
      }
    }));
  };

  const onCheckedChild = (event, parentKey, childIndex) => {
    const isChecked = event.target.checked;
    setStates(prevState => ({
      ...prevState,
      groupedRolePermissions: {
        ...prevState.groupedRolePermissions,
        [parentKey]: prevState.groupedRolePermissions[parentKey].map((permission, index) => {
          if (index === childIndex) {
            return { ...permission, checked: isChecked };
          }
          return permission;
        })
      }
    }));
  };

  const SavePermission = async () => {
    let permission_ids = [];
    let removed_permission_ids = []
    Object.entries(states.groupedRolePermissions).forEach(([key, permissions]) => {
      permissions.forEach((permission) => {
        if (permission.checked) {
          permission_ids.push(permission.permission_id);
        } else {
          removed_permission_ids.push(permission.permission_id);
        }
      });
    });
    setStates(prev => ({ ...prev, isLoading: true }));
    let result = await props.update_role_permissions(`api/create_role_permissions?id=${props.user_data.id}`, props.token, { role_id: states.selectedRole.role_id, permission_ids: permission_ids, removed_permission_ids: removed_permission_ids })
    setStates(prev => ({ ...prev, isLoading: false }));
    handleCloseModal()
  }
  return (
    <Container sx={{ pt: 5 }}>
      <Grid container columns={12} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <h4 style={{ color: headingColor, fontWeight: 'bold' }}>Roles</h4>
        {/* <button className='heading_button' onClick={() => { }}>Create New Role</button> */}
      </Grid>
      <DataGrid
        rows={states.rows}
        columns={columns}
        slots={{ toolbar: GridToolbar }}
        autoHeight
        rowHeight={50}
        getRowId={(row) => row.role_id}
      />
      {states.isLoading && <LoadingSpinner />}
      <Dialog
        fullScreen
        open={states.openModal}
        onClose={handleCloseModal}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseModal}
              aria-label="close"
            >
              <Cancel />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              User Role Permission
            </Typography>
            <Button autoFocus color="inherit" onClick={() => SavePermission()}>
              save
            </Button>
          </Toolbar>
        </AppBar>
        <List>
          <Container sx={{ pt: 2 }} maxWidth={'lg'}>
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
            }} >
              {Object.entries(states.groupedRolePermissions).map(([key, permissions], index) => (
                <div key={index}>
                  <Box sx={{ background: halfWhite, pl: 2 }}>
                    <FormControlLabel
                      label={key}
                      control={
                        <Checkbox
                          checked={permissions.every(permission => permission.checked)}
                          indeterminate={permissions.some(permission => permission.checked) && !permissions.every(permission => permission.checked)}
                          onChange={(event) => onCheckedParent(event, key)}
                        />
                      }
                    />
                  </Box>
                  {permissions.map((item, childIndex) => (
                    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }} key={childIndex}>
                      <FormControlLabel
                        label={item.permission_name}
                        control={
                          <Checkbox
                            checked={item.checked}
                            onChange={(event) => onCheckedChild(event, key, childIndex)}
                          />
                        }
                      />
                    </Box>
                  ))}
                </div>
              ))}
            </Box>
          </Container>

        </List>
      </Dialog>
    </Container>
  );
};

const style = {
  position: 'absolute',
  top: '50%',
  // left: '50%',
  // transform: 'translate(-50%, -50%)',
  width: '100% !important',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  // maxHeight: '80%'
};

const mapStateToPros = (state) => ({
  user_data: state.AuthReducer.user_data,
  token: state.AuthReducer.token,
});

const mapStateToDispatch = {
  get_roles: get_roles,
  get_role_permissions: get_role_permissions,
  update_role_permissions: update_role_permissions,
  get_permissions: get_permissions
};

export default connect(mapStateToPros, mapStateToDispatch)(Roles);
