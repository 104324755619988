


import React, { useEffect, useState } from 'react'
import { ResponsivePie } from '@nivo/pie'
import { connect } from 'react-redux'
import { getServiceInstanceCount } from '../../redux.js/actions'
const Pie_chart = (props) => {
    const [SourceData, setSourceData] = useState([])
    useEffect(() => {
        GetGraphData()
    }, [])
    const GetGraphData = async () => {
        let result = await props.getServiceInstanceCount(`api/get_services_instance_count?id=${props.user_data.id}`, props.token);
        if (result) {
            setSourceData(result)
        }
    };
    return (
        <ResponsivePie
            data={SourceData}
            margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            activeOuterRadiusOffset={8}
            borderWidth={1}
            borderColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        0.2
                    ]
                ]
            }}
            arcLinkLabel={"label"}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor={{ from: 'color', modifiers: [] }}
            arcLinkLabelsDiagonalLength={22}
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: 'color' }}
            arcLabelsTextColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        '2.3'
                    ]
                ]
            }}
            defs={[
                {
                    id: 'dots',
                    type: 'patternDots',
                    background: 'inherit',
                    color: 'rgba(255, 255, 255, 0.3)',
                    size: 4,
                    padding: 1,
                    stagger: true
                },
                {
                    id: 'lines',
                    type: 'patternLines',
                    background: 'inherit',
                    color: 'rgba(255, 255, 255, 0.3)',
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10
                }
            ]}
            legends={[]}
        />
    )
}


const mapStateToProps = (state) => ({
    token: state.AuthReducer.token,
    user_data: state.AuthReducer.user_data,
})
const mapStateToDispatch = {
    getServiceInstanceCount: getServiceInstanceCount
}
export default connect(mapStateToProps, mapStateToDispatch)(Pie_chart)