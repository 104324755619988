import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';

const CustomAlert = ({ open, onClose, onYesClick, onCancelClick, message, cancelText = "Cancle", continueText = "Yes" }) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Alert</DialogTitle>
            <DialogContent>
                <p>{message}</p>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancelClick} color="secondary">
                    {cancelText}
                </Button>
                <Button onClick={onYesClick} color="primary" autoFocus>
                    {continueText}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CustomAlert;
