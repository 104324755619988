import React, { useEffect, useState } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Container, Divider, ListItemIcon, MenuItem } from '@mui/material';
import { connect } from 'react-redux';
import { get_web_users } from '../redux.js/actions';
import LoadingSpinner from '../components/loader/LoadingSpinner';
import AccountMenu from '../components/Common/MoreMenu';
import { Delete, Update } from '@mui/icons-material';
import UpdateUser from '../components/Users/UpdateUser';
const Users = (props) => {
  const [rows, setRows] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isUpdateUser, setIsUpdateUser] = useState(null)
  const onPressUpdate = (row) => {
    setIsUpdateUser(row)
  }
  const handleDelete = (row) => {

  }
  const columns = [
    { field: 'id', headerName: 'Id', flex: 1, },
    { field: 'full_name', headerName: 'Name', flex: 1, },
    { field: 'email', headerName: 'Email', flex: 1, },
    { field: 'phone', headerName: 'Phone', flex: 1, },
    { field: 'created_on', headerName: 'Created On', flex: 1, },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      renderCell: (params) => (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}>
          <AccountMenu>
            <MenuItem onClick={() => onPressUpdate(params.row)}>
              <ListItemIcon>
                <Update fontSize="small" />
              </ListItemIcon>
              Update
            </MenuItem>
            <div>

              <Divider />
              {params.row.user_type != 'super_admin' &&
                <MenuItem onClick={() => handleDelete(params.row)}>
                  <ListItemIcon>
                    <Delete fontSize="small" />
                  </ListItemIcon>
                  Delete
                </MenuItem>
              }
            </div>
          </AccountMenu>
        </div>
      ),
    },
  ];
  useEffect(() => {
    GetWebUsers()
  }, [])
  const GetWebUsers = async () => {
    setIsLoading(true)
    let result = await props.get_web_users(`api/get_web_user?id=${props.user_data.id}`, props.token)

    setIsLoading(false)
    if (result) {
      setRows(result.data)
    } else {
      setRows([])
    }


  }
  return (
    <Container maxWidth={'lg'} sx={{ pt: 5 }}>
      <DataGrid rows={rows} columns={columns}
        slots={{ toolbar: GridToolbar }}
        autoHeight
        rowHeight={50}
      />
      {isLoading &&
        <LoadingSpinner />
      }
      {isUpdateUser &&
        <UpdateUser
          isOpen={isUpdateUser ? true : false}
          onClose={() => setIsUpdateUser(null)}
          formData={isUpdateUser}
        />
      }
    </Container>

  );
}
const mapStateToPros = (state) => ({
  user_data: state.AuthReducer.user_data,
  token: state.AuthReducer.token,
})
const mapStateToDispatch = {
  get_web_users: get_web_users
}
export default connect(mapStateToPros, mapStateToDispatch)(Users)