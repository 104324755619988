import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { useState } from 'react';
import { AccountCircleOutlined, ChevronRightRounded, KeyboardArrowRight, Dashboard, LogoutOutlined, VerifiedUser, KeyboardArrowDown, AccountCircle, Flag, Notifications, VerticalShadesOutlined, Email, EmailOutlined } from '@mui/icons-material';
import { primaryColor, secondaryColor, whiteColor } from '../../util/colors';
import { json, useNavigate } from 'react-router-dom';
import Arrow_right from '../Common/Arrow_right';
import List_item_button from '../Common/List_item_button';
import { connect, useDispatch } from 'react-redux';
import { ALL_SERVICE_DATA, DASHBOARD_COUNT, DROPDOWN_TYPES, INPUT_TYPES, IS_LOGIN, READ_NOTIFICATIONS, REGISTRATIONS, TOKEN, TRUST_CATEGORY, TRUST_TYPES, USER_DATA, USER_ROLE } from '../../redux.js/actionType';
import CustomAlert from '../CustomAlert';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { checkPagesPermission, formatNotificationTime, hasPermission } from '../../util/Fun';
import { Avatar, Badge, Popover } from '@mui/material';
import socket from '../../config/SockerConfig';

const drawerWidth = 240;
const content = {
    width: `calc(100% - ${drawerWidth}px)`
}
const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const MiniDrawer = (props) => {
    const theme = useTheme();
    const dispatch = useDispatch()
    const [open, setOpen] = useState(true);
    const [isUserOpen, setIsUserOpen] = useState(false);
    const [IsTrustOpen, setIsTrustOpen] = useState(false)
    const [roleManagement, setIsRoleManagement] = useState(false)
    const [dropdowns, setIsDropdowns] = useState(false)
    const [showAlert, setShowAlert] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const navigate = useNavigate();

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
        setIsUserOpen(false)
        setIsTrustOpen(false)
        setIsRoleManagement(false)
        setIsDropdowns(false)
    };
    const openSubItemUser = () => {
        setIsUserOpen(!isUserOpen)
    }
    const openSubItemService = () => {
        setIsTrustOpen(!IsTrustOpen)
    }
    const openSubItemRoleManagement = () => {
        setIsRoleManagement(!roleManagement)
    }


    const handleNotificationClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const notification_open = Boolean(anchorEl);
    const id = notification_open ? 'simple-popover' : undefined;

    const handleLogout = () => {
        dispatch({ type: IS_LOGIN, payload: false })
        dispatch({ type: USER_DATA, payload: null })
        dispatch({ type: ALL_SERVICE_DATA, payload: [] })
        dispatch({ type: REGISTRATIONS, payload: [] })
        dispatch({ type: DASHBOARD_COUNT, payload: null })
        dispatch({ type: USER_ROLE, payload: [] })
        dispatch({ type: TRUST_TYPES, payload: [] })
        dispatch({ type: TRUST_CATEGORY, payload: [] })
        dispatch({ type: INPUT_TYPES, payload: [] })
        dispatch({ type: DROPDOWN_TYPES, payload: [] })
        setShowAlert(false)
    }
    const getInitials = (name) => {
        if (name) {
            return name.split(' ')
                .map(word => word[0])
                .join('')
                .substring(0, 2)
                .toUpperCase();
        }
        return
    };

    const HandleReadNotification = (notification) => {
        navigate(`?instance_id=${notification.instance_id}`);
    }

    const MarkAllAsRead = () => {
        let findUnRead = props.notifications.filter((item) =>
            item.is_read == false
        ).map((item) => item.id);
        if (findUnRead.length > 0) {
            socket.emit("notification_status", findUnRead)
        }
        dispatch({ type: READ_NOTIFICATIONS, payload: findUnRead })
    }
    let numberOfNotification = props.notifications.filter(i => i.is_read == false)

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" open={open} className='app-bar'>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: 5,
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        NOMA PVT
                    </Typography>
                    <Stack direction="row" gap={3} alignItems="center" justifyContent="space-evenly">
                        <Box>
                            <Stack direction="row" spacing={2}>
                                {hasPermission('create_account', props.user_data.permissions) && (
                                    <Button onClick={() => navigate("/newuser")} variant="contained" size="small">
                                        Create User
                                    </Button>
                                )}
                                {hasPermission('service_instant', props.user_data.permissions) && (
                                    <Button onClick={() => navigate("/newtask")} variant="contained" size="small">
                                        Registration
                                    </Button>
                                )}
                            </Stack>
                        </Box>
                        <Badge color="secondary" badgeContent={numberOfNotification ? numberOfNotification.length : null} onClick={handleNotificationClick}>
                            <Notifications />
                        </Badge>
                        <Popover
                            id={id}
                            open={notification_open}
                            anchorEl={anchorEl}
                            onClose={handlePopoverClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            PaperProps={{
                                style: {
                                    maxWidth: '300px',
                                    boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.2)',
                                    borderRadius: '3px',
                                    marginTop: 10
                                },
                            }}
                        >
                            <Box sx={{maxHeight:500}}>
                                <Box sx={{ display: 'flex', gap: 5, alignItems: 'center', px: 1, pt: 1, justifyContent: 'space-between' }}>
                                    <Typography fontSize={13}>NOTIFICATIONS</Typography>
                                    <Typography fontSize={12} sx={{ cursor: 'pointer', color: primaryColor }} onClick={() => MarkAllAsRead()}>Mark all as read</Typography>
                                </Box>
                                <List>
                                    {props.notifications.length > 0 ?
                                        (
                                            props.notifications.map((notification, index) => (
                                                <ListItem
                                                    onClick={() => HandleReadNotification(notification)}
                                                    button
                                                    key={index}
                                                    sx={{
                                                        '&:hover': {
                                                            backgroundColor: '#f5f5f5',
                                                        },
                                                        p: 0
                                                    }}
                                                >
                                                    <Box display="flex" alignItems="center" sx={{ p: 1, backgroundColor: notification.is_read ? null : '#e6e6f6', width: '100%' }}>
                                                        <Avatar sx={{ bgcolor: primaryColor, color: '#fff', marginRight: 2, fontSize: 18 }}>
                                                            {getInitials(notification.full_name)}
                                                        </Avatar>

                                                        <Box>
                                                            <Typography fontSize={14} color={primaryColor}>
                                                                {notification.full_name}
                                                            </Typography>
                                                            <Typography variant="body2" color="textSecondary"
                                                                sx={{
                                                                    display: '-webkit-box',
                                                                    WebkitBoxOrient: 'vertical',
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                    WebkitLineClamp: 3,
                                                                    lineHeight: '1.5',
                                                                    maxHeight: '4.5em',
                                                                }}
                                                            >
                                                                {notification.message}
                                                            </Typography>
                                                            <Typography variant="caption" color="textSecondary">
                                                                {formatNotificationTime(notification.created_on)}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </ListItem>
                                            ))
                                        ) : (
                                            <ListItem>
                                                <ListItemText primary="No new notifications" />
                                            </ListItem>
                                        )}
                                </List>
                            </Box>
                        </Popover>
                        <Avatar>
                            <AccountCircle />
                        </Avatar>
                        <Box>
                            <Typography variant="body1">
                                {props.user_data.full_name}
                            </Typography>
                            <Typography variant="body2" color="gray">
                                {props.user_data.email}
                            </Typography>
                        </Box>
                    </Stack>
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open} sx={{
                "& .MuiDrawer-paper": {
                    flexShrink: 0,
                    backgroundColor: secondaryColor
                }
            }} >

                <DrawerHeader className='toggle-side-btn'>
                    <IconButton onClick={handleDrawerClose} sx={{ backgroundColor: whiteColor }} >
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    {checkPagesPermission("Dashboard", props.user_data.pages) &&
                        <ListItem key={"dashboard"} disablePadding sx={{ display: 'block' }}>
                            <List_item_button onClick={() => navigate("/")}>
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 1 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Dashboard className='side-menu-items-icons' />
                                </ListItemIcon>
                                <ListItemText primary={"Dashboard"} sx={{ opacity: open ? 1 : 0 }} className='side-menu-items' />
                            </List_item_button>
                        </ListItem>
                    }

                    {/* Trust Service */}
                    {checkPagesPermission("Services", props.user_data.pages) &&
                        <>
                            <ListItem key={"services"} disablePadding sx={{ display: 'block' }}>
                                <List_item_button onClick={() => openSubItemService()}>
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 1 : 'auto',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <AccountCircleOutlined className='side-menu-items-icons' />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={"Services"}
                                        sx={{
                                            opacity: open ? 1 : 0,
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                        className='side-menu-items'

                                    />
                                    { /* Display the angle icon on the right side of the text */}
                                    {IsTrustOpen ?
                                        <KeyboardArrowDown className='side-menu-items-icons' /> :
                                        <KeyboardArrowRight sx={{ display: open ? 'block' : 'none' }} className='side-menu-items-icons' />}
                                </List_item_button>
                            </ListItem>
                            {IsTrustOpen &&
                                <List sx={{ paddingLeft: '20px', py: 0 }}>
                                    {hasPermission('get_All_Services', props.user_data.permissions) &&
                                        <ListItem key={"all_services"} disablePadding>
                                            <List_item_button onClick={() => navigate("ViewServices")}>
                                                <Arrow_right open={open} />
                                                <ListItemText primary={"All Service"} sx={{
                                                    opacity: open ? 1 : 0
                                                }} className='sidebar-subitem' />
                                            </List_item_button>
                                        </ListItem>
                                    }

                                </List>
                            }
                        </>
                    }


                    {/* service end */}

                    {(hasPermission("get_web_user", props.user_data.permissions) || hasPermission("get_portal_user", props.user_data.permissions)) &&
                        <>
                            <ListItem key={"user"} disablePadding sx={{ display: 'block' }}>
                                <List_item_button onClick={() => openSubItemUser()} >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 1 : 'auto',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <AccountCircleOutlined className='side-menu-items-icons' />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={"Users"}
                                        sx={{
                                            opacity: open ? 1 : 0,
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                        className='side-menu-items'
                                    />
                                    { /* Display the angle icon on the right side of the text */}
                                    {isUserOpen ?
                                        <KeyboardArrowDown className='side-menu-items-icons' /> :
                                        <KeyboardArrowRight sx={{ display: open ? 'block' : 'none' }} className='side-menu-items-icons' />}
                                </List_item_button>
                            </ListItem>

                            {isUserOpen &&
                                <List sx={{ paddingLeft: '20px', py: 0 }}>
                                    {hasPermission("get_web_user", props.user_data.permissions) &&
                                        <ListItem key={"web_users"} disablePadding>
                                            <List_item_button onClick={() => navigate('users')}>
                                                <Arrow_right open={open} />
                                                <ListItemText primary={"Web Users"} sx={{ opacity: open ? 1 : 0 }} className='sidebar-subitem' />
                                            </List_item_button>
                                        </ListItem>
                                    }
                                    {hasPermission("get_portal_user", props.user_data.permissions) &&
                                        <ListItem key={"portal_users"} disablePadding>
                                            <List_item_button onClick={() => navigate('portal_users')}>
                                                <Arrow_right open={open} />
                                                <ListItemText primary={"Portal Users"} sx={{ opacity: open ? 1 : 0 }} className='sidebar-subitem' />
                                            </List_item_button>
                                        </ListItem>
                                    }
                                </List>
                            }
                        </>
                    }

                    {/* <ListItem key={"Complaints"} disablePadding sx={{ display: 'block' }}>
                        <List_item_button onClick={() => navigate("complaints")}>
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 1 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <Flag className='side-menu-items-icons' />
                            </ListItemIcon>
                            <ListItemText primary={"Complaints"} sx={{ opacity: open ? 1 : 0 }} className='side-menu-items' />
                        </List_item_button>
                    </ListItem> */}

                       {/* <ListItem key={"MessageCenter"} disablePadding sx={{ display: 'block' }}>
                        <List_item_button onClick={() => navigate("messageCenter")}>
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 1 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <EmailOutlined className='side-menu-items-icons' />
                            </ListItemIcon>
                            <ListItemText primary={"Message Center"} sx={{ opacity: open ? 1 : 0 }} className='side-menu-items' />
                        </List_item_button>
                    </ListItem> */}

                    {/* Role management */}
                    {(
                        hasPermission("get_roles", props.user_data.permissions)
                        || hasPermission("get_permissions", props.user_data.permissions)
                        || hasPermission("get_pages", props.user_data.permissions)
                    ) &&
                        <>
                            <ListItem key={"roles"} disablePadding sx={{ display: 'block' }}>
                                <List_item_button onClick={() => openSubItemRoleManagement()} >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 1 : 'auto',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <AccountCircleOutlined className='side-menu-items-icons' />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={"Roles"}
                                        sx={{
                                            opacity: open ? 1 : 0,
                                            display: 'flex',
                                            alignItems: 'center',

                                        }}
                                        className='side-menu-items'
                                    />
                                    { /* Display the angle icon on the right side of the text */}
                                    {roleManagement ?
                                        <KeyboardArrowDown className='side-menu-items-icons' /> :
                                        <KeyboardArrowRight sx={{ display: open ? 'block' : 'none' }} className='side-menu-items-icons' />}
                                </List_item_button>
                            </ListItem>
                            {roleManagement &&
                                <List sx={{ paddingLeft: '20px', py: 0 }}>
                                    {hasPermission("get_roles", props.user_data.permissions) &&
                                        <ListItem key={"roles"} disablePadding>
                                            <List_item_button onClick={() => navigate('roles')}>
                                                <Arrow_right open={open} />
                                                <ListItemText primary={"Roles"} sx={{ opacity: open ? 1 : 0 }} className='sidebar-subitem' />
                                            </List_item_button>
                                        </ListItem>
                                    }
                                    {hasPermission("get_permissions", props.user_data.permissions) &&
                                        <ListItem key={"permissions"} disablePadding>
                                            <List_item_button onClick={() => navigate('permissions')}>
                                                <Arrow_right open={open} />
                                                <ListItemText primary={"Permissions"} sx={{ opacity: open ? 1 : 0 }} className='sidebar-subitem' />
                                            </List_item_button>
                                        </ListItem>
                                    }
                                    {hasPermission("get_pages", props.user_data.permissions) &&
                                        <ListItem key={"pages"} disablePadding>
                                            <List_item_button onClick={() => navigate('pages')}>
                                                <Arrow_right open={open} />
                                                <ListItemText primary={"Pages"} sx={{ opacity: open ? 1 : 0 }} className='sidebar-subitem' />
                                            </List_item_button>
                                        </ListItem>
                                    }
                                </List>
                            }
                        </>

                    }

                    {/* Role management End*/}

                    {/* Dropdown management */}
                    {(
                        hasPermission("get_dropdowns", props.user_data.permissions)
                        || hasPermission("get_dropdowns_types", props.user_data.permissions)
                    ) &&
                        <>
                            <ListItem key={"dropdowns"} disablePadding sx={{ display: 'block' }}>
                                <List_item_button onClick={() => setIsDropdowns(!dropdowns)} >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 1 : 'auto',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <AccountCircleOutlined className='side-menu-items-icons' />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={"Dropdowns"}
                                        sx={{
                                            opacity: open ? 1 : 0,
                                            display: 'flex',
                                            alignItems: 'center',

                                        }}
                                        className='side-menu-items'
                                    />
                                    { /* Display the angle icon on the right side of the text */}
                                    {dropdowns ?
                                        <KeyboardArrowDown className='side-menu-items-icons' /> :
                                        <KeyboardArrowRight sx={{ display: open ? 'block' : 'none' }} className='side-menu-items-icons' />}
                                </List_item_button>
                            </ListItem>
                            {dropdowns &&
                                <List sx={{ paddingLeft: '20px', py: 0 }}>
                                    {hasPermission("get_dropdowns", props.user_data.permissions) &&
                                        <ListItem key={"dropdowns"} disablePadding>
                                            <List_item_button open={open} onClick={() => navigate('dropdowns')}>
                                                <Arrow_right open={open} />
                                                <ListItemText primary={"Dropdowns"} sx={{ opacity: open ? 1 : 0 }} className='sidebar-subitem' />
                                            </List_item_button>
                                        </ListItem>
                                    }
                                    {hasPermission("get_dropdowns_types", props.user_data.permissions) &&
                                        <ListItem key={"dropdown_type"} disablePadding>
                                            <List_item_button onClick={() => navigate('dropdowns_type')}>
                                                <Arrow_right open={open} />
                                                <ListItemText primary={"Dropdown Type"} sx={{ opacity: open ? 1 : 0 }} className='sidebar-subitem' />
                                            </List_item_button>
                                        </ListItem>
                                    }
                                </List>
                            }
                        </>
                    }
                    {/* Dropdown management End*/}
                </List>
                <Divider sx={{ backgroundColor: whiteColor }} />
                {/* <List>
                    <ListItem key={"report"} disablePadding sx={{ display: 'block' }}>
                        <List_item_button>
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 1 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <MailIcon className='side-menu-items-icons' />
                            </ListItemIcon>
                            <ListItemText primary={"Report"} sx={{ opacity: open ? 1 : 0 }} className='side-menu-items' />
                        </List_item_button>
                    </ListItem>
                </List> */}
                <List>
                    <ListItem key={"logout"} disablePadding sx={{ display: 'block' }}>
                        <List_item_button onClick={() => setShowAlert(true)}>
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 1 : 'auto',
                                    justifyContent: 'center',
                                    color: "#ff0000"
                                }}
                            >
                                <LogoutOutlined />
                            </ListItemIcon>
                            <ListItemText primary={"Logout"} sx={{ opacity: open ? 1 : 0 }} className='logout-btn' />
                        </List_item_button>
                    </ListItem>
                </List>

            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, width: open ? content : null }}>
                <DrawerHeader />
                {props.children}
                <CustomAlert
                    open={showAlert}
                    onHide={() => setShowAlert(false)}
                    onYesClick={() => handleLogout()}
                    onCancelClick={() => setShowAlert(false)}
                    message="Are you sure you want to Logout?"
                    continueText='Logout'
                />
            </Box>
        </Box>
    );
}


const mapStateToPros = (state) => ({
    token: state.AuthReducer.token,
    user_data: state.AuthReducer.user_data,
    notifications: state.rootReducer.notifications
})
const mapStateToDispatch = {

}


export default connect(mapStateToPros, mapStateToDispatch)(MiniDrawer)