import { Provider } from 'react-redux';
import './App.css';
import MainRoute from './routes/MainRoute';
import { store, persistor } from './redux.js/store';
import { PersistGate } from 'redux-persist/integration/react';
import Toast_Container from './components/Toast_Container';
function App() {
  return (
    <>
    <Toast_Container/>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <MainRoute />
        </PersistGate>
      </Provider>
    </>
  );
}

export default App;
