import { toast } from 'react-toastify';
import Post from '../../api/Post'
import Get from '../../api/Get'
import { ACTIVE_PORTAL_USER, ADD_NEW_SERVICE_CATEGORY, ALL_SERVICE_DATA, CHANGE_SERVICE_STATUS, DASHBOARD_COUNT, REGISTRATIONS, SERVICE_CATEGORY, USER_ROLE } from '../actionType';
import Put from '../../api/Put';
import Delete from '../../api/Delete';
export const setFormData = (data) => ({
  type: 'ADD_SERVICE',
  payload: data,
});



export const add_service = (endPoint, data, token) => {
  return async (dispatch) => {
    try {
      const response = await Post({ endPoint: endPoint, data: data, token: token, content_Type: "multipart/form-data" });
      if (response.success) {
        toast("Service added successfully.", { type: 'success' })
        return response
      } else {
        toast("please try again there is some issue.", { type: 'error' })
        return false
      }
    } catch (error) {
      toast("please try again there is some issue.", { type: 'error' })
      return false
    }
  };
};

export const update_service = (endPoint, data, token) => {
  return async (dispatch) => {
    try {
      const response = await Post({ endPoint: endPoint, data: data, token: token, content_Type: "multipart/form-data" });
      if (response?.success) {
        toast("Service Update Successfully.", { type: 'success' })
        return response
      } else {
        toast("please try again there is some issue.", { type: 'error' })
        return false
      }
    } catch (error) {
      toast("please try again there is some issue.", { type: 'error' })
      return false
    }
  };
};

export const change_service_status = (endPoint, data, token) => {
  return async (dispatch) => {
    try {
      const response = await Post({ endPoint: endPoint, data: data, token: token });
      if (response?.success) {
        return response
      } else {
        toast("please try again there is some issue.", { type: 'error' })
        return false
      }
    } catch (error) {
      toast("please try again there is some issue.", { type: 'error' })
      return false
    }
  };
};

export const delete_service = (endPoint, data, token) => {
  return async (dispatch) => {
    try {
      const response = await Delete({ endPoint: endPoint, data: data, token: token });
      if (response?.success) {
        return response
      } else {
        toast("please try again there is some issue.", { type: 'error' })
        return false
      }
    } catch (error) {
      toast("please try again there is some issue.", { type: 'error' })
      return false
    }
  };
};

export const get_all_services = (endPoint, token) => {
  return async (dispatch) => {
    try {
      const response = await Get({ endPoint: endPoint, token: token });
      if (response.success) {
        dispatch({ type: ALL_SERVICE_DATA, payload: response.data })
        return response
      } else {
        return false
      }
    } catch (error) {
      console.log(error)
      return false
    }
  };
};

export const get_service_detail = (endPoint, token) => {
  return async (dispatch) => {
    try {
      const response = await Get({ endPoint: endPoint, token: token });
      if (response?.success) {
        return response.data
      } else {
        return false
      }
    } catch (error) {
      return false
    }
  };
};

export const add_service_category = (endPoint, data, token) => {
  return async (dispatch) => {
    try {
      const response = await Post({ endPoint: endPoint, data: data, token: token });
      if (response?.success) {
        dispatch({ type: ADD_NEW_SERVICE_CATEGORY, payload: response.data })
        return true
      } else {
        toast("please try again there is some issue.", { type: 'error' })
        return false
      }
    } catch (error) {
      toast("please try again there is some issue.", { type: 'error' })
      return false
    }
  };
};

export const get_dropdowns_by_key = (endPoint, token) => {
  return async (dispatch) => {
    try {
      const response = await Get({ endPoint: endPoint, token: token });
      if (response.success) {
        return response
      } else {
        return false
      }
    } catch (error) {
      return false
    }
  };
};

export const get_web_users = (endPoint, token) => {
  return async (dispatch) => {
    try {
      const response = await Get({ endPoint: endPoint, token: token });
      if (response?.success) {
        return response
      } else {
        return false
      }
    } catch (error) {
      return false
    }
  };
};

export const get_portal_users = (endPoint, token) => {
  return async (dispatch) => {
    try {
      const response = await Get({ endPoint: endPoint, token: token });
      if (response?.success) {
        return response
      } else {
        return false
      }
    } catch (error) {
      return false
    }
  };
};

// add service instant
export const add_service_instant = (endPoint, data, token) => {
  return async (dispatch) => {
    try {
      const response = await Post({ endPoint: endPoint, data: data, token: token, content_Type: "multipart/form-data" });
      if (response?.success) {
        toast(response.message, { type: 'success' })
        return true
      } else {
        toast("please try again there is some issue.", { type: 'error' })
        return false
      }
    } catch (error) {
      toast("please try again there is some issue.", { type: 'error' })
      return false
    }
  };
};
export const get_instance_Detail = (endPoint, token) => {
  return async (dispatch) => {
    try {
      const response = await Get({ endPoint: endPoint, token: token });
      if (response?.success) {
        return response
      } else {
        toast("please try again there is some issue.", { type: 'error' })
        return false
      }
    } catch (error) {
      toast("please try again there is some issue.", { type: 'error' })
      return false
    }
  };
};
// update instacne detail 
export const update_instance_detail = (endPoint, token, data) => {
  return async (dispatch) => {
    try {
      const response = await Put({ endPoint: endPoint, token: token, data: data, content_Type: 'multipart/form-data' });
      if (response?.success) {
        toast(response.message, { type: 'success' })
        return response
      } else {
        toast("please try again there is some issue.", { type: 'error' })
        return false
      }
    } catch (error) {
      toast("please try again there is some issue.", { type: 'error' })
      return false
    }
  };
};

// roles permission
export const get_roles = (endPoint, token) => {
  return async (dispatch) => {
    try {
      const response = await Get({ endPoint: endPoint, token: token });
      if (response?.success) {
        return response
      } else {
        return false
      }
    } catch (error) {
      return false
    }
  };
};
export const get_role_permissions = (endPoint, token) => {
  return async (dispatch) => {
    try {
      const response = await Get({ endPoint: endPoint, token: token });
      if (response?.success) {
        return response
      } else {
        return false
      }
    } catch (error) {
      return false
    }
  };
};
export const update_role_permissions = (endPoint, token, data) => {
  return async (dispatch) => {
    try {
      const response = await Post({ endPoint: endPoint, token: token, data: data });
      if (response.success) {
        return response
      } else {
        return false
      }
    } catch (error) {
      console.log(error)
      return false
    }
  };
};
// permission curd
export const get_permissions = (endPoint, token) => {
  return async (dispatch) => {
    try {
      const response = await Get({ endPoint: endPoint, token: token });
      if (response?.success) {
        return response
      } else {
        return false
      }
    } catch (error) {
      return false
    }
  };
};
export const add_permission = (endPoint, token, data) => {
  return async (dispatch) => {
    try {
      const response = await Post({ endPoint: endPoint, token: token, data: data });
      console.log(response)
      if (response.success) {
        return response
      } else {
        return false
      }
    } catch (error) {
      console.log(error)
      return false
    }
  };
};
export const update_permission = (endPoint, token, data) => {
  return async (dispatch) => {
    try {
      const response = await Put({ endPoint: endPoint, token: token, data: data });
      if (response.success) {
        return response
      } else {
        return false
      }
    } catch (error) {
      return false
    }
  };
};
export const delete_permission = (endPoint, token, data) => {
  return async (dispatch) => {
    try {
      const response = await Delete({ endPoint: endPoint, token: token, data: data });
      if (response.success) {
        return response
      } else {
        return false
      }
    } catch (error) {
      return false
    }
  };
};
// pages curd
export const get_pages = (endPoint, token) => {
  return async (dispatch) => {
    try {
      const response = await Get({ endPoint: endPoint, token: token });
      if (response?.success) {
        return response
      } else {
        return false
      }
    } catch (error) {
      return false
    }
  };
};
export const add_pages = (endPoint, token, data) => {
  return async (dispatch) => {
    try {
      const response = await Post({ endPoint: endPoint, token: token, data: data });
      if (response.success) {
        return response
      } else {
        return false
      }
    } catch (error) {
      return false
    }
  };
};
export const update_page = (endPoint, token, data) => {
  return async (dispatch) => {
    try {
      const response = await Put({ endPoint: endPoint, token: token, data: data });
      if (response.success) {
        return response
      } else {
        return false
      }
    } catch (error) {
      return false
    }
  };
};
export const delete_page = (endPoint, token, data) => {
  return async (dispatch) => {
    try {
      const response = await Delete({ endPoint: endPoint, token: token, data: data });
      if (response.success) {
        return response
      } else {
        return false
      }
    } catch (error) {
      return false
    }
  };
};


// dropdowns curd
export const get_dropdowns = (endPoint, token) => {
  return async (dispatch) => {
    try {
      const response = await Get({ endPoint: endPoint, token: token });
      if (response?.success) {
        return response
      } else {
        return false
      }
    } catch (error) {
      return false
    }
  };
};
export const create_dropdown = (endPoint, token, data) => {
  return async (dispatch) => {
    try {
      const response = await Post({ endPoint: endPoint, token: token, data: data });
      if (response.success) {
        return response
      } else {
        return false
      }
    } catch (error) {
      return false
    }
  };
};
export const update_dropdown = (endPoint, token, data) => {
  return async (dispatch) => {
    try {
      const response = await Put({ endPoint: endPoint, token: token, data: data });
      if (response.success) {
        return response
      } else {
        return false
      }
    } catch (error) {
      return false
    }
  };
};
export const delete_dropdown = (endPoint, token, data) => {
  return async (dispatch) => {
    try {
      const response = await Delete({ endPoint: endPoint, token: token, data: data });
      if (response.success) {
        return response
      } else {
        return false
      }
    } catch (error) {
      return false
    }
  };
};


// dropdown_types curd
export const get_dropdown_types = (endPoint, token) => {
  return async (dispatch) => {
    try {
      const response = await Get({ endPoint: endPoint, token: token });
      if (response?.success) {
        return response
      } else {
        return false
      }
    } catch (error) {
      return false
    }
  };
};
export const create_dropdown_type = (endPoint, token, data) => {
  return async (dispatch) => {
    try {
      const response = await Post({ endPoint: endPoint, token: token, data: data });
      if (response.success) {
        return response
      } else {
        return false
      }
    } catch (error) {
      return false
    }
  };
};
export const update_dropdown_type = (endPoint, token, data) => {
  return async (dispatch) => {
    try {
      const response = await Put({ endPoint: endPoint, token: token, data: data });
      if (response.success) {
        return response
      } else {
        return false
      }
    } catch (error) {
      return false
    }
  };
};
export const delete_dropdown_type = (endPoint, token, data) => {
  return async (dispatch) => {
    try {
      const response = await Delete({ endPoint: endPoint, token: token, data: data });
      if (response.success) {
        return response
      } else {
        return false
      }
    } catch (error) {
      return false
    }
  };
};

export const get_all_registration = (endPoint, token) => {
  return async (dispatch) => {
    try {
      const response = await Get({ endPoint: endPoint, token: token });
      if (response?.success) {
        dispatch({ type: REGISTRATIONS, payload: response.data })
        return true
      } else {
        dispatch({ type: REGISTRATIONS, payload: [] })
        return false
      }
    } catch (error) {
      console.log(error)
      return false
    }
  };
};

export const get_dashboardCount = (endPoint, token) => {
  return async (dispatch) => {
    try {
      const response = await Get({ endPoint: endPoint, token: token });
      console.log(endPoint)
      if (response?.success) {
        dispatch({ type: DASHBOARD_COUNT, payload: response.data })
        return true
      } else {
        dispatch({ type: DASHBOARD_COUNT, payload: null })
        return false
      }
    } catch (error) {
      console.log(error)
      return false
    }
  };
};

export const get_dashboards_types = (endPoint, token) => {
  return async (dispatch) => {
    try {
      const response = await Get({ endPoint: endPoint, token: token });
      if (response?.success) {
        return response.data
      } else {
        return []
      }
    } catch (error) {
      console.log(error)
      return false
    }
  };
};

export const get_user_roles = (endPoint, token) => {
  return async (dispatch) => {
    try {
      const response = await Get({ endPoint: endPoint, token: token });
      if (response.success) {
        dispatch({ type: USER_ROLE, payload: response.data })
        return true
      } else {
        dispatch({ type: USER_ROLE, payload: response.data })
        return false
      }
    } catch (error) {
      return false
    }
  };
};

// get all active user for dropdown
export const get_active_portal_user = (endPoint, token) => {
  return async (dispatch) => {
    try {
      const response = await Get({ endPoint: endPoint, token: token });
      // console.log(response)
      if (response.success) {
        dispatch({ type: ACTIVE_PORTAL_USER, payload: response.data })
        return true
      } else {
        dispatch({ type: ACTIVE_PORTAL_USER, payload: [] })
        return false
      }
    } catch (error) {
      dispatch({ type: ACTIVE_PORTAL_USER, payload: [] })
      return false
    }
  };
};
// assign task
export const assign_task = (endPoint, token, data) => {
  return async (dispatch) => {
    try {
      const response = await Put({ endPoint: endPoint, token: token, data: data });
      if (response.success) {
        toast(response.message, { type: 'success' })
        return true
      } else {
        return false
      }
    } catch (error) {
      return false
    }
  };
};

// get all flowusp
export const get_flowup = (endPoint, token) => {
  return async (dispatch) => {
    try {
      const response = await Get({ endPoint: endPoint, token: token });
      if (response.success) {
        return response
      } else {
        return false
      }
    } catch (error) {
      return false
    }
  };
};

// create flowups
export const create_FlowUps = (endPoint, token, data) => {
  return async (dispatch) => {
    try {
      const response = await Post({ endPoint: endPoint, token: token, data: data, content_Type: "multipart/form-data" });
      if (response.success) {
        toast(response.message, { type: 'success' })
        return true
      } else {
        return false
      }
    } catch (error) {
      return false
    }
  };
};

// get all flowusp
export const get_Steps = (endPoint, token) => {
  return async (dispatch) => {
    try {
      const response = await Get({ endPoint: endPoint, token: token });
      if (response.success) {
        return response
      } else {
        return false
      }
    } catch (error) {
      return false
    }
  };
};
// create flowups
export const create_Steps = (endPoint, token, data) => {
  return async (dispatch) => {
    try {
      const response = await Post({ endPoint: endPoint, token: token, data: data });
      if (response.success) {
        toast(response.message, { type: 'success' })
        return true
      } else {
        return false
      }
    } catch (error) {
      return false
    }
  };
};

// search users
export const search_user = (endPoint, token) => {
  return async (dispatch) => {
    try {
      const response = await Get({ endPoint: endPoint, token: token });
      if (response.success) {
        return response.data
      } else {
        return []
      }
    } catch (error) {
      return false
    }
  };
};
// complete step
export const complete_step = (endPoint, token, data) => {
  return async (dispatch) => {
    try {
      const response = await Post({ endPoint: endPoint, token: token, data: data, content_Type: "multipart/form-data" });
      if (response.success) {
        toast("Completion step updated successfully.", { type: 'success' })
        return true
      } else {
        return false
      }
    } catch (error) {
      return false
    }
  };
};
// on Final completion step
export const onAllStepCompleted = (endPoint, token, data) => {
  return async (dispatch) => {
    try {
      const response = await Post({ endPoint: endPoint, token: token, data: data });
      if (response.success) {
        toast(response.message, { type: 'success' })
        return true
      } else {
        return false
      }
    } catch (error) {
      return false
    }
  };
};

export const Update_User = (endPoint, token, data) => {
  return async (dispatch) => {
    try {
      const response = await Put({ endPoint: endPoint, token: token, data: data });
      if (response.success) {
        toast(response.message, { type: 'success' })
        return response
      } else {
        return false
      }
    } catch (error) {
      return false
    }
  };
};

export const AddUserQueries = (endPoint, token, data) => {
  return async (dispatch) => {
    try {
      const response = await Post({ endPoint: endPoint, token: token, data: data, content_Type: "multipart/form-data" });
      if (response.success) {
        return response
      } else {
        return false
      }
    } catch (error) {
      return false
    }
  };
};

export const getUserFlowUps = (endPoint, token) => {
  return async (dispatch) => {
    try {
      const response = await Get({ endPoint: endPoint, token: token });
      if (response.success) {
        return response.data
      } else {
        return []
      }
    } catch (error) {
      return false
    }
  };
};


export const getServiceInstanceCount = (endPoint, token) => {
  return async (dispatch) => {
    try {
      const response = await Get({ endPoint: endPoint, token: token });
      if (response.success) {
        return response.data
      } else {
        return []
      }
    } catch (error) {
      return false
    }
  };
};