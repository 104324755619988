import React, { useEffect, useState } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Button, Container, Grid, TextField, Modal, Typography, MenuItem, ListItemIcon, Divider, FormHelperText } from '@mui/material';
import { connect } from 'react-redux';
import { add_pages, delete_page, get_pages, update_page } from '../../redux.js/actions';
import LoadingSpinner from '../../components/loader/LoadingSpinner';
import { headingColor } from '../../util/colors';
import AccountMenu from '../../components/Common/MoreMenu';
import { Delete, Update } from '@mui/icons-material';
import { hasPermission } from '../../util/Fun';

const Pages = (props) => {
    const [data, setData] = useState({ rows: [], openModal: false, pageName: '', selectedPage: null, isLoading: false });
    const [errors, setErrors] = useState({ pageName: '' });

    const columns = [
        { field: 'id', headerName: 'Id', flex: 1 },
        { field: 'page_name', headerName: 'Page Name', flex: 1 },
        {
            field: 'action',
            headerName: 'Action',
            flex: 1,
            renderCell: (params) => (
                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}>
                    <AccountMenu>
                        {hasPermission("update_page", props.user_data.permissions) &&
                            <MenuItem onClick={() => onPressUpdate(params.row)}>
                                <ListItemIcon>
                                    <Update fontSize="small" />
                                </ListItemIcon>
                                Update
                            </MenuItem>
                        }
                        {hasPermission("delete_page", props.user_data.permissions) &&
                            <div>
                                <Divider />
                                <MenuItem onClick={() => handleDeletePage(params.row.id)}>
                                    <ListItemIcon>
                                        <Delete fontSize="small" />
                                    </ListItemIcon>
                                    Delete
                                </MenuItem>
                            </div>
                        }
                    </AccountMenu>
                </div>
            ),
        },
    ];

    useEffect(() => {
        getPages();
    }, []);

    const getPages = async () => {
        try {
            setData((prevData) => ({ ...prevData, isLoading: true }));
            const result = await props.get_pages(`api/get_pages?id=${props.user_data.id}`, props.token);
            if (result) {
                setData((prevData) => ({ ...prevData, rows: result.data, isLoading: false }));
            } else {
                setData((prevData) => ({ ...prevData, rows: [], isLoading: false }));
            }
        } catch (error) {
            console.error('Error fetching permissions:', error);
            setData((prevData) => ({ ...prevData, isLoading: false }));
        }
    };

    const handleOpenModal = () => {
        setData((prevData) => ({ ...prevData, openModal: true }));
    };

    const handleCloseModal = () => {
        setData((prevData) => ({ ...prevData, openModal: false, selectedPage: null, pageName: '' }));
        setErrors({ pageName: '' });
    };

    const handleInputChange = (e) => {
        setData((prevData) => ({ ...prevData, pageName: e.target.value }));
        setErrors((prevErrors) => ({ ...prevErrors, pageName: '' }));
    };

    const handleAddPage = async () => {
        if (data.pageName.trim() === '') {
            setErrors((prevErrors) => ({ ...prevErrors, pageName: 'Page name is required' }));
            return;
        }
        setData((prevData) => ({ ...prevData, isLoading: true }));
        await props.add_pages(`api/created_page?id=${props.user_data.id}`, props.token, { page_name: data.pageName });
        handleCloseModal()
        getPages()
    };

    const handleUpdatePage = async () => {
        if (!data.selectedPage || data.pageName.trim() === '') {
            setErrors((prevErrors) => ({ ...prevErrors, pageName: 'Page name is required' }));
            return;
        }
        setData((prevData) => ({ ...prevData, isLoading: true }));
        await props.update_page(`api/update_page?id=${props.user_data.id}`, props.token, { id: data.selectedPage.id, page_name: data.pageName });
        getPages()
        handleCloseModal()
    };

    const handleDeletePage = async (id) => {
        setData((prevData) => ({ ...prevData, isLoading: true }));
        await props.delete_page(`api/delete_page?id=${props.user_data.id}`, props.token, { id: id });
        getPages();
        handleCloseModal()
    };

    const onPressUpdate = (row) => {
        setData((prevData) => ({ ...prevData, selectedPage: row, pageName: row.page_name, openModal: true }));
    };




    return (
        <Container sx={{ pt: 5 }}>
            <Grid container columns={12} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                <h4 style={{ color: headingColor, fontWeight: 'bold' }}>Permissions</h4>
                {hasPermission("created_page", props.user_data.permissions) &&
                    <Button variant="outlined" onClick={handleOpenModal}>Create New Page</Button>
                }
            </Grid>
            <DataGrid
                rows={data.rows}
                columns={columns}
                slots={{ toolbar: GridToolbar }}
                autoHeight
                rowHeight={50}
                getRowId={(row) => row.id}
            />
            {data.isLoading && <LoadingSpinner />}
            <Modal
                open={data.openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Container sx={style}>
                    <Typography variant="h6" component="h2">{data.selectedPage ? 'Update Page' : 'Add New Page'}</Typography>
                    <TextField
                        size='small'
                        label="Page Name"
                        variant="outlined"
                        fullWidth
                        value={data.pageName}
                        onChange={handleInputChange}
                        error={!!errors.pageName}
                        helperText={errors.pageName}
                        sx={{ mt: 2 }}
                    />
                    <Button variant="contained" onClick={data.selectedPage ? handleUpdatePage : handleAddPage} sx={{ mt: 2 }}>
                        {data.selectedPage ? 'Update Page' : 'Add Page'}
                    </Button>
                </Container>
            </Modal>
        </Container>
    );
};

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const mapStateToPros = (state) => ({
    user_data: state.AuthReducer.user_data,
    token: state.AuthReducer.token,
});

const mapStateToDispatch = {
    get_pages: get_pages,
    add_pages: add_pages,
    update_page: update_page,
    delete_page: delete_page,
};

export default connect(mapStateToPros, mapStateToDispatch)(Pages);
