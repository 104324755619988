import React, { useState } from 'react';
import { Button, Modal, Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const CustomModal = ({ handleClose, open, children, modal_header = "Modal Header" }) => {
    return (
        <Modal
            open={open}
            onClose={handleClose}
            disableEnforceFocus  // Disables enforcing focus within the modal
            disableAutoFocus    // Disables auto-focusing on the first interactive element
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    pt: 3,
                    pb: 3,
                    pl: 3,
                    pr: 3,
                    borderRadius: 2,
                    maxHeight: '80vh',
                    overflowY: 'auto',
                }}
            >
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Typography variant="h6">{modal_header}</Typography>
                    <IconButton onClick={handleClose} size="small">
                        <CloseIcon />
                    </IconButton>
                </Box>
                {children}
            </Box>
        </Modal>
    );
};

export default CustomModal;
