import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import LoadingSpinner from '../loader/LoadingSpinner';
import {
    AppBar,
    Box,
    Container,
    Dialog,
    Divider,
    Grid,
    IconButton,
    Slide,
    Toolbar,
    Typography,
    FormControl,
    FormGroup,
    FormControlLabel,
    Checkbox,
    TextField,
    Select,
    MenuItem,
    useMediaQuery,
    useTheme
} from '@mui/material';
import { Cancel } from '@mui/icons-material';
import { get_dropdowns_by_key, get_instance_Detail } from '../../redux.js/actions';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ViewInstantDetail = ({
    isOpen,
    user_data,
    token,
    instance_id,
    get_instance_Detail,
    onClose,
    all_dropdowns,
    dropdown_types,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [detail, setDetail] = useState({});
    const [dropdownOptions, setDropdownOptions] = useState({});
    let InputTypes = all_dropdowns.inputTypes || []
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    useEffect(() => {
        if (instance_id) {
            fetchInstanceDetail(instance_id);
        }
    }, [instance_id]);

    const fetchInstanceDetail = async (instance_id) => {
        setIsLoading(true);
        try {
            const result = await get_instance_Detail(
                `api/get_instant_detail?id=${user_data.id}&instance_id=${instance_id}`,
                token
            );
            if (result.success) {
                setDetail(result.data);
                let keys = extractDropdownKeys(result.data);
                fetchDropdownOptions(keys);
            }
        } catch (error) {
            console.error('Error fetching instance detail:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const extractDropdownKeys = (data) => {
        const keys = new Set();
        const processParams = (params) => {
            if (!Array.isArray(params)) {
                return; // Exit early if params is not an array
            }
            params.forEach(param => {
                if (param.options) {
                    let options = JSON.parse(param.options);
                    if (checkInputType(param.type) === 'dropdown' && options) {
                        keys.add({ key: options.key, parameter_id: param.parameter_id });
                    }
                } else {
                    if (checkInputType(param.type) === 'multi') {
                        let nestedParameters = param.sub_parameters
                        nestedParameters.map((v, i) => {
                            processParams(v)
                        })
                    }
                }
            });
        };

        processParams(data.parameters); // Start processing from the top level of parameters
        return [...keys];
    };

    const fetchDropdownOptions = async (dropdownKeys) => {
        const fetchedOptions = {};
        for (let dropdown of dropdownKeys) {
            let dropdownKey = checkDropdownKey(dropdown.key);
            // const response = await get_dropdowns_by_key(`api/get_dropdowns_by_key?id=${user_data.id}&key=${dropdownKey}`, token);
            fetchedOptions[dropdown.key] = all_dropdowns[dropdownKey] || [];
        }
        setDropdownOptions(fetchedOptions);
    };

    const renderParameter = (parameter) => {
        const { parameter_name, parameter_value, sub_parameters, type, options } = parameter;
        let key = null;
        if (options) {
            key = JSON.parse(options);
        }
        switch (checkInputType(type)) {
            case 'text':
                return (
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label={parameter_name}
                            value={parameter_value || ''}
                            disabled
                            size='small'
                            margin="normal"
                        />
                    </Grid>
                );
            case 'tel':
                return (
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label={parameter_name}
                            value={parameter_value || ''}
                            disabled
                            type='number'
                            margin="normal"
                            size='small'
                        />
                    </Grid>
                );
            case 'dropdown':
                return (
                    <Grid item xs={12} sm={6} md={4}>
                        <FormControl fullWidth variant="outlined" margin="normal">
                            <Select
                                fullWidth
                                value={parameter_value || ''}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Select' }}
                                disabled
                                size='small'
                            >
                                {dropdownOptions[key.key] && dropdownOptions[key.key].map((dropdown) => (
                                    <MenuItem key={dropdown.dropdown_id} value={dropdown.dropdown_id}>{dropdown.title}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                );
            case 'checkbox':
                return (
                    <Grid item xs={12} sm={6} md={4}>
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox checked={parameter_value} disabled />}
                                label={parameter_name}
                            />
                        </FormGroup>
                    </Grid>
                );
            case 'file':
                return (
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label={parameter_name}
                            // value={parameter_value || ''}
                            disabled
                            size='small'
                            margin="normal"
                            InputProps={{
                                endAdornment: parameter_value && (
                                    <IconButton
                                        component="a"
                                        href={parameter_value}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        color="primary"
                                        size='small'
                                    >
                                        Open File
                                    </IconButton>
                                ),
                            }}
                        />
                    </Grid>
                );
            case 'multi':
                return (
                    <Container maxWidth="lg">
                        <Box sx={{ p: 1 }}>
                            <Typography>{parameter_name}</Typography>
                            <Grid container spacing={2}>
                                {sub_parameters && sub_parameters.map((subParam, index) => (
                                    <React.Fragment key={index}>
                                        {index > 0 && (
                                            <Grid container sx={{ bgcolor: 'gray' }}>
                                                <Divider />
                                            </Grid>
                                        )}
                                        {subParam.map((p, i) => (
                                            <React.Fragment key={i}>
                                                {renderParameter(p)}
                                            </React.Fragment>
                                        ))}
                                    </React.Fragment>
                                ))}
                            </Grid>
                        </Box>
                    </Container>
                );
            default:
                return null;
        }
    };

    const checkInputType = (value) => {
        const input = InputTypes.find(v => v.dropdown_id == value);
        return input?.value || "";
    };

    const checkDropdownKey = (value) => {
        const input = dropdown_types.find(v => v.dropdown_type_id == value);
        return input?.type || "";
    };

    return (
        <>
            {isLoading && <LoadingSpinner />}
            <Dialog fullScreen open={isOpen} onClose={onClose} TransitionComponent={Transition}>
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                            <Cancel />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Instance Detail
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Container maxWidth="lg">
                    <Box sx={{ pt: 5 }}>
                        <Grid container spacing={2}>
                            {Object.keys(detail).length > 0 &&
                                detail.parameters.map((parameter, index) => (
                                    <React.Fragment key={index}>
                                        {renderParameter(parameter)}
                                    </React.Fragment>
                                ))}
                        </Grid>
                    </Box>
                </Container>
            </Dialog>
        </>
    );
};

const mapStateToProps = (state) => ({
    user_data: state.AuthReducer.user_data,
    token: state.AuthReducer.token,
    dropdown_types: state.rootReducer.dropdown_types,
    all_dropdowns: state.rootReducer.all_dropdowns
});

const mapDispatchToProps = {
    get_instance_Detail: get_instance_Detail,
    get_dropdowns_by_key: get_dropdowns_by_key
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewInstantDetail);
