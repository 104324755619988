import React, { useState } from 'react';
import { Grid, Typography, TextField, Paper, List, ListItem, ListItemText } from '@mui/material';
import { connect } from 'react-redux';
import { search_user } from '../../redux.js/actions';
import { headingColor } from '../../util/colors';

function SearchUser({ user_data, token, search_user, onSelect }) {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);

    const handleSearch = async (value) => {
        setSearchTerm(value);
        if (value.length >= 3) {
            try {
                let result = await search_user(`api/search_user?id=${user_data.id}&query=${value}`, token);
                setSearchResults(result);
            } catch (error) {
                console.error('Error fetching users:', error);
                setSearchResults([]);
            }
        } else {
            setSearchResults([]);
        }
    };

    const handleSelectUser = (user) => {
        setSearchTerm(user.email);
        setSearchResults([]);
        onSelect(user.id)
    };

    const handleInputChange = (event) => {
        const { value } = event.target;
        setSearchTerm(value);
        handleSearch(value);
    };

    return (
        <Grid container alignItems="center" spacing={2} sx={{ pt: 2, pb: 2 }}>
            <Grid item xs={12}>
                <Typography variant="h6" sx={{ color: headingColor }}>
                    Select User
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12} lg={8}>
                <TextField
                    size='small'
                    label="Search input"
                    value={searchTerm}
                    onChange={handleInputChange}
                    variant="outlined"
                    fullWidth
                    InputProps={{
                        type: 'search',
                    }}
                />
                <Paper elevation={3} sx={{ position: 'absolute', zIndex: 9999, maxHeight: 300, overflowY: 'auto', mt: 1 }}>
                    <List>
                        {searchResults.length > 0 && (
                            searchResults.map((user) => (
                                <ListItem key={user.id} button onClick={() => handleSelectUser(user)}>
                                    <ListItemText primary={user.full_name} secondary={user.email} />
                                </ListItem>
                            ))
                        )
                        }
                    </List>
                </Paper>
            </Grid>
        </Grid>
    );
}

const mapStateToProps = (state) => ({
    user_data: state.AuthReducer.user_data,
    token: state.AuthReducer.token,
});

const mapDispatchToProps = {
    search_user: search_user,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchUser);
