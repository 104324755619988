import { KeyboardArrowRight } from '@mui/icons-material'
import { ListItemIcon } from '@mui/material'
import React from 'react'

export default function Arrow_right({open}) {
  return (
      <ListItemIcon
          sx={{
              minWidth: 0,
              mr: open ? 1 : 'auto',
              justifyContent: 'center',
          }}
      >
          <KeyboardArrowRight fontSize='small' className='sidebar-subitem-icon' />
      </ListItemIcon>
  )
}
