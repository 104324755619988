import { useSelector } from "react-redux";
import { formatDistanceToNow, isYesterday, format } from 'date-fns';
export const resetCacheAndReload = () => {
    // Clear cache
    caches.keys().then(function (names) {
        for (let name of names) {
            caches.delete(name);
        }
    });

    // Perform a hard reload
    window.location.reload(true);
}


export const groupByCategory = (services) => {
    const groupedData = services.reduce((acc, service) => {
        const category = service.category_name;
        if (!acc[category]) {
            acc[category] = { category, services: [] };
        }
        acc[category].services.push(service);
        return acc;
    }, {});

    return Object.values(groupedData);
};


export const checkPagesPermission = (value, list) => {
    return list.includes(value);
}
export const hasPermission = (value, list) => {
    return list.some(permission => permission.permission_value === value)
}


export const formatNotificationTime = (createdOn) => {
    const date = new Date(createdOn);
    const now = new Date();

    if (isYesterday(date)) {
        return 'Yesterday';
    } else if (now - date < 24 * 60 * 60 * 1000) {
        return formatDistanceToNow(date, { addSuffix: true }); // e.g., "5 minutes ago"
    } else {
        return format(date, 'MMMM dd, yyyy'); // e.g., "August 27, 2024"
    }
};

