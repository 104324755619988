import React, { useState, useRef, useEffect } from 'react';
import {
    AppBar,
    Dialog,
    IconButton,
    Slide,
    Toolbar,
    Typography,
    TextField,
    Button,
    Box,
    Grid,
    Paper,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    ListItemAvatar,
    Avatar,
    Chip,
} from '@mui/material';
import { Cancel, AddCircle, AttachFile } from '@mui/icons-material';

import { connect } from 'react-redux';
import { create_FlowUps } from '../../redux.js/actions';
import LoadingSpinner from '../loader/LoadingSpinner';
import { toast } from 'react-toastify';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function FlowUp({ data = [], is_flowUp, onClose, token, user_data, create_FlowUps }) {
    console.log("is Flow Up", is_flowUp)
    const [newFlowupMessage, setNewFlowupMessage] = useState('');
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const listRef = useRef(null);
    const lastItemRef = useRef(null);

    useEffect(() => {
        if (is_flowUp && data.length > 0) {
            if (lastItemRef.current) {
                lastItemRef.current.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [is_flowUp, data]);

    const handleFileChange = (event) => {
        const newFiles = Array.from(event.target.files);
        setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
    };

    const handleDeleteFile = (index) => {
        setSelectedFiles((prevFiles) => prevFiles.filter((file, i) => i !== index));
    };

    const handleAddFlowup = async () => {
        if (!newFlowupMessage.trim()) {
            alert('Message is required.');
            return;
        }

        setIsLoading(true);

        const formData = new FormData();
        formData.append('message', newFlowupMessage);
        formData.append('instance_id', is_flowUp);
        selectedFiles.forEach((file, index) => {
            formData.append(`files[${index}]`, file);
        });

        try {
            await create_FlowUps(`api/add_flowup?id=${user_data.id}`, token, formData);
            setNewFlowupMessage('');
            setSelectedFiles([]);
            onClose()
        } catch (error) {
            console.error('Error adding flowup:', error);
            toast('Failed to add flowup. Please try again.', { type: "error" });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Dialog fullScreen open={is_flowUp ? true : false} onClose={onClose} TransitionComponent={Transition}>
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                        <Cancel />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Flow Up
                    </Typography>
                </Toolbar>
            </AppBar>
            {isLoading && <LoadingSpinner />}
            <Box sx={{ p: 2 }}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                    Existing Flowups
                </Typography>
                <List
                    ref={listRef}
                    sx={{ width: '100%', bgcolor: 'background.paper', overflow: 'auto', maxHeight: 400 }}
                >
                    {data.map((flowup, index) => (
                        <ListItem
                            key={flowup.flowup_id}
                            disablePadding
                            ref={index === data.length - 1 ? lastItemRef : null}
                        >
                            <ListItemAvatar>
                                <Avatar
                                    alt="Flowup User"
                                    sx={{ backgroundColor: user_data.id === flowup.user_id ? 'green' : 'gray' }}
                                />
                            </ListItemAvatar>
                            <ListItemText primary={flowup.message} secondary={flowup.created_on} />
                            {flowup.file_paths.length > 0 && (
                                <ListItemSecondaryAction>
                                    {flowup.file_paths.map((filePath, fileIndex) => (
                                        <a key={fileIndex} href={filePath} target="_blank" rel="noopener noreferrer" download>
                                            <Chip
                                                label={`File ${fileIndex + 1}`}
                                                icon={<AttachFile />}
                                                style={{ marginRight: '8px', marginBottom: '8px' }}
                                                variant="outlined"
                                            />
                                        </a>
                                    ))}
                                </ListItemSecondaryAction>
                            )}
                        </ListItem>
                    ))}
                </List>
                <Typography variant="h6" sx={{ mt: 3 }}>
                    Create New Flowup
                </Typography>
                <Paper sx={{ p: 2 }}>
                    <TextField
                        fullWidth
                        multiline
                        rows={4}
                        variant="outlined"
                        label="Type your message"
                        value={newFlowupMessage}
                        onChange={(e) => setNewFlowupMessage(e.target.value)}
                        sx={{ mb: 2 }}
                    />
                    <input
                        id="attachment-upload"
                        multiple
                        type="file"
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                    />
                    <label htmlFor="attachment-upload">
                        <Button variant="contained" component="span" startIcon={<AttachFile />}>
                            Attach File
                        </Button>
                    </label>
                    {selectedFiles.length > 0 && (
                        <Grid container spacing={1} mt={2}>
                            {selectedFiles.map((file, index) => (
                                <Grid item key={index}>
                                    <Chip
                                        label={file.name}
                                        onDelete={() => handleDeleteFile(index)}
                                        sx={{ marginRight: 1, marginBottom: 1 }}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    )}

                    <Box sx={{ mt: 2 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<AddCircle />}
                            onClick={handleAddFlowup}
                        >
                            Add Flowup
                        </Button>
                    </Box>
                </Paper>
            </Box>
        </Dialog>
    );
}

const mapStateToProps = (state) => ({
    token: state.AuthReducer.token,
    user_data: state.AuthReducer.user_data,
});

const mapDispatchToProps = {
    create_FlowUps: create_FlowUps,
};

export default connect(mapStateToProps, mapDispatchToProps)(FlowUp);
