import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { add_service, get_dropdowns_by_key, update_service } from '../../redux.js/actions';
import { INPUT_TYPES, TRUST_CATEGORY } from '../../redux.js/actionType';
import { Box, Button, Checkbox, Container, Divider, FormControl, FormControlLabel, Grid, InputLabel, ListItemText, MenuItem, Paper, Select, TextField, Typography } from '@mui/material';
import { headingColor } from '../../util/colors';
import ImageIcon from '@mui/icons-material/Image';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { v4 as uuidv4 } from 'uuid';
import Toast_Container from '../../components/Toast_Container';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';


const imageFormats = ['JPEG', 'PNG', 'GIF', 'BMP', 'TIFF', 'SVG'];
const fileFormats = ['PDF', 'DOCX', 'XLSX', 'TXT', 'CSV', 'ZIP', 'RAR'];
function AddUpdateService(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const { row } = location.state || {};
  const navigation = useNavigate();
  const [existingParametersId, setExistingParameterID] = useState([])
  const [parameters_to_delete, setParameters_to_delete] = useState([])
  const InputTypes = props.all_dropdowns.inputTypes || []
  const [states, setStates] = useState({
    openModal: false,
    selectedService: null,
    isLoading: false,
    title: "",
    category: '',
    description: '',
    icon: '',
    iconURL: '',
    created_by: props.user_data.id,
    is_active: false,
    form_heading: '',
    parameters: [
      {
        parameter_id: uuidv4(),
        name: '',
        label: '',
        type: '',
        key: '',
        fileFormats: ['PDF'],
        imageFormats: [],
        required: false,
        display_order: 1,
        sub_parameters: []
      }
    ]
  });
  const [errors, setErrors] = useState({
    title: '',
    category: '',
    description: '',
    icon: '',
    form_heading: '',
    key: '',
    fileFormats: '',
    imageFormats: '',
    parameters: []
  });
  useEffect(() => {
    if (row) {
      setStates({
        ...states,
        title: row.title,
        category: row.category,
        description: row.description,
        iconURL: row.icon,
        is_active: row.is_active,
        form_heading: row.form_heading,
        parameters: row.parameters.map(param => ({
          ...param,
          required: stringToBoolean(param.required),
          sub_parameters: param.sub_parameters ? param.sub_parameters.map(subParam => ({
            ...subParam,
            required: stringToBoolean(subParam.required),
            options: subParam.options ? parseOptions(subParam.options) : [],
            ...parseOptions(subParam.options)
          })) : [],
          options: param.options ? parseOptions(param.options) : [],
          ...parseOptions(param.options)
        }))
      });
      setExistingParameterID(getAllParameterIds(row.parameters))
    }
  }, [row]);

  function getAllParameterIds(parameters) {
    let ids = [];
    for (let param of parameters) {
      if (param.parameter_id) ids.push(param.parameter_id);
      if (param.sub_parameters && param.sub_parameters.length > 0) {
        ids = ids.concat(getAllParameterIds(param.sub_parameters));
      }
    }
    return ids;
  }
  const stringToBoolean = (value) => {
    if (value == '1') {
      return true
    } else {
      return false
    }
  };
  const parseOptions = (options) => {
    if (!options) {
      return {};
    }

    const parsedOptions = {};
    try {
      const parsed = JSON.parse(options);
      for (const [key, value] of Object.entries(parsed)) {
        if (key === 'key') {
          parsedOptions.key = value;
        } else if (key === 'file') {
          parsedOptions.fileFormats = value;
        } else if (key === 'image') {
          parsedOptions.imageFormats = value;
        }
      }
    } catch (e) {
      console.error('Failed to parse options:', e);
    }
    return parsedOptions;
  };


  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const imageUrl = URL.createObjectURL(file);
      setStates((prevData) => ({ ...prevData, icon: file, iconURL: imageUrl }));
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: ''
    }));
    setStates((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleParameterChange = (indices, key, value) => {
    const updateParameters = (parameters, indices) => {
      if (indices.length === 1) {
        if (key === 'type') {
          parameters[indices[0]][key] = value;
          parameters[indices[0]].fileFormats = parameters[indices[0]].fileFormats || [];
          parameters[indices[0]].imageFormats = parameters[indices[0]].imageFormats || [];
          parameters[indices[0]].key = parameters[indices[0]].key || "";
        } else if (key === 'required') {
          parameters[indices[0]][key] = !!value;  // Ensure the value is a boolean
        } else {
          parameters[indices[0]][key] = value || '';
        }
      } else {
        const currentIndex = indices[0];
        const restIndices = indices.slice(1);
        parameters[currentIndex].sub_parameters = updateParameters(parameters[currentIndex].sub_parameters, restIndices);
      }
      return parameters;
    };

    setStates((prevData) => ({
      ...prevData,
      parameters: updateParameters([...prevData.parameters], indices)
    }));
  };




  const addParameter = () => {
    setStates((prevData) => ({
      ...prevData,
      parameters: [
        ...prevData.parameters,
        {
          parameter_id: uuidv4(),
          name: '',
          label: '',
          type: '',
          required: false,
          key: '',
          fileFormats: [],
          imageFormats: [],
          display_order: prevData.parameters.length + 1,
          sub_parameters: []
        }
      ]
    }));
  };

  const removeParameter = (indices, para) => {
    const updateParameters = (parameters, indices) => {
      if (indices.length === 1) {
        parameters.splice(indices[0], 1);
      } else {
        const currentIndex = indices[0];
        const restIndices = indices.slice(1);
        parameters[currentIndex].sub_parameters = updateParameters(parameters[currentIndex].sub_parameters, restIndices);
      }
      return parameters;
    };
    if (row) {
      let paraIds = getAllParameterIds([para]);
      paraIds.forEach(id => {
        if (existingParametersId.includes(id)) {
          setParameters_to_delete(prev => ([...prev, id]));
        }
      });
    }
    setStates((prevData) => ({
      ...prevData,
      parameters: updateParameters([...prevData.parameters], indices),
    }));
  };


  const addSubParameter = (indices) => {
    const updateParameters = (parameters, indices) => {
      if (indices.length === 1) {
        parameters[indices[0]].sub_parameters.push({
          parameter_id: uuidv4(),
          name: '',
          label: '',
          type: '',
          required: false,
          key: '',
          fileFormats: [],
          imageFormats: [],
          display_order: parameters[indices[0]].sub_parameters.length + 1,
          sub_parameters: []
        });
      } else {
        const currentIndex = indices[0];
        const restIndices = indices.slice(1);
        parameters[currentIndex].sub_parameters = updateParameters(parameters[currentIndex].sub_parameters, restIndices);
      }
      return parameters;
    };

    setStates((prevData) => ({
      ...prevData,
      parameters: updateParameters([...prevData.parameters], indices)
    }));
  };

  const validateParameters = (parameters) => {
    const errors = parameters.map(param => {
      const paramErrors = {};
      if (!param.name) paramErrors.name = 'Name is required';
      if (!param.label) paramErrors.label = 'Label is required';
      if (!param.type) paramErrors.type = 'Type is required';

      // Additional checks based on parameter type
      if (checkInputType(param.type) === 'checkbox' || checkInputType(param.type) === 'radio' || checkInputType(param.type) === 'dropdown') {
        if (!param.key) paramErrors.key = 'Key is required for Checkbox, Radio, or Dropdown';
      } else if (checkInputType(param.type) === 'file') {
        if (!param.fileFormats || param.fileFormats.length === 0) paramErrors.fileFormats = 'File format is required';
      } else if (checkInputType(param.type) === 'image') {
        if (!param.imageFormats || param.imageFormats.length === 0) paramErrors.imageFormats = 'Image format is required';
      }

      if (param.sub_parameters && param.sub_parameters.length > 0) {
        const subParamErrors = validateParameters(param.sub_parameters);
        if (subParamErrors.length > 0) {
          paramErrors.sub_parameters = subParamErrors;
        }
      }

      return Object.keys(paramErrors).length > 0 ? paramErrors : null;
    });

    return errors.filter(error => error !== null);
  };

  const moveParameter = (indices, direction) => {
    const updateParameters = (parameters, indices) => {
      if (indices.length === 1) {
        const index = indices[0];
        const newIndex = index + direction;

        if (newIndex < 0 || newIndex >= parameters.length) return parameters;

        // Swap the parameters
        const temp = parameters[index];
        parameters[index] = parameters[newIndex];
        parameters[newIndex] = temp;

        // Update display_order
        parameters[index].display_order = index + 1;
        parameters[newIndex].display_order = newIndex + 1;
      } else {
        const currentIndex = indices[0];
        const restIndices = indices.slice(1);
        parameters[currentIndex].sub_parameters = updateParameters(parameters[currentIndex].sub_parameters, restIndices);
      }
      return parameters;
    };

    setStates((prevData) => ({
      ...prevData,
      parameters: updateParameters([...prevData.parameters], indices)
    }));
  };
  // console.log(states.parameters[5]?.sub_parameters)
  const handleSubmit = async () => {
    const parameterErrors = validateParameters(states.parameters);
    const otherErrors = {};
    if (!states.title) otherErrors.title = 'Title is required';
    if (!states.category) otherErrors.category = 'Category is required';
    if (!states.description) otherErrors.description = 'Description is required';
    if (!states.form_heading) otherErrors.form_heading = 'Form heading is required';
    setErrors(prev => ({ ...prev, ...otherErrors }));
    if (parameterErrors.length > 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        parameters: parameterErrors
      }));
      console.log(parameterErrors)
      toast("All form fields are required in parameters", { type: 'error' });
      return;
    }

    let formData = convertToFormData();
    if (row) {
      let result = await props.update_service(`api/update_service?id=${props.user_data.id}`, formData, props.token);
      if (result.success) {
        navigation('/ViewServices')
      }
    } else {
      let result = await props.add_service(`api/add_service?id=${props.user_data.id}`, formData, props.token);
      if (result.success) {
        navigation('/ViewServices')
      }
    }
  };

  const convertToFormData = () => {
    const formData = new FormData();
    if (row) {
      formData.append('service_id', row.service_id);
    }
    formData.append('title', states.title);
    formData.append('category', states.category);
    formData.append('description', states.description);
    formData.append('icon', states.icon);
    formData.append('created_by', states.created_by);
    formData.append('is_active', states.is_active);
    formData.append('form_heading', states.form_heading);

    const formattedParameters = formatParameters(states.parameters);
    formData.append('parameters', JSON.stringify(formattedParameters));
    formData.append('parameters_to_delete', JSON.stringify(parameters_to_delete));


    return formData;
  };

  const formatParameters = (parameters, parent_id = null) => {
    return parameters.map(param => {
      const formattedParam = {
        parameter_id: row ? (existingParametersId.includes(param.parameter_id)
          ? param.parameter_id : null)
          : null,
        parent_id: parent_id ? (existingParametersId.includes(parent_id) ? parent_id : null) : null,
        name: param.name,
        label: param.label,
        type: param.type,
        required: param.required,
        display_order: param.display_order
      };
      // console.log(param.label,param.required , typeof param.required)
      if (checkInputType(param.type) === 'checkbox' || checkInputType(param.type) === 'radio' || checkInputType(param.type) === 'dropdown') {
        formattedParam.options = { key: param.key || '' };
      } else if (checkInputType(param.type) === 'file') {
        formattedParam.options = { file: param.fileFormats }; // Initialize file array
      } else if (checkInputType(param.type) === 'image') {
        console.log(checkInputType(param.type))
        formattedParam.options = { image: param.imageFormats }; // Initialize image array
      }

      if (param.sub_parameters && param.sub_parameters.length > 0) {
        formattedParam.sub_parameters = formatParameters(param.sub_parameters, param.parameter_id);
      }

      return formattedParam;
    });
  };

  const checkInputType = (value) => {
    let input = InputTypes.find(v => v.dropdown_id == value)
    return input?.value || ""
  }
  const renderParameters = (parameters, parentIndices = []) => {
    return parameters.map((param, index) => {
      const currentIndices = [...parentIndices, index];
      return (
        <Box key={param.parameter_id} sx={{ ml: parentIndices.length * 2, mt: 1, mb: 1 }}>
          <Grid container spacing={2} alignItems="center" sx={{ display: 'flex', flexWrap: 'nowrap' }}>
            <Grid item sx={{ flexShrink: 0 }}>
              <TextField
                fullWidth
                size="small"
                label="Parameter Name"
                variant="outlined"
                value={param.name || ''}
                onChange={(e) => handleParameterChange(currentIndices, 'name', e.target.value)}
                sx={{ minWidth: 150, marginRight: 1 }}
              />
            </Grid>

            <Grid item sx={{ flexShrink: 0 }}>
              <TextField
                fullWidth
                size="small"
                label="Parameter Label"
                variant="outlined"
                value={param.label || ''}
                onChange={(e) => handleParameterChange(currentIndices, 'label', e.target.value)}
                sx={{ minWidth: 150, marginRight: 1 }}
              />
            </Grid>

            <Grid item sx={{ flexShrink: 0 }}>
              <FormControl fullWidth size="small" sx={{ minWidth: 150, marginRight: 1 }}>
                <InputLabel id="demo-simple-select-label">Type</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={param.type || ''}
                  onChange={(e) => handleParameterChange(currentIndices, 'type', e.target.value)}
                  label="Type"
                >
                  {InputTypes.map((v) => (
                    <MenuItem key={v.dropdown_id} value={v.dropdown_id}>
                      {v.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {(checkInputType(param.type) === 'radio' || (checkInputType(param.type) === 'checkbox') || (checkInputType(param.type) === 'dropdown')) && (
              <Grid item sx={{ flexShrink: 0 }}>
                <FormControl fullWidth size="small" sx={{ minWidth: 150, marginRight: 1 }}>
                  <InputLabel id="demo-simple-select-label">Select Key</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={param.key || ""}
                    onChange={(e) => handleParameterChange(currentIndices, 'key', e.target.value)}
                    label="Select Key"
                  >
                    {props.dropdown_types.map((v) => (
                      <MenuItem key={v.dropdown_type_id} value={v.dropdown_type_id}>
                        {v.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            {(checkInputType(param.type) === 'file') && (
              <Grid item sx={{ flexShrink: 0 }}>
                <FormControl fullWidth size="small" sx={{ minWidth: 150, marginRight: 1 }}>
                  <InputLabel id="demo-multiple-checkbox-label">File Formate</InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={param.fileFormats || []}
                    onChange={(e) => handleParameterChange(currentIndices, 'fileFormats', e.target.value)}
                    label="File Formate"
                    renderValue={(selected) => selected.join(', ')}
                  >
                    {fileFormats.map((name) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox checked={param.fileFormats.indexOf(name) > -1} />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            {(checkInputType(param.type) === 'image') && (
              <Grid item sx={{ flexShrink: 0 }}>
                <FormControl fullWidth size="small" sx={{ minWidth: 150, marginRight: 1 }}>
                  <InputLabel id="demo-multiple-checkbox-label">Image Formate</InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={param.imageFormats || []}
                    onChange={(e) => handleParameterChange(currentIndices, 'imageFormats', e.target.value)}
                    label="Image Formate"
                    renderValue={(selected) => selected.join(', ')}
                  >
                    {imageFormats.map((name) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox checked={param.imageFormats.indexOf(name) > -1} />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}

            <Grid item sx={{ flexShrink: 0 }}>
              <FormControlLabel
                control={<Checkbox checked={param.required} onChange={(e) => handleParameterChange(currentIndices, 'required', e.target.checked)} />}
                label="Required"
              />
            </Grid>

            <Grid item sx={{ flexShrink: 0 }}>
              <Button variant="contained" onClick={() => moveParameter(currentIndices, -1)}>
                Up
              </Button>
            </Grid>
            <Grid item sx={{ flexShrink: 0 }}>
              <Button variant="contained" onClick={() => moveParameter(currentIndices, 1)}>
                Down
              </Button>
            </Grid>
            <Grid item sx={{ flexShrink: 0 }}>
              <Button variant="contained" color="primary" onClick={() => addSubParameter(currentIndices)}>
                <ArrowDownwardIcon />
              </Button>
            </Grid>
            <Grid item sx={{ flexShrink: 0 }}>
              <Button variant="contained" color="secondary" onClick={() => removeParameter(currentIndices, param)}>
                <RemoveIcon />
              </Button>
            </Grid>
          </Grid>

          {/* Render sub-parameters if present */}
          {param.sub_parameters && param.sub_parameters.length > 0 && (
            <div>
              {renderParameters(param.sub_parameters, currentIndices)}
            </div>
          )}
        </Box>
      );
    });
  };


  return (
    <Container sx={{ mt: 5 }} maxWidth={'100%'}>
      <Typography variant="h5" style={{ color: headingColor, fontWeight: 'bold' }}>Service Registration</Typography>
      <Box sx={{ width: 150, height: 150 }}>
        <input
          accept="image/*"
          id="icon-file"
          type="file"
          onChange={handleImageChange}
          style={{ display: 'none' }}
        />
        <label htmlFor="icon-file">
          <Paper sx={{ width: 150, height: 150, border: 1, mt: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {states.iconURL ? (
              <img src={states.iconURL} alt="icon" style={{ width: 150, height: 150 }} />
            ) : (
              <ImageIcon style={{ fontSize: 150, color: '#ccc' }} />
            )}
          </Paper>
        </label>
      </Box>

      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            fullWidth
            size="small"
            label="Service Name"
            variant="outlined"
            name="title"
            value={states.title}
            onChange={handleInputChange}
            error={!!errors.title}
            helperText={errors.title}
            sx={{}}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            fullWidth
            size="small"
            label="Form Heading"
            variant="outlined"
            name="form_heading"
            value={states.form_heading}
            onChange={handleInputChange}
            error={!!errors.form_heading}
            helperText={errors.form_heading}
            sx={{}}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth size="small" sx={{}}>
            <InputLabel id="demo-simple-select-label">Select Category</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={states.category}
              name="category"
              label="Select Category"
              onChange={handleInputChange}
              error={!!errors.category}
            >
              {props.all_dropdowns?.trustRegistrationType ? props.all_dropdowns?.trustRegistrationType.map((category) => (
                <MenuItem key={category.dropdown_id} value={category.dropdown_id}>
                  {category.title}
                </MenuItem>
              )) : null}
            </Select>
            {errors.category && <Typography color="error">{errors.category}</Typography>}
          </FormControl>
        </Grid>


        <Grid item xs={12} sm={12} md={12}>
          <TextField
            fullWidth
            size="small"
            label="Description"
            variant="outlined"
            name="description"
            value={states.description}
            onChange={handleInputChange}
            error={!!errors.description}
            helperText={errors.description}
            multiline
            rows={4}
            sx={{}}
          />
        </Grid>
      </Grid>
      <Typography sx={{ pt: 1, pb: 1 }} variant="h6" style={{ color: headingColor, fontWeight: 'bold' }}>Service Parameters</Typography>

      <Grid sx={{ overflowX: 'scroll' }}>
        {renderParameters(states.parameters)}
      </Grid>

      <Grid sx={{ mt: 2, mb: 2 }}>
        <Button variant="contained" color="primary" onClick={addParameter}>
          <AddIcon /> Add Parameter
        </Button>

        <Button variant="contained" color="secondary" sx={{ ml: 2 }} onClick={handleSubmit}>
          {row ? "Update" : "Save"}
        </Button>
      </Grid>

      {/* <Toast_Container /> */}
    </Container>
  );
}

const mapStateToPros = (state) => ({
  user_data: state.AuthReducer.user_data,
  token: state.AuthReducer.token,
  trust_category: state.rootReducer.trust_category,
  dropdown_types: state.rootReducer.dropdown_types,
  all_dropdowns: state.rootReducer.all_dropdowns
});

const mapDispatchToProps = {
  get_dropdowns_by_key: get_dropdowns_by_key,
  add_service: add_service,
  update_service: update_service
};

export default connect(mapStateToPros, mapDispatchToProps)(AddUpdateService);
