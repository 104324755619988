import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import LoadingSpinner from '../loader/LoadingSpinner';
import {
    AppBar,
    Box,
    Container,
    Dialog,
    Divider,
    Grid,
    IconButton,
    Slide,
    Toolbar,
    Typography,
    FormControl,
    FormGroup,
    FormControlLabel,
    Checkbox,
    TextField,
    Select,
    MenuItem,
    useMediaQuery,
    useTheme,
    Button
} from '@mui/material';
import { Cancel, Delete } from '@mui/icons-material';
import { get_dropdowns_by_key, get_instance_Detail, update_instance_detail } from '../../redux.js/actions';
import { headingColor } from '../../util/colors';
import { v4 as uuidv4 } from 'uuid';
import Multi_button from '../Common/Multi_button';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Editinstance = ({
    isOpen,
    user_data,
    token,
    instance_id,
    get_instance_Detail,
    onClose,
    all_dropdowns,
    dropdown_types,
    onSuccess,
    update_instance_detail,
}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [detail, setDetail] = useState({});
    const [formData, setFormData] = useState({});
    const [initialFormData, setInitialFormData] = useState({});
    const [dropdownOptions, setDropdownOptions] = useState({});
    const [validationErrors, setValidationErrors] = useState({});
    const [multiCount, setMultiCount] = useState({});
    const [deletedMultiPdIds, setDeletedMultiPdIds] = useState([]);
    let InputTypes = all_dropdowns.inputTypes || []
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        ResetFields()
        if (instance_id) {
            fetchInstanceDetail(instance_id);
        }
    }, [instance_id]);

    const fetchInstanceDetail = async (instance_id) => {
        setIsLoading(true);
        try {
            const result = await get_instance_Detail(
                `api/get_instant_detail?id=${user_data.id}&instance_id=${instance_id}`,
                token
            );
            if (result.success) {
                const dataWithIds = addUniqueIdsToParameters(result.data);
                setDetail(dataWithIds);
                const initialData = dataWithIds.parameters.reduce((acc, param) => {
                    acc[param.unique_id] = { pdId: param.pdId, parameter_value: param.parameter_value || '', parameter_id: param.parameter_id, };
                    if (param.sub_parameters) {
                        param.sub_parameters.forEach(subGroup => {
                            subGroup.forEach(subParam => {
                                acc[subParam.unique_id] = { pdId: subParam.pdId, parameter_value: subParam.parameter_value || '', parameter_id: param.parameter_id };
                            });
                        });
                    }
                    return acc;
                }, {});
                setFormData(initialData);
                setInitialFormData(initialData);
                let keys = extractDropdownKeys(dataWithIds);
                fetchDropdownOptions(keys);
                const initialMultiCount = dataWithIds.parameters.reduce((acc, param) => {
                    if (checkInputType(param.type) === 'multi') {
                        acc[param.unique_id] = param.sub_parameters.length;
                    }
                    return acc;
                }, {});
                setMultiCount(initialMultiCount);
            }
        } catch (error) {
            console.error('Error fetching instance detail:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const addUniqueIdsToParameters = (data) => {
        const updatedData = { ...data };
        let groupCounter = 0;
        updatedData.parameters = updatedData.parameters.map(param => {
            const updatedParam = {
                ...param,
                unique_id: uuidv4()
            };
            if (updatedParam.sub_parameters) {
                updatedParam.sub_parameters = updatedParam.sub_parameters.map(subGroup => {
                    groupCounter++
                    return subGroup.map(subParam => ({
                        ...subParam,
                        unique_id: uuidv4(),
                        group_by: groupCounter
                    }));
                });
            }
            return updatedParam;
        });
        return updatedData;
    };

    const extractDropdownKeys = (data) => {
        const keys = new Set();
        const processParams = (params) => {
            if (!Array.isArray(params)) {
                return;
            }
            params.forEach(param => {
                if (param.options) {
                    let options = JSON.parse(param.options);
                    if (checkInputType(param.type) === 'dropdown' && options) {
                        keys.add({ key: options.key, parameter_id: param.parameter_id });
                    }
                } else {
                    if (checkInputType(param.type) === 'multi') {
                        let nestedParameters = param.sub_parameters
                        nestedParameters.map((v, i) => {
                            processParams(v)
                        })
                    }
                }
            });
        };

        processParams(data.parameters);
        return [...keys];
    };

    const fetchDropdownOptions = async (dropdownKeys) => {
        const fetchedOptions = {};
        for (let dropdown of dropdownKeys) {
            let dropdownKey = checkDropdownKey(dropdown.key);
            fetchedOptions[dropdown.key] = all_dropdowns[dropdownKey] || [];
        }
        setDropdownOptions(fetchedOptions);
    };

    const handleInputChange = (e, unique_id, pdId, parameter_id) => {
        const { value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [unique_id]: {
                pdId: pdId,
                parameter_id: parameter_id,
                parameter_value: value,
            },
        }));
        setValidationErrors(prevState => ({
            ...prevState,
            [unique_id]: '',
        }));
    };

    const handleFileChange = (e, unique_id, pdId, parameter_id) => {
        const file = e.target.files[0];
        setFormData(prevState => ({
            ...prevState,
            [unique_id]: {
                pdId: pdId,
                parameter_id: parameter_id,
                parameter_value: file,
            },
        }));
        setValidationErrors(prevState => ({
            ...prevState,
            [unique_id]: '',
        }));
    };

    const validateForm = () => {
        const errors = {};
        detail.parameters.forEach(param => {
            if (param.required && formData[param.unique_id]?.parameter_value == '') {
                errors[param.unique_id] = `${param.parameter_name} is required`;
            }
            if (param.sub_parameters) {
                param.sub_parameters.forEach(subGroup => {
                    subGroup.forEach(subParam => {
                        if (subParam.required && formData[subParam.unique_id]?.parameter_value == '') {
                            errors[subParam.unique_id] = `${subParam.parameter_name} is required`;
                        }
                    });
                });
            }
        });

        setValidationErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleMultiCountChange = (uniqueId, newCount) => {
        setDetail(prevDetail => {
            const updatedParams = prevDetail.parameters.map(param => {
                if (param.unique_id !== uniqueId) return param;

                let newSubParameters = [...param.sub_parameters];
                const currentCount = newSubParameters.length;

                if (newCount > currentCount) {
                    // Add new sub-parameters with unique values
                    for (let i = currentCount; i < newCount; i++) {
                        let newSubParamGroup = newSubParameters[0].map(subParam => ({
                            ...subParam,
                            unique_id: uuidv4(),
                            isNew: true,
                            group_by: newCount,
                            parameter_value: ''
                        }));

                        // Update formData and initialFormData with new entries
                        let initialData = {};
                        newSubParamGroup.forEach(subParam => {
                            initialData[subParam.unique_id] = {
                                pdId: subParam.pdId,
                                parameter_value: subParam.parameter_value || '',
                                parameter_id: subParam.parameter_id
                            };
                        });
                        setFormData(prev => ({ ...prev, ...initialData }));
                        setInitialFormData(prev => ({ ...prev, ...initialData }));

                        newSubParameters.push(newSubParamGroup);
                    }
                } else if (newCount < currentCount) {
                    // Remove extra sub-parameters
                    const removedSubParams = newSubParameters.slice(newCount, currentCount);
                    const removedPdIds = removedSubParams.flat().filter(subParam => !subParam.isNew).map(subParam => subParam.pdId);
                    setDeletedMultiPdIds(prevState => [...prevState, ...removedPdIds]);

                    // Remove entries from formData and initialFormData
                    removedSubParams.forEach(subParamGroup => {
                        subParamGroup.forEach(subParam => {
                            delete formData[subParam.unique_id];
                            delete initialFormData[subParam.unique_id];
                        });
                    });

                    newSubParameters = newSubParameters.slice(0, newCount);
                }

                return {
                    ...param,
                    sub_parameters: newSubParameters,
                };
            });

            return {
                ...prevDetail,
                parameters: updatedParams,
            };
        });

        // Update multi count
        setMultiCount(prevState => ({
            ...prevState,
            [uniqueId]: newCount,
        }));
    };

    const handleSubmit = async () => {
        if (!validateForm()) {
            return;
        }
        let result = updateGroupByValues()
        const parametersToSubmit = [];
        for (const unique_id in formData) {
            const { pdId, parameter_value, parameter_id } = formData[unique_id];
            const initialValue = initialFormData[unique_id]?.parameter_value;
            let parameterDetails = findParameterDetails(unique_id);

            if (parameter_value !== initialValue) {
                parametersToSubmit.push({
                    unique_id,
                    pdId,
                    parameter_id,
                    group_by: parameterDetails.group_by,
                    value: parameter_value instanceof File ? null : parameter_value,
                    isNew: checkIsNew(unique_id) || false,
                    isFile: parameter_value instanceof File,
                });
            }
        }

        if (parametersToSubmit.length > 0 || deletedMultiPdIds.length > 0) {
            const formDataToSubmit = new FormData();
            formDataToSubmit.append('parameters', JSON.stringify(parametersToSubmit));
            formDataToSubmit.append('instance_id', instance_id)
            formDataToSubmit.append('deleted_pdIds', JSON.stringify(deletedMultiPdIds))

            // Append files to FormData
            for (const unique_id in formData) {
                const { parameter_value } = formData[unique_id];
                if (parameter_value instanceof File) {
                    formDataToSubmit.append(unique_id, parameter_value);
                }
            }

            setIsLoading(true);
            let onUpdate = await update_instance_detail(`api/update_instant_detail?id=${user_data.id}`, token, formDataToSubmit);
            setIsLoading(false);
            if (onUpdate.success) {
                ResetFields()
                onSuccess()
            } else {

            }
        } else {
            ResetFields()
            onClose()
        }
    };
    const ResetFields = () => {
        setDetail({})
        setDropdownOptions({})
        setFormData({})
        setInitialFormData({})
        setValidationErrors({})
        setDeletedMultiPdIds([])
    }
    const findParameterDetails = (unique_id) => {
        // Assuming detail is an array of parameters or an object containing parameters
        let parameterDetails = null;

        // Search for parameter by unique_id in detail state
        if (detail.parameters) {
            parameterDetails = detail.parameters.find(param => param.unique_id === unique_id);
        }

        // If parameter is not found, check sub_parameters
        if (!parameterDetails && detail.parameters) {
            detail.parameters.forEach(param => {
                if (param.sub_parameters) {
                    param.sub_parameters.map((p, i) => {
                        const subParam = p.find(sub => sub.unique_id === unique_id);
                        if (subParam) {
                            parameterDetails = subParam;
                            return; // Exit loop if found
                        }
                    })
                }
            });
        }

        return parameterDetails;
    };
    const checkIsNew = (unique_id) => {
        for (const param of detail.parameters) {
            if (param.unique_id === unique_id) {
                return param?.isNew;
            }
            if (param.sub_parameters) {
                for (const subGroup of param.sub_parameters) {
                    for (const subParam of subGroup) {
                        if (subParam.unique_id === unique_id) {
                            return subParam?.isNew;
                        }
                    }
                }
            }
        }
        return false; // Default to false if not found
    };
    const handleRemoveSingleMulti = (unique_id, index) => {
        setDetail(prevDetail => {
            const updatedParams = prevDetail.parameters.map(param => {
                if (param.unique_id !== unique_id) return param;

                let newSubParameters = [...param.sub_parameters];

                if (newSubParameters.length > 1) {
                    const removedSubParams = newSubParameters.splice(index, 1);

                    const removedPdIds = removedSubParams.flat().filter(subParam => !subParam.isNew).map(subParam => subParam.pdId);

                    removedSubParams.forEach(subParamGroup => {
                        subParamGroup.forEach(subParam => {
                            delete formData[subParam.unique_id];
                            delete initialFormData[subParam.unique_id];
                        });
                    });

                    setDeletedMultiPdIds(prevState => [...prevState, ...removedPdIds]);
                }

                return {
                    ...param,
                    sub_parameters: newSubParameters,
                };
            });

            return {
                ...prevDetail,
                parameters: updatedParams,
            };
        });
        setMultiCount(prevState => {
            if (prevState[unique_id] > 1) {
                return {
                    ...prevState,
                    [unique_id]: prevState[unique_id] - 1,
                };
            }
            return prevState;
        });
    };

    const updateGroupByValues = () => {
        let groupCounter = 1;
        let data = detail.parameters
        data.forEach(param => {
            if (param.sub_parameters && param.sub_parameters.length > 0) {
                param.sub_parameters.forEach((subParamGroup) => {
                    subParamGroup.forEach(subParam => {
                        subParam.group_by = groupCounter;
                    });
                    groupCounter++;
                });
            }
        });

        return data;
    };

    const renderParameter = (parameter) => {
        const { parameter_id, parameter_name, parameter_value, sub_parameters, type, options, pdId, required, unique_id } = parameter;
        let key = null;
        if (options) {
            key = JSON.parse(options);
        }
        switch (checkInputType(type)) {
            case 'text':
                return (
                    <Grid item xs={12} sm={6} md={4} key={unique_id} sx={{ pl: 1, pr: 1 }}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label={parameter_name}
                            value={formData[unique_id]?.parameter_value || ''}
                            onChange={(e) => handleInputChange(e, unique_id, pdId, parameter_id)}
                            size='small'
                            margin="normal"
                            error={!!validationErrors[unique_id]}
                            helperText={validationErrors[unique_id]}
                            required={!!required}
                        />
                    </Grid>
                );
            case 'tel':
                return (
                    <Grid item xs={12} sm={6} md={4} key={unique_id} sx={{ pl: 1, pr: 1 }}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label={parameter_name}
                            value={formData[unique_id]?.parameter_value || ''}
                            onChange={(e) => handleInputChange(e, unique_id, pdId, parameter_id)}
                            type='number'
                            margin="normal"
                            size='small'
                            error={!!validationErrors[unique_id]}
                            helperText={validationErrors[unique_id]}
                            required={!!required}
                        />
                    </Grid>
                );
            case 'dropdown':
                return (
                    <Grid item xs={12} sm={6} md={4} key={unique_id} sx={{ pl: 1, pr: 1 }}>
                        <FormControl fullWidth variant="outlined" margin="normal" size='small' error={!!validationErrors[unique_id]} required={!!required}>
                            <Select
                                fullWidth
                                value={formData[unique_id]?.parameter_value || ''}
                                onChange={(e) => handleInputChange(e, unique_id, pdId, parameter_id)}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Select' }}
                            >
                                {dropdownOptions[key.key] && dropdownOptions[key.key].map((dropdown) => (
                                    <MenuItem key={dropdown.dropdown_id} value={dropdown.dropdown_id}>{dropdown.title}</MenuItem>
                                ))}
                            </Select>
                            {validationErrors[unique_id] && <Typography color="error">{validationErrors[unique_id]}</Typography>}
                        </FormControl>
                    </Grid>
                );
            case 'checkbox':
                return (
                    <Grid item xs={12} sm={6} md={4} key={unique_id} sx={{ pl: 1, pr: 1 }}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formData[unique_id]?.parameter_value || false}
                                        onChange={(e) => handleInputChange(e, unique_id, pdId, parameter_id)}
                                        required={!!required}
                                    />
                                }
                                label={parameter_name}
                            />
                            {validationErrors[unique_id] && <Typography color="error">{validationErrors[unique_id]}</Typography>}
                        </FormGroup>
                    </Grid>
                );
            case 'file':
                return (
                    <Grid item xs={12} sm={6} md={4} key={unique_id} sx={{ pl: 1, pr: 1 }}>
                        <FormControl fullWidth margin="normal" size='small' required={!!required} error={!!validationErrors[unique_id]}>
                            <Button
                                variant="outlined"
                                component="label"
                                fullWidth
                            >
                                {formData[unique_id]?.parameter_value?.name || `Attach ${parameter_name}`}
                                <input
                                    type="file"
                                    hidden
                                    onChange={(e) => handleFileChange(e, unique_id, pdId, parameter_id)}
                                />
                            </Button>
                            {validationErrors[unique_id] && <Typography color="error">{validationErrors[unique_id]}</Typography>}
                        </FormControl>
                    </Grid>
                );
            case 'multi':
                return (
                    <Container maxWidth="lg" key={unique_id}>
                        <Grid>
                            <Grid item xs={12} key={unique_id} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Typography variant="subtitle1">{parameter_name}</Typography>
                                <Multi_button
                                    totalCount={multiCount[parameter.unique_id] || 1}
                                    updatedCount={(count) => handleMultiCountChange(parameter.unique_id, count)}
                                />
                            </Grid>
                            {sub_parameters && sub_parameters.map((subParam, index) => (
                                <Grid container key={index} sx={{ p: 2, mb: 2, mt: 2, border: 1, borderColor: '#ccc', borderRadius: 2, position: 'relative' }}>
                                    <IconButton
                                        sx={{ position: 'absolute', top: '-10px', right: '-10px', bgcolor: 'red' }}
                                        onClick={() => handleRemoveSingleMulti(unique_id, index)}
                                    >
                                        <Delete sx={{ width: 15, height: 15 }} />
                                    </IconButton>
                                    {subParam.map((p, i) => (
                                        <React.Fragment key={i}>
                                            {renderParameter(p)}
                                        </React.Fragment>
                                    ))}
                                </Grid>
                            ))}
                        </Grid>
                    </Container>
                );
            default:
                return null;
        }
    };

    const checkInputType = (value) => {
        const input = InputTypes.find(v => v.dropdown_id == value);
        return input?.value || "";
    };

    const checkDropdownKey = (value) => {
        const input = dropdown_types.find(v => v.dropdown_type_id == value);
        return input?.type || "";
    };

    return (
        <>
            <Dialog fullScreen open={isOpen} onClose={onClose} TransitionComponent={Transition}>
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                            <Cancel />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Instance Detail
                        </Typography>
                        <Button color="inherit" onClick={handleSubmit}>Save</Button>
                    </Toolbar>
                </AppBar>
                <Container maxWidth="lg">
                    {isLoading && <LoadingSpinner />}
                    <Box sx={{ pt: 5 }}>
                        <Grid container>
                            {Object.keys(detail).length > 0 &&
                                detail.parameters.map((parameter, index) => (
                                    <React.Fragment key={index}>
                                        {renderParameter(parameter)}
                                    </React.Fragment>
                                ))}
                        </Grid>
                    </Box>
                </Container>
            </Dialog>
        </>
    );
};

const mapStateToProps = (state) => ({
    user_data: state.AuthReducer.user_data,
    token: state.AuthReducer.token,
    dropdown_types: state.rootReducer.dropdown_types,
    all_dropdowns: state.rootReducer.all_dropdowns
});

const mapDispatchToProps = {
    get_instance_Detail: get_instance_Detail,
    get_dropdowns_by_key: get_dropdowns_by_key,
    update_instance_detail: update_instance_detail
};

export default connect(mapStateToProps, mapDispatchToProps)(Editinstance);
