import axios from 'axios';
import { api_base_url } from '../util/Constant';

const Put = async ({ endPoint, token, data, timeout = 30000, content_Type = "application/json" }) => {
    try {
        const response = await axios({
            method: 'put',
            url: api_base_url + endPoint,
            headers: token ? {
                'Authorization': token ? `bearer ${token}` : '',
                'Content-Type': content_Type
            } :
                {
                    'Content-Type': content_Type
                },
            data: data
        });
        return response.data;
    } catch (error) {
        return error.response;
    }
};

export default Put;
