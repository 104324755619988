import React from 'react';
import { Box, Grid, Typography, Paper } from '@mui/material';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

const DashboardCard = ({ title, icon }) => {
  return (
    <Grid container alignItems="center" spacing={2} sx={{justifyContent:'space-between'}}>
      <Grid item>
        <Typography variant="body1" className='dashboard_card_title' sx={{fontWeight:'bold'}}>{title}</Typography>
      </Grid>
      <Grid item>
        <Box display="flex" alignItems="center">
          {icon}
        </Box>
      </Grid>
    </Grid>
  );
};

export default DashboardCard;
