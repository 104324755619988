import React, { useState } from 'react';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Divider,
  TextField,
  Button,
  Paper,
  Tabs,
  Tab,
  Grid,
  Container
} from '@mui/material';
import ReplyIcon from '@mui/icons-material/Reply';
import SendIcon from '@mui/icons-material/Send';
import EmailIcon from '@mui/icons-material/Email';
import { headingColor } from '../../util/colors';

function MessageCenter() {

  const dummyMessages = [
    {
      id: 1,
      email: 'user1@example.com',
      subject: 'Inquiry about services',
      message: 'Hello, I would like to know more about your services.'
    },
    {
      id: 2,
      email: 'user2@example.com',
      subject: 'Support needed',
      message: 'I am facing an issue with my account, can you help?'
    },
    
  ];

  const [messages] = useState(dummyMessages); // Using dummy data
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [reply, setReply] = useState('');
  const [compose, setCompose] = useState(false);
  const [newEmail, setNewEmail] = useState('');
  const [newSubject, setNewSubject] = useState('');
  const [newMessage, setNewMessage] = useState('');

  const handleReply = (message) => {
    setSelectedMessage(message);
    setReply(''); // Reset the reply field when a new message is selected
    setCompose(false); // Switch back to reply mode
  };

  const handleSendReply = () => {
    console.log('Sending reply:', reply);
    setReply('');
    setSelectedMessage(null);
  };

  const handleCompose = () => {
    setCompose(true);
    setSelectedMessage(null); // Deselect any selected message
  };

  const handleSendNewEmail = () => {
    console.log('Sending new email:', { to: newEmail, subject: newSubject, message: newMessage });
    setNewEmail('');
    setNewSubject('');
    setNewMessage('');
    setCompose(false);
  };

  return (
    <Container sx={{ mt: 5 }}>
      <Grid container columns={12} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant="h4" style={{ color: headingColor, fontWeight: 'bold' }}>Services</Typography>
      </Grid>
      <Divider sx={{ mt: 2 }} />
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Tabs
          value={compose ? 1 : 0}
          onChange={(e, newValue) => setCompose(newValue === 1)}
          sx={{ marginBottom: 2 }}
        >
          <Tab label="Inbox" />
          <Tab label="Compose New Email" />
        </Tabs>

        <Box sx={{ display: 'flex', gap: 2 }}>
          {/* Email List */}
          {!compose && (
            <Paper sx={{ width: '40%', padding: 2, overflowY: 'auto' }}>
              <Typography variant="h6" gutterBottom>
                Incoming Messages
              </Typography>
              <List>
                {messages.map((message, index) => (
                  <React.Fragment key={message.id}>
                    <ListItem
                      button
                      onClick={() => handleReply(message)}
                      selected={selectedMessage && selectedMessage.id === message.id}
                      sx={{
                        backgroundColor: selectedMessage && selectedMessage.id === message.id ? 'rgba(0, 0, 0, 0.08)' : 'transparent',
                        borderRadius: 1,
                        marginBottom: 1,
                      }}
                    >
                      <EmailIcon color="primary" sx={{ marginRight: 2 }} />
                      <ListItemText
                        primary={message.subject}
                        secondary={`From: ${message.email}`}
                      />
                      <ListItemSecondaryAction>
                        <IconButton edge="end" onClick={() => handleReply(message)}>
                          <ReplyIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                    {index < messages.length - 1 && <Divider />}
                  </React.Fragment>
                ))}
              </List>
            </Paper>
          )}

          {/* Reply or Compose Section */}
          <Paper sx={{ width: '60%', padding: 3, flexDirection: 'column' }}>
            {compose ? (
              <>
                <Typography variant="h6" gutterBottom>
                  Compose New Email
                </Typography>
                <TextField
                  label="To"
                  fullWidth
                  variant="outlined"
                  value={newEmail}
                  onChange={(e) => setNewEmail(e.target.value)}
                  sx={{ marginBottom: 2 }}
                />
                <TextField
                  label="Subject"
                  fullWidth
                  variant="outlined"
                  value={newSubject}
                  onChange={(e) => setNewSubject(e.target.value)}
                  sx={{ marginBottom: 2 }}
                />
                <TextField
                  label="Message"
                  multiline
                  rows={6}
                  fullWidth
                  variant="outlined"
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  sx={{ marginBottom: 2 }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  endIcon={<SendIcon />}
                  onClick={handleSendNewEmail}
                  sx={{ alignSelf: 'flex-end' }}
                >
                  Send
                </Button>
              </>
            ) : selectedMessage ? (
              <>
                <Typography variant="h6" gutterBottom>
                  Reply to: {selectedMessage.email}
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Subject: {selectedMessage.subject}
                </Typography>
                <Typography variant="body2" sx={{ marginBottom: 2, whiteSpace: 'pre-wrap' }}>
                  {selectedMessage.message}
                </Typography>
                <TextField
                  label="Write your reply"
                  multiline
                  rows={6}
                  fullWidth
                  variant="outlined"
                  value={reply}
                  onChange={(e) => setReply(e.target.value)}
                  sx={{ flexGrow: 1, marginBottom: 2 }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  endIcon={<SendIcon />}
                  onClick={handleSendReply}
                  sx={{ alignSelf: 'flex-end' }}
                >
                  Send Reply
                </Button>
              </>
            ) : (
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                <Typography variant="body1">
                  Select a message to view and reply or compose a new email.
                </Typography>
              </Box>
            )}
          </Paper>
        </Box>
      </Box>
    </Container>
  );
}

export default MessageCenter;
