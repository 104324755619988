export const IS_LOGIN = "IS_LOGIN";
export const USER_DATA = "USER_DATA";
export const TOKEN = "TOKEN";
export const CURRENT_VERSION = "CURRENT_VERSION";
export const GLOBAL_LOADER = "GLOBAL_LOADER";



export const ADD_SERVICE = 'ADD_SERVICE';
export const ALL_SERVICE_DATA = 'ALL_SERVICE_DATA';
export const SERVICE_CATEGORY = 'SERVICE_CATEGORY';
export const ADD_NEW_SERVICE_CATEGORY = 'ADD_NEW_SERVICE_CATEGORY';
export const REGISTRATIONS = 'REGISTRATIONS';
export const UPDATE_INSTANCE_STATUS = 'UPDATE_INSTANCE_STATUS';
export const DASHBOARD_COUNT = 'DASHBOARD_COUNT';
export const CHANGE_SERVICE_STATUS = 'CHANGE_SERVICE_STATUS';
export const USER_ROLE = 'USER_ROLE';
export const DROPDOWN_TYPES = 'DROPDOWN_TYPES';
export const ALL_DROPDOWNS = 'ALL_DROPDOWNS';
export const TRUST_TYPES = 'TRUST_TYPES';
export const TRUST_CATEGORY = 'TRUST_CATEGORY';
export const INPUT_TYPES = 'INPUT_TYPES';
export const ACTIVE_PORTAL_USER = 'ACTIVE_PORTAL_USER';
export const NOTIFICATIONS = 'NOTIFICATIONS';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const READ_NOTIFICATIONS = 'READ_NOTIFICATIONS';