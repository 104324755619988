import { toast } from 'react-toastify';
import Post from '../../api/Post'
import Get from '../../api/Get'
import { CURRENT_VERSION, GLOBAL_LOADER, IS_LOGIN, TOKEN, USER_DATA } from '../actionType';
import { resetCacheAndReload } from '../../util/Fun';
export const loginAction = (endPoint, data) => {
  return async (dispatch) => {
    try {
      const response = await Post({ endPoint: endPoint, data: data });
      if (response.success) {
        toast(response.message)
        dispatch({ type: IS_LOGIN, payload: true })
        dispatch({ type: USER_DATA, payload: response.data })
        dispatch({ type: TOKEN, payload: response.data.token })
        return true

      } else {
        toast(response.message, { type: 'error' })
        return false
      }
    } catch (error) {
      toast("please try again there is some issue.", { type: 'error' })
      return false
    }
  };
};
export const CreateUser = (endPoint, data, token) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GLOBAL_LOADER, payload: true })
      const response = await Post({ endPoint: endPoint, token: token, data: data, });
      dispatch({ type: GLOBAL_LOADER, payload: false })
      if (response.success) {
        toast(response.message)
        return true
      } else {
        toast(response.message, { type: 'error' })
        return false
      }
    } catch (error) {
      dispatch({ type: GLOBAL_LOADER, payload: false })
      toast("please try again there is some issue.", { type: 'error' })
      return false
    }
  };
};


export const get_current_version = (endPoint, current_version) => {
  return async (dispatch) => {
    try {
      const response = await Get({ endPoint: endPoint });
      if (response?.success) {
        if (response.data.version_number != current_version) {
          dispatch({ type: CURRENT_VERSION, payload: response.data.version_number })
          resetCacheAndReload()
        }
        return response
      } else {
        return false
      }
    } catch (error) {
      return false
    }
  };
};