import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { complete_step, get_Steps, onAllStepCompleted } from '../../redux.js/actions';
import {
    AppBar,
    Dialog,
    IconButton,
    Slide,
    Toolbar,
    Typography,
    Paper,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    Button,
    Avatar,
    Chip,
    Box,
    Divider,
} from '@mui/material';
import { Cancel, Delete, Done, Save, Update, UploadFile } from '@mui/icons-material';
import LoadingSpinner from '../loader/LoadingSpinner';
import { toast } from 'react-toastify';
import { UPDATE_INSTANCE_STATUS } from '../../redux.js/actionType';
import { hasPermission } from '../../util/Fun';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function Tracking({ service, isOpen, onClose, user_data, token, get_Steps, complete_step, onAllStepCompleted }) {
    const dispatch = useDispatch()
    const [steps, setSteps] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState({});
    const [stepDeletedFiles, setStepDeletedFiles] = useState([]);

    useEffect(() => {
        if (service && hasPermission("get_steps", user_data.permissions)) {
            GetSteps(service.service_id, service.registration_id);
        }
    }, [service, token, get_Steps]);

    const GetSteps = async (id, registration_id) => {
        setIsLoading(true);
        let result = await get_Steps(`api/get_steps?id=${user_data.id}&serviceId=${id}&instance_id=${registration_id}`, token);
        setIsLoading(false);
        if (result.success) {
            setSteps(result.data);
        }
    };

    const handleDone = async (step) => {
        if (step.file_required && !selectedFiles[step.step_id]) {
            return toast("Image or pdf file is required for this step", { type: 'error' });
        }
        const formData = new FormData();
        // Example data to send
        const requestData = {
            instance_id: service.registration_id,
            step_id: step.step_id,
            user_id: service.client_id,
        };

        // Append fields to the FormData object
        for (const key in requestData) {
            formData.append(key, requestData[key]);
        }

        // Append selected files for this step
        if (selectedFiles[step.step_id]) {
            selectedFiles[step.step_id].forEach(file => {
                formData.append('files', file);
            });
        }
        setIsLoading(true)

        let result = await complete_step(`api/complete_step?id=${user_data.id}`, token, formData);
        setIsLoading(false)
        if (result) {
            onClose()
            setSelectedFiles({})
            setSteps([])
        }

        // Handle form submission logic here
    };
    const handleUpdate = async (step) => {
        if (step.file_required && !selectedFiles[step.step_id]) {
            if (step.completions[0].files.length == 0) {
                return toast("Image or pdf file is required for this step", { type: 'error' });
            }
        }
        const formData = new FormData();
        // Example data to send
        const requestData = {
            completion_id: step.completions[0].completed_step_id,
            instance_id: service.registration_id,
            step_id: step.step_id,
            user_id: service.client_id,
            type: 'update'
        };

        // Append fields to the FormData object
        for (const key in requestData) {
            formData.append(key, requestData[key]);
        }

        // Append selected files for this step
        if (selectedFiles[step.step_id]) {
            selectedFiles[step.step_id].forEach(file => {
                formData.append('files', file);
            });
        }
        // Append deleted step files
        stepDeletedFiles.forEach((fileId, index) => {
            formData.append(`deleted_step_files[${index}]`, fileId);
        });
        setIsLoading(true)

        let result = await complete_step(`api/complete_step?id=${user_data.id}`, token, formData);
        setIsLoading(false)
        if (result) {
            onClose()
            setSelectedFiles({})
            setSteps([])
            setStepDeletedFiles([])
        }

        // Handle form submission logic here
    };

    const handleFileUpload = (event, stepId) => {
        const files = Array.from(event.target.files);
        const validFiles = files.filter(file => {
            const isValidType = ['image/jpeg', 'image/png', 'application/pdf'].includes(file.type);
            const isValidSize = file.size < 2 * 1024 * 1024;
            return isValidType && isValidSize;
        });

        if (validFiles.length !== files.length) {
            toast("Some files were rejected. Only PDF, PNG, JPEG files under 2MB are allowed.", { type: 'error' });
        }

        setSelectedFiles(prevFiles => ({
            ...prevFiles,
            [stepId]: [...(prevFiles[stepId] || []), ...validFiles],
        }));
    };

    const handleDeleteFile = (stepId, fileName) => {
        setSelectedFiles(prevFiles => ({
            ...prevFiles,
            [stepId]: prevFiles[stepId].filter(file => file.name !== fileName),
        }));
    };

    const OnDeleteStepFiles = (step_id, file_id) => {
        setSteps(prevSteps => prevSteps.map(step => {
            if (step.step_id !== step_id) {
                return step;
            }

            // Check if the step requires at least one file
            if (step.file_required) {
                const completionsWithFiles = step.completions.map(completion => {
                    const updatedFiles = completion.files.filter(file => file.file_id !== file_id);

                    // If this completion will end up with no files, prevent deletion
                    if (updatedFiles.length === 0) {
                        toast('This step requires at least one file. Cannot delete the last file.', { type: "warning" });
                        return completion; // Return the completion unchanged
                    }

                    setStepDeletedFiles(prev => [...prev, file_id]);
                    return {
                        ...completion,
                        files: updatedFiles
                    };
                });
                return {
                    ...step,
                    completions: completionsWithFiles
                };
            }

            // If step doesn't require a file, proceed with deletion
            const updatedCompletions = step.completions.map(completion => {
                return {
                    ...completion,
                    files: completion.files.filter(file => file.file_id !== file_id)
                };
            });

            return {
                ...step,
                completions: updatedCompletions
            };
        }));

    };

    const onRevert = async (step) => {
        const formData = new FormData();
        // Example data to send
        const requestData = {
            completion_id: step.completions[0].completed_step_id,
            instance_id: service.registration_id,
            step_id: step.step_id,
            user_id: service.client_id,
            type: 'revert'
        };

        // Append fields to the FormData object
        for (const key in requestData) {
            formData.append(key, requestData[key]);
        }

        setIsLoading(true)

        let result = await complete_step(`api/complete_step?id=${user_data.id}`, token, formData);
        setIsLoading(false)
        if (result) {
            onClose()
            setSelectedFiles({})
            setStepDeletedFiles([])
            setSteps([])
        }
    }
    const handleOnCompleteAllSteps = async () => {
        if (!steps.every(step => step.completions.length > 0)) {
            return toast("PLease complete all above step first.", { type: 'warning' })
        } else {
            setIsLoading(true)
            let result = await onAllStepCompleted(`api/onCompleteAllSteps?id=${user_data.id}`, token, {
                instance_id: service.registration_id,
            });
            setIsLoading(false)
            if (result) {
                onClose()
                setSelectedFiles({})
                setStepDeletedFiles([])
                setSteps([])
                dispatch({ type: UPDATE_INSTANCE_STATUS, payload: { instance_id: service.registration_id, newStatus: 'complete' } })
            }
        }
    }
    return (
        <Dialog fullScreen open={isOpen} onClose={onClose} TransitionComponent={Transition}>
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                        <Cancel />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Service Tracking
                    </Typography>
                </Toolbar>
            </AppBar>
            {isLoading && <LoadingSpinner />}
            <Paper elevation={3} sx={{ padding: 2, margin: 2 }}>
                <Typography variant="h6">Tracking for Service ID: {service.service_id}</Typography>
                {steps.length > 0 ? (
                    <List>
                        {steps.map((step) => {
                            return (
                                <ListItem key={step.step_id} sx={{ borderBottom: '1px solid #ccc' }}>
                                    <Avatar sx={{ mr: 2 }}>{step.step_id}</Avatar>
                                    <ListItemText
                                        primary={step.step_description}
                                        secondary={step.department_name}
                                    />
                                    <ListItemSecondaryAction sx={{ display: 'flex', alignItems: 'center' }}>
                                        <input
                                            type="file"
                                            accept="image/jpeg,image/png,application/pdf"
                                            style={{ display: 'none' }}
                                            id={`file-upload-${step.step_id}`}
                                            onChange={(event) => handleFileUpload(event, step.step_id)}
                                            multiple
                                        />
                                        <label htmlFor={`file-upload-${step.step_id}`}>
                                            <IconButton
                                                disabled={service.status == 'complete'}
                                                color="primary" component="span">
                                                <UploadFile />
                                            </IconButton>
                                        </label>
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, ml: 1 }}>
                                            {step.completions.map((data, index) => (
                                                data.files.map((file, i) => (
                                                    <Chip
                                                        disabled={service.status == 'complete'}
                                                        key={i}
                                                        label={file.file_name}
                                                        onDelete={() => OnDeleteStepFiles(step.step_id, file.file_id)}
                                                        sx={{ mr: 1, backgroundColor: 'greenyellow' }}
                                                    />
                                                ))

                                            ))}

                                            {selectedFiles[step.step_id] && selectedFiles[step.step_id].map((file, index) => (
                                                <Chip
                                                    disabled={service.status == 'complete'}
                                                    key={index}
                                                    label={file.name}
                                                    onDelete={() => handleDeleteFile(step.step_id, file.name)}
                                                    sx={{ mr: 1 }}
                                                />
                                            ))}
                                        </Box>
                                        {hasPermission("complete_step", user_data.permissions) &&
                                            <>
                                                {
                                                    step.completions.length > 0 &&
                                                    <Button
                                                        disabled={service.status == 'complete'}
                                                        variant="contained"
                                                        color="warning"
                                                        startIcon={<Delete />}
                                                        onClick={() => onRevert(step)}
                                                        sx={{ ml: 'auto', mr: 1 }}
                                                    >
                                                        Revert
                                                    </Button>
                                                }
                                                {step.completions.length > 0 ?
                                                    <Button
                                                        disabled={service.status == 'complete'}
                                                        variant="contained"
                                                        color="primary"
                                                        startIcon={<Update />}
                                                        onClick={() => handleUpdate(step)}
                                                        sx={{ ml: 'auto' }}
                                                    >
                                                        Update
                                                    </Button>

                                                    :
                                                    <Button
                                                        disabled={service.status == 'complete'}
                                                        variant="contained"
                                                        color="primary"
                                                        startIcon={<Done />}
                                                        onClick={() => handleDone(step)}
                                                        sx={{ ml: 'auto' }}
                                                    >
                                                        Done
                                                    </Button>
                                                }
                                            </>
                                        }
                                    </ListItemSecondaryAction>
                                </ListItem>
                            )
                        })}
                        {hasPermission("onCompleteAllSteps", user_data.permissions) &&
                            <Button
                                disabled={(!steps.every(step => step.completions.length > 0) || service.status == 'complete')}
                                variant="contained"
                                color="success"
                                startIcon={<Save />}
                                onClick={() => handleOnCompleteAllSteps()}
                                sx={{ ml: 'auto', mt: 2, float: 'right' }}
                            >
                                Completed
                            </Button>
                        }
                    </List>
                ) : (
                    <Typography>No steps found.</Typography>
                )}
            </Paper>
        </Dialog>
    );
}

const mapStateToProps = (state) => ({
    user_data: state.AuthReducer.user_data,
    token: state.AuthReducer.token,
});

const mapDispatchToProps = {
    get_Steps: get_Steps,
    complete_step: complete_step,
    onAllStepCompleted: onAllStepCompleted,
};

export default connect(mapStateToProps, mapDispatchToProps)(Tracking);
