import React, { useEffect, useState, useMemo } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Box, Button, Container, Divider, FormControl, InputLabel, MenuItem, Modal, Select, TextField, Typography, IconButton, Grid, Paper, Chip } from '@mui/material';
import { Delete, Update, Block, CheckCircle, Cancel } from '@mui/icons-material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { add_service, get_all_services, get_dropdowns_by_key, update_service, delete_service, change_service_status, get_Steps } from '../../redux.js/actions';
import LoadingSpinner from '../../components/loader/LoadingSpinner';
import { headingColor } from '../../util/colors';
import ImageIcon from '@mui/icons-material/Image';
import { TRUST_CATEGORY } from '../../redux.js/actionType';
import { useNavigate } from 'react-router-dom';
import { hasPermission } from '../../util/Fun';
import { VscTypeHierarchy } from "react-icons/vsc";
import ServiceTracking from '../../components/Services/ServiceTracking';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const ServicesDataGrid = ({ rows, columns }) => (
    <DataGrid
        rows={rows}
        columns={columns}
        autoHeight
        rowHeight={50}
        getRowId={(row) => row.service_id}
        components={{ Toolbar: GridToolbar }}
        initialState={{ pagination: { paginationModel: { pageSize: 10 } } }}
        pageSizeOptions={[10, 25, 50, 100]}
    />
);

const ViewServices = (props) => {
    const dispatch = useDispatch()
    const navigation = useNavigate()
    const [isSteps, setIsStep] = useState(null)
    const [serviceSteps, setServiceSteps] = useState([])
    const [states, setStates] = useState({
        openModal: false,
        selectedService: null,
        isLoading: false,
        title: "",
        category: '',
        description: '',
        icon: '',
        iconURL: '',
        created_by: props.user_data.id,
        is_active: false,
    });
    const [errors, setErrors] = useState({
        title: '',
        category: '',
        description: '',
        icon: ''
    });

    useEffect(() => {
        getServices();
        // getCategory();
    }, []);
    const getServices = async () => {
        setStates(prev => ({ ...prev, isLoading: true }));
        let result = await props.get_all_services(`api/get_All_Services?id=${props.user_data.id}`, props.token);
        setStates(prev => ({ ...prev, isLoading: false }));
    };

    // const getCategory = async () => {
    //     setStates(prev => ({ ...prev, isLoading: true }));
    //     let result = await props.get_dropdowns_by_key(`api/get_dropdowns_by_key?id=${props.user_data.id}&key=services category`, props.token);
    //     setStates(prev => ({ ...prev, isLoading: false }));
    //     // setCatagories(result.success ? result.data : []);
    //     dispatch({ type: TRUST_CATEGORY, payload: result.success ? result.data : [] })
    // };

    const handleOpenModal = () => {
        // setStates((prevData) => ({ ...prevData, openModal: true }));
        navigation('/AddUpdateService')
    };

    const handleCloseModal = () => {
        setStates((prevData) => ({
            ...prevData,
            openModal: false,
            selectedService: null,
            title: '',
            category: '',
            description: '',
            icon: '',
            is_active: false,
        }));
        setErrors({});
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setStates((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleImageChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            const imageUrl = URL.createObjectURL(file);
            setStates((prevData) => ({ ...prevData, icon: file, iconURL: imageUrl }));
        }
    };

    const validateForm = () => {
        let isValid = true;
        let newErrors = { title: '', category: '', description: '', icon: '' };

        if (!states.title) {
            newErrors.title = 'Title is required';
            isValid = false;
        }
        if (!states.category) {
            newErrors.category = 'Category is required';
            isValid = false;
        }
        if (!states.description) {
            newErrors.description = 'Description is required';
            isValid = false;
        }
        if (!states.icon && !states.selectedService) {
            newErrors.icon = 'Icon is required';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleAddService = async () => {
        if (!validateForm()) {
            return;
        }

        const formData = new FormData();
        formData.append('title', states.title);
        formData.append('category', states.category);
        formData.append('description', states.description);
        formData.append('created_by', states.created_by);
        formData.append('icon', states.icon);
        formData.append('is_active', states.is_active);

        setStates(prev => ({ ...prev, isLoading: true }));

        let result = await props.add_service(`api/add_service?id=${props.user_data.id}`, formData, props.token);
        if (result.success) {
            getServices();
            handleCloseModal();
        }
        setStates(prev => ({ ...prev, isLoading: false }));
    };
    const handleUpdateService = async () => {
        if (!validateForm()) {
            return;
        }
        const formData = new FormData();
        formData.append('service_id', states.selectedService.id);
        formData.append('title', states.title);
        formData.append('category', states.category);
        formData.append('description', states.description);
        {
            states.icon &&
                formData.append('icon', states.icon);
        }
        setStates(prev => ({ ...prev, isLoading: true }));
        let result = await props.update_service(`api/update_service?id=${props.user_data.id}`, formData, props.token);
        if (result.success) {
            getServices();
            handleCloseModal();
        }
        setStates(prev => ({ ...prev, isLoading: false }));
    };

    const handleDeleteService = async (id) => {
        setStates(prev => ({ ...prev, isLoading: true }));
        let result = await props.delete_service(`api/delete_service?id=${props.user_data.id}`, { id: id }, props.token);
        if (result.success) {
            getServices();
        }
        setStates(prev => ({ ...prev, isLoading: false }));
    };

    const onPressUpdate = (row) => {
        navigation('/AddUpdateService', { state: { row } });
        // setStates({
        //     openModal: true,
        //     selectedService: row,
        //     title: row.title,
        //     category: row.category,
        //     description: row.description,
        //     icon: '', // Reset the file input
        //     iconURL: row.icon, // Set the existing icon URL
        //     is_active: row.is_active,
        //     created_by: row.created_by
        // });
    };


    const handleDisable = async (id, status) => {
        setStates(prev => ({ ...prev, isLoading: true }));
        let result = await props.change_service_status(`api/change_service_status?id=${props.user_data.id}`, {
            id: id,
            is_active: !status
        }, props.token);
        if (result.success) {
            getServices();
        }
        setStates(prev => ({ ...prev, isLoading: false }));
    };

    const onUpdateSteps = async (row) => {
        setStates(prev => ({ ...prev, isLoading: true }));
        let result = await props.get_Steps(`api/get_steps?id=${props.user_data.id}&serviceId=${row.service_id}`, props.token)
        setStates(prev => ({ ...prev, isLoading: false }));
        if (result.success) {
            setIsStep(row)
            setServiceSteps(result.data)
        }
    }

    const columns = useMemo(() => [
        { field: 'service_id', headerName: 'ID', flex: 1 },
        {
            field: 'icon',
            headerName: 'Icon',
            flex: 1,
            renderCell: (params) => (
                <img src={params.row.icon} alt="service icon" style={{ width: 50, height: 50, padding: 5 }} />
            ),
        },
        { field: 'title', headerName: 'Title', flex: 1 },
        { field: 'category', headerName: 'Category', flex: 1 },
        { field: 'description', headerName: 'Description', flex: 1 },
        {
            field: 'is_active',
            headerName: 'Status',
            flex: 1,
            renderCell: (params) => (
                params.row.is_active ? (
                    <Chip
                        icon={<CheckCircle style={{ color: 'green' }} />}
                        label="Active"
                        style={{ color: 'green', borderColor: 'green' }}
                        variant="outlined"
                    />
                ) : (
                    <Chip
                        icon={<Cancel style={{ color: 'red' }} />}
                        label="Inactive"
                        style={{ color: 'red', borderColor: 'red' }}
                        variant="outlined"
                    />
                )
            ),
        },
        {
            field: 'action',
            headerName: 'Action',
            width:150,
            renderCell: (params) => (
                <Box display="flex" justifyContent="flex-start" width="100%">
                    {hasPermission("update_service", props.user_data.permissions) &&
                        <IconButton onClick={() => onPressUpdate(params.row)}>
                            <Update fontSize="small" htmlColor='#ffda1f' />
                        </IconButton>
                    }
                    {hasPermission("change_service_status", props.user_data.permissions) &&
                        <IconButton onClick={() => handleDisable(params.row.service_id, params.row.is_active)}>
                            <Block fontSize="small" htmlColor='#ff931f' />
                        </IconButton>
                    }

                    <IconButton onClick={() => onUpdateSteps(params.row)}>
                        <VscTypeHierarchy fontSize="small" htmlColor='#cecece' />
                    </IconButton>

                    {hasPermission("delete_service", props.user_data.permissions) &&
                        <IconButton onClick={() => handleDeleteService(params.row.service_id)}>
                            <Delete fontSize="small" htmlColor='#ff1f1f' />
                        </IconButton>
                    }
                </Box>
            )
        }
    ], []);

    return (
        <Container sx={{ mt: 5 }}>
            <Grid container columns={12} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography variant="h4" style={{ color: headingColor, fontWeight: 'bold' }}>Services</Typography>
                {hasPermission("add_service", props.user_data.permissions) &&
                    <Button variant="outlined" onClick={handleOpenModal}>Create New</Button>
                }
            </Grid>
            <Divider sx={{ mt: 2 }} />
            <Box sx={{ mt: 5 }}>
                <ServicesDataGrid rows={props.servicesData} columns={columns} />
            </Box>

            {states.isLoading && <LoadingSpinner />}
            <ServiceTracking
                isOpen={isSteps ? true : false}
                onClose={() => setIsStep(null)}
                serviceId={isSteps?.service_id}
                existingSteps={serviceSteps}
            />

            {/* <Modal
                open={states.openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Container sx={style}>
                    <Typography variant="h6">{states.selectedService ? 'Update Service' : 'Add New Service'}</Typography>
                    <TextField
                        size="small"
                        label="Title"
                        variant="outlined"
                        fullWidth
                        name="title"
                        value={states.title}
                        onChange={handleInputChange}
                        error={!!errors.title}
                        helperText={errors.title}
                        sx={{ mt: 2 }}
                    />
                    <FormControl fullWidth size="small" sx={{ mt: 2 }}>
                        <InputLabel id="demo-simple-select-label">Select Type</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={states.category}
                            name="category"
                            label="Select Category"
                            onChange={handleInputChange}
                            error={!!errors.category}
                        >
                            {props.trust_category.map((category) => (
                                <MenuItem key={category.dropdown_id} value={category.dropdown_id}>
                                    {category.title}
                                </MenuItem>
                            ))}
                        </Select>
                        {errors.category && <Typography color="error">{errors.category}</Typography>}
                    </FormControl>
                    <TextField
                        size="small"
                        label="Description"
                        variant="outlined"
                        fullWidth
                        name="description"
                        value={states.description}
                        onChange={handleInputChange}
                        error={!!errors.description}
                        helperText={errors.description}
                        multiline
                        rows={4}
                        sx={{ mt: 2 }}
                    />
                    <Box sx={{ width: 150, height: 150 }}>
                        <input
                            accept="image/*"
                            id="icon-file"
                            type="file"
                            onChange={handleImageChange}
                            style={{ display: 'none' }}
                        />
                        <label htmlFor="icon-file">
                            <Paper sx={{ width: 150, height: 150, border: 1, mt: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                {states.iconURL ? (
                                    <img src={states.iconURL} alt="icon" style={{ width: 150, height: 150 }} />
                                ) : (
                                    <ImageIcon style={{ fontSize: 150, color: '#ccc' }} />
                                )}
                            </Paper>
                        </label>
                    </Box>
                    {errors.icon && <Typography color="error">{errors.icon}</Typography>}
                    <Button variant="contained" onClick={states.selectedService ? handleUpdateService : handleAddService} sx={{ mt: 2 }}>
                        {states.selectedService ? 'Update Service' : 'Add Service'}
                    </Button>
                </Container>
            </Modal> */}

        </Container>
    );
};

const mapStateToPros = (state) => ({
    user_data: state.AuthReducer.user_data,
    token: state.AuthReducer.token,
    trust_category: state.rootReducer.trust_category,
    servicesData: state.rootReducer.servicesData,
});

const mapDispatchToProps = {
    get_all_services: get_all_services,
    get_dropdowns_by_key: get_dropdowns_by_key,
    add_service: add_service,
    update_service: update_service,
    change_service_status: change_service_status,
    delete_service: delete_service,
    get_Steps: get_Steps,
};

export default connect(mapStateToPros, mapDispatchToProps)(ViewServices);
