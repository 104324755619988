import { CURRENT_VERSION, GLOBAL_LOADER, IS_LOGIN, TOKEN, USER_DATA } from "../actionType";

// reducers/AuthReducer.js
const initialState = {
    is_login: false,
    user_data: null,
    token: '',
    current_version: null,
    global_loader: false,
};

const AuthReducer = (state = initialState, action) => {
    switch (action.type) {
        case IS_LOGIN:
            return {
                ...state,
                is_login: action.payload,
            };
        case USER_DATA:
            return {
                ...state,
                user_data: action.payload,
            };
        case TOKEN:
            return {
                ...state,
                token: action.payload,
            };
        case CURRENT_VERSION:
            return {
                ...state,
                current_version: action.payload,
            };
        case GLOBAL_LOADER:
            return {
                ...state,
                global_loader: action.payload,
            };
        default:
            return state;
    }
};

export default AuthReducer;
