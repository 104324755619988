import React, { memo, useEffect, useState } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Button, Container, Divider, FormControl, Grid, InputLabel, ListItemIcon, MenuItem, Modal, Select, TextField, Typography } from '@mui/material';
import { connect } from 'react-redux';
import { get_permissions, get_pages, add_permission, update_permission, delete_permission } from '../../redux.js/actions';
import LoadingSpinner from '../../components/loader/LoadingSpinner';
import { headingColor } from '../../util/colors';
import AccountMenu from '../../components/Common/MoreMenu';
import { Delete, Update } from '@mui/icons-material';
import { hasPermission } from '../../util/Fun';


const DataGridViewComponent = memo(({ rows, columns }) => {
    return (
        <DataGrid
            rows={rows}
            columns={columns}
            slots={{ toolbar: GridToolbar }}
            autoHeight
            rowHeight={50}
            getRowId={(row) => row.permission_id}
            initialState={{
                pagination: { paginationModel: { pageSize: 10 } },
            }}
            pagination
            pageSizeOptions={[10, 25, 50, 100]}
        />
    )
})

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const Permission = (props) => {
    const [states, setStates] = useState({
        isLoading: false,
        permission_name: '',
        permission_value: '',
        page_id: '',
        rows: [],
        openModal: false,
        selectedPage: null,
        pages: []
    });
    const [errors, setErrors] = useState({ permission_name: '', permission_value: '', page_id: '' });

    const columns = [
        { field: 'permission_id', headerName: 'Id', flex: 1 },
        { field: 'permission_name', headerName: 'Permission Name', flex: 1 },
        { field: 'permission_value', headerName: 'Permission Value', flex: 1 },
        { field: 'page_name', headerName: 'Page Name', flex: 1 },
        {
            field: 'action',
            headerName: 'Action',
            flex: 1,
            renderCell: (params) => (
                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}>
                    <AccountMenu>
                        {hasPermission("update_role_permissions", props.user_data.permissions) &&
                            <MenuItem onClick={() => onPressUpdate(params.row)}>
                                <ListItemIcon>
                                    <Update fontSize="small" />
                                </ListItemIcon>
                                Update
                            </MenuItem>
                        }
                        {hasPermission("delete_role_permissions", props.user_data.permissions) &&
                            <div>
                                <Divider />
                                <MenuItem onClick={() => handleDeletePage(params.row.permission_id)}>
                                    <ListItemIcon>
                                        <Delete fontSize="small" />
                                    </ListItemIcon>
                                    Delete
                                </MenuItem>
                            </div>
                        }
                    </AccountMenu>
                </div>
            ),
        },
    ];

    useEffect(() => {
        GetPermissions();
        GetPages();
    }, []);

    const GetPages = async () => {
        let result = await props.get_pages(`api/get_pages?id=${props.user_data.id}`, props.token);
        if (result) {
            setStates(prev => ({ ...prev, pages: result.data }));
        }
    };

    const GetPermissions = async () => {
        setStates(prev => ({ ...prev, isLoading: true }));
        let result = await props.get_permissions(`api/get_permissions?id=${props.user_data.id}`, props.token);
        setStates(prev => ({ ...prev, isLoading: false }));
        if (result) {
            setStates(prev => ({ ...prev, rows: result.data }));
        } else {
            setStates(prev => ({ ...prev, rows: [] }));
        }
    };

    const handleCloseModal = () => {
        setStates((prevData) => ({
            ...prevData,
            openModal: false,
            selectedPage: null,
            permission_name: '',
            permission_value: '',
            page_id: ''
        }));
        setErrors({ permission_name: '', permission_value: '', page_id: '' });
    };

    const handleInputChange = (key, value) => {
        setStates((prevData) => ({ ...prevData, [key]: value }));
        setErrors((prevErrors) => ({ ...prevErrors, [key]: '' }));
    };

    const onPressUpdate = (row) => {
        setStates((prevData) => ({
            ...prevData,
            selectedPage: row,
            permission_name: row.permission_name,
            permission_value: row.permission_value,
            page_id: row.page_id,
            openModal: true
        }));
    };

    const handleAddPermission = async () => {
        if (states.permission_name.trim() === '' || states.permission_value.trim() === '') {
            setErrors({
                permission_name: states.permission_name.trim() === '' ? 'Permission name is required' : '',
                permission_value: states.permission_value.trim() === '' ? 'Permission value is required' : '',
            });
            return;
        }
        setStates((prevData) => ({ ...prevData, isLoading: true }));
        await props.add_permission(`api/create_permission?id=${props.user_data.id}`, props.token, {
            permission_name: states.permission_name,
            permission_value: states.permission_value,
            page_id: states.page_id
        });
        handleCloseModal();
        GetPermissions();
    };

    const handleUpdatePermission = async () => {
        if (states.permission_name.trim() === '' || states.permission_value.trim() === '') {
            setErrors({
                permission_name: states.permission_name.trim() === '' ? 'Permission name is required' : '',
                permission_value: states.permission_value.trim() === '' ? 'Permission value is required' : '',
            });
            return;
        }
        setStates((prevData) => ({ ...prevData, isLoading: true }));
        await props.update_permission(`api/update_permission?id=${props.user_data.id}&permission_id=${states.selectedPage.permission_id}`, props.token, {
            permission_name: states.permission_name,
            permission_value: states.permission_value,
            page_id: states.page_id
        });
        handleCloseModal();
        GetPermissions();
    };

    const handleDeletePage = async (id) => {
        setStates((prevData) => ({ ...prevData, isLoading: true }));
        await props.delete_permission(`api/delete_permission?id=${props.user_data.id}`, props.token, { permission_id: id });
        GetPermissions();
    };

    return (
        <Container sx={{ pt: 5 }}>
            <Grid container columns={12} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                <h4 style={{ color: headingColor, fontWeight: 'bold' }}>Permissions</h4>
                {hasPermission("create_permission", props.user_data.permissions) &&
                    <Button variant="outlined" onClick={() => setStates((prevData) => ({ ...prevData, openModal: true }))}>Create New Permission</Button>
                }
            </Grid>
            <DataGridViewComponent
                rows={states.rows}
                columns={columns}
            />
            {states.isLoading && <LoadingSpinner />}

            <Modal
                open={states.openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Container sx={style}>
                    <Typography variant="h6" component="h2">{states.selectedPage ? 'Update Permission' : 'Add New Permission'}</Typography>
                    <TextField
                        size='small'
                        label="Permission Name"
                        variant="outlined"
                        fullWidth
                        value={states.permission_name}
                        onChange={(e) => handleInputChange("permission_name", e.target.value)}
                        error={!!errors.permission_name}
                        helperText={errors.permission_name}
                        sx={{ mt: 2 }}
                    />
                    <TextField
                        size='small'
                        label="Permission Value"
                        variant="outlined"
                        fullWidth
                        value={states.permission_value}
                        onChange={(e) => handleInputChange("permission_value", e.target.value)}
                        error={!!errors.permission_value}
                        helperText={errors.permission_value}
                        sx={{ mt: 2 }}
                    />
                    <FormControl fullWidth size='small' sx={{ mt: 2 }}>
                        <InputLabel id="demo-simple-select-label">Select Page</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={states.page_id}
                            label="Select Page"
                            onChange={(e) => handleInputChange("page_id", e.target.value)}
                        >
                            {states.pages.map((v, i) => (
                                <MenuItem key={i} value={v.id}>{v.page_name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Button variant="contained" onClick={states.selectedPage ? handleUpdatePermission : handleAddPermission} sx={{ mt: 2 }}>
                        {states.selectedPage ? 'Update Permission' : 'Add Permission'}
                    </Button>
                </Container>
            </Modal>
        </Container>
    );
}

const mapStateToPros = (state) => ({
    user_data: state.AuthReducer.user_data,
    token: state.AuthReducer.token,
});

const mapStateToDispatch = {
    get_permissions: get_permissions,
    get_pages: get_pages,
    add_permission: add_permission,
    update_permission: update_permission,
    delete_permission: delete_permission
};

export default connect(mapStateToPros, mapStateToDispatch)(Permission);
