import React, { useEffect, useState } from 'react'
import { FormControl, FormHelperText, InputLabel, Grid, Box, Input, Container, Button, TextField, Select, MenuItem } from '@mui/material';
import { headingColor } from '../util/colors';
import { CreateUser } from '../redux.js/actions/AuthAction';
import { connect, useDispatch } from 'react-redux';
import { get_dropdown_types } from '../redux.js/actions';
import Toast_Container from '../components/Toast_Container';
import { hasPermission } from '../util/Fun';
function NewUser(props) {
    const dispatch = useDispatch()
    const [values, setValues] = useState({
        full_name: '',
        email: '',
        phone: '',
        address: '',
        username: '',
        password: '',
        confirm_password: '',
        role_id: '',
        user_type: "",
        district: '',
    });

    const [errors, setErrors] = useState({
        full_name: '',
        email: '',
        phone: '',
        password: '',
        confirm_password: '',
        role_id: '',
        district: ''
    });

    const handleChange = name => event => {
        if (name == 'role_id') {
            const role = props.all_dropdowns.roles.find(value => value.role_id == event.target.value)
            setValues({
                ...values,
                [name]: event.target.value,
                user_type: role.role_name == 'user' ? role.role_name : 'admin'
            });
        } else {
            setValues({ ...values, [name]: event.target.value });
        }
        setErrors({ ...errors, [name]: false });
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        let formErrors = {};
        let hasErrors = false;
        const phoneNumberRegex = /^92[3-9]\d{9}$/;
        if (!values.full_name) {
            formErrors.full_name = "fullname is required.";
            hasErrors = true;
        }
        if (!values.email) {
            formErrors.email = "Email is required";
            hasErrors = true;
        }
        if (!values.phone) {
            formErrors.phone = "Phone is required";
            hasErrors = true;
        }
        if (values.phone && !phoneNumberRegex.test(values.phone)) {
            formErrors.phone = "Enter valid phone number ex 923xxxxxxxxx.";
            hasErrors = true;
        }
        if (!values.password) {
            formErrors.password = "Password is required";
            hasErrors = true;
        }
        if (!values.confirm_password) {
            formErrors.confirm_password = "Confirm password is required";
            hasErrors = true;
        }
        if (!values.role_id) {
            formErrors.role_id = "User role is required.";
            hasErrors = true;
        }
        if (values.user_type != 'user' && values.user_type) {
            if (!values.district) {
                formErrors.role_id = "District is required.";
                hasErrors = true;
            }
        }
        if ((values.password && values.confirm_password) && (values.password !== values.confirm_password)) {
            formErrors.confirm_password = "Confirm password does not match with password.";
            hasErrors = true;
        }

        if (hasErrors) {
            setErrors(formErrors);
        } else {
            let result = await props.CreateUser(`api/create_account?id=${props.user_data.id}`, values, props.token)
            if (result) {
                ClearState()
            }
        }
    };
    const ClearState = () => {
        setValues({
            full_name: '',
            email: '',
            phone: '',
            address: '',
            username: '',
            password: '',
            confirm_password: '',
            role_id: '',
            user_type: "",
            district: ''
        });
    }
    return (
        <Container sx={{ pt: 5 }}>
            <Grid container columns={12} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                <h4 style={{ color: headingColor, fontWeight: 'bold' }}>Register new user</h4>
            </Grid>
            <form onSubmit={handleSubmit}>
                <Box sx={{ flexGrow: 1, pt: 1 }}>
                    <Grid container spacing={{ xs: 0, md: 1 }} columns={{ xs: 1, sm: 8, md: 12 }}>
                        <Grid item xs={1} sm={4} md={4}>
                            <TextField
                                size='small'
                                id="standard-name"
                                label="Name"
                                fullWidth
                                value={values.full_name}
                                onChange={handleChange("full_name")}
                                margin="dense"
                                error={Boolean(errors.full_name)}
                                type='text'
                            />
                            {errors.name && <FormHelperText error>{errors.name}</FormHelperText>}
                        </Grid>
                        <Grid item xs={1} sm={4} md={4}>
                            <TextField
                                id="standard-name"
                                label="Email"
                                fullWidth
                                value={values.email}
                                onChange={handleChange("email")}
                                size='small'
                                margin="dense"
                                error={Boolean(errors.email)}

                            />
                            {errors.email && <FormHelperText error>{errors.email}</FormHelperText>}
                        </Grid>
                        <Grid item xs={1} sm={4} md={4}>
                            <TextField
                                id="standard-name"
                                label="Phone"
                                type='number'
                                fullWidth
                                value={values.phone}
                                onChange={handleChange("phone")}
                                size='small'
                                margin="dense"

                                error={Boolean(errors.phone)}
                            />
                            {errors.phone && <FormHelperText error>{errors.phone}</FormHelperText>}
                        </Grid>

                        <Grid item xs={1} sm={4} md={4}>
                            <TextField
                                id="standard-name"
                                label="Address"
                                fullWidth
                                value={values.address}
                                onChange={handleChange("address")}
                                size='small'
                                margin="dense"

                                error={Boolean(errors.address)}
                            />
                            {errors.address && <FormHelperText error>{errors.address}</FormHelperText>}
                        </Grid>
                        <Grid item xs={1} sm={4} md={4}>
                            <FormControl sx={{ mt: 1 }} size='small' fullWidth>
                                <InputLabel id="demo-simple-select-helper-label">Role Type</InputLabel>
                                <Select
                                    fullWidth
                                    size='small'
                                    margin="dense"
                                    value={values.role_id}
                                    label="Role Type"
                                    onChange={handleChange("role_id")}
                                    error={Boolean(errors.role_id)}
                                >
                                    {props.all_dropdowns.roles && props.all_dropdowns.roles.length > 0 ? (
                                        props.all_dropdowns.roles.map((v, i) => (
                                            <MenuItem key={i} value={v.role_id}>{v.role_name}</MenuItem>
                                        ))
                                    ) : null}
                                </Select>
                                {errors.role_id && <FormHelperText error>{errors.role_id}</FormHelperText>}
                            </FormControl>
                        </Grid>

                        {values.user_type != 'user' && values.user_type &&
                            <Grid item xs={1} sm={4} md={4}>
                                <FormControl sx={{ mt: 1 }} size='small' fullWidth>
                                    <InputLabel id="demo-simple-select-helper-label">Select Districts</InputLabel>
                                    <Select
                                        fullWidth
                                        size='small'
                                        margin="dense"
                                        value={values.district}
                                        label="Select Districts"
                                        onChange={handleChange("district")}
                                        error={Boolean(errors.district)}
                                    >
                                        {props.all_dropdowns?.districts && props.all_dropdowns.districts.length > 0 ? (
                                            props.all_dropdowns.districts.map((v, i) => (
                                                <MenuItem key={i} value={v.dropdown_id}>{v.title}</MenuItem>
                                            ))
                                        ) : null}
                                    </Select>
                                    {errors.district && <FormHelperText error>{errors.district}</FormHelperText>}
                                </FormControl>
                            </Grid>
                        }
                        <Grid item xs={1} sm={4} md={4}>
                            <TextField
                                id="standard-name"
                                label="Password"
                                fullWidth
                                value={values.password}
                                onChange={handleChange("password")}
                                size='small'
                                margin="dense"
                                error={Boolean(errors.password)}
                            />
                            {errors.password && <FormHelperText error>{errors.password}</FormHelperText>}
                        </Grid>
                        <Grid item xs={1} sm={4} md={4}>
                            <TextField
                                id="standard-name"
                                label="Confirm Password"
                                fullWidth
                                value={values.confirm_password}
                                onChange={handleChange("confirm_password")}
                                size='small'
                                margin="dense"
                                error={Boolean(errors.confirm_password)}
                            />
                            {errors.confirm_password && <FormHelperText error>{errors.confirm_password}</FormHelperText>}
                        </Grid>
                    </Grid>
                    <Button variant="contained" color="primary" type="submit" sx={{ mt: 2 }}>
                        CREATE USER
                    </Button>
                </Box>
            </form>
            {/* <Toast_Container /> */}
        </Container>
    )
}
const mapSateToProps = (state) => ({
    user_data: state.AuthReducer.user_data,
    token: state.AuthReducer.token,
    all_dropdowns: state.rootReducer.all_dropdowns,
})
const mapStateToDispatch = {
    CreateUser: CreateUser,
    get_dropdown_types: get_dropdown_types,
}

export default connect(mapSateToProps, mapStateToDispatch)(NewUser)