import React, { useState, useEffect, useRef } from 'react';
import { Button, TextField, Avatar, Paper, Box, Typography, List, ListItem, ListItemIcon, ListItemText, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AttachFile from '@mui/icons-material/AttachFile';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import { connect } from 'react-redux';
import { AddUserQueries, getUserFlowUps } from '../../redux.js/actions';
import LoadingSpinner from '../loader/LoadingSpinner';
import { hasPermission } from '../../util/Fun';
import { toast } from 'react-toastify';

const primaryColor = '#111927'; // Primary color

function UserFlowUp({ token, user_data, AddUserQueries, item, onCLose, getUserFlowUps }) {
    const [message, setMessage] = useState('');
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [allMessages, setAllMessages] = useState([]);
    const scrollableContentRef = useRef(null);
    useEffect(() => {
        if (hasPermission("get_user_flowUps", user_data.permissions)) {
            GetUserFlowUps()
        }
    }, []);
    useEffect(() => {
        if (scrollableContentRef.current) {
            scrollableContentRef.current.scrollTop = scrollableContentRef.current.scrollHeight;
        }
    }, [allMessages]);

    const GetUserFlowUps = async () => {
        let result = await getUserFlowUps(`api/get_user_flowUps?id=${user_data.id}&instance_id=${item.registration_id}`, token)
        if (result) {
            setAllMessages(result)
        }
    }

    const handleOnCLose = () => {
        onCLose();
    };

    const handleFileChange = (event) => {
        setSelectedFiles([...selectedFiles, ...Array.from(event.target.files)]);
    };

    const handleFileRemove = (index) => {
        setSelectedFiles(selectedFiles.filter((_, i) => i !== index));
    };
    const SendMessage = async () => {
        if (!message) {
            return;
        }
        if (!hasPermission("add_user_flowUp", user_data.permissions)) {
            return toast("Sorry you do not have permission to perform this action.", { type: 'error' });
        }
        const newMessage = {
            message: message,
            user_type: 'admin',
            file_paths: []
        };

        if (selectedFiles.length > 0) {
            newMessage.file_paths = selectedFiles.map(file => ({
                file_name: file.name,
                file_path: URL.createObjectURL(file)
            }));
        }

        setAllMessages(prevMessages => [...prevMessages, newMessage]);

        setIsLoading(true);

        const formData = new FormData();
        formData.append('instance_id', item.registration_id);
        formData.append('message', message);
        formData.append('user_type', 'admin');
        formData.append('noti_type', 'query_noti');

        for (const file of selectedFiles) {
            formData.append('files', file);
        }

        // Send the message to the backend
        let result = await AddUserQueries(`api/add_user_flowUp?id=${user_data.id}`, token, formData);

        // Clear the input fields and loading state
        setMessage('');
        setSelectedFiles([]);
        setIsLoading(false);
    };

    const handleFileClick = (filePath) => {
        const fileName = filePath.split('/').pop();
        const fileExtension = fileName.split('.').pop().toLowerCase();

        const viewableExtensions = ['pdf', 'jpg', 'jpeg', 'png', 'gif', 'bmp'];

        if (viewableExtensions.includes(fileExtension)) {
            window.open(filePath, '_blank');
        } else {
            const link = document.createElement('a');
            link.href = filePath;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };


    return (
        <Paper
            elevation={5}
            sx={{
                position: 'fixed',
                bottom: 16,
                right: 16,
                padding: 2,
                width: 500,
                maxHeight: '80vh',
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                borderRadius: 2,
                boxShadow: 3,
                bgcolor: primaryColor,
                color: 'white',
            }}
        >
            <Box display="flex" alignItems="center" mb={2} sx={{ position: 'relative' }}>
                <Avatar sx={{ mr: 1, bgcolor: '#00796b' }}>{item.full_name.charAt(0).toUpperCase()}</Avatar>
                <Box>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white' }}>{item.full_name}</Typography>
                    <Typography variant="body2" sx={{ color: '#b0bec5' }}>{item.email}</Typography>
                </Box>
                <IconButton sx={{ position: 'absolute', top: 8, right: 8 }} onClick={() => handleOnCLose()}>
                    <CloseIcon sx={{ color: '#b0bec5' }} />
                </IconButton>
            </Box>

            <Box mb={2} ref={scrollableContentRef} sx={{ overflowY: 'auto' }}>
                <Typography variant="h6" gutterBottom sx={{ color: 'white' }}>Messages</Typography>
                <List dense >
                    {allMessages.map((v, i) => {
                        return (
                            <div key={i}>
                                {
                                    v.user_type == "admin" ?
                                        <ListItem sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', }}>
                                            <Box sx={{ bgcolor: '#263238', padding: 1, borderRadius: 1, maxWidth: '80%' }}>
                                                <ListItemText primary={v.message} sx={{ color: 'white', wordWrap: 'break-word', overflowWrap: 'break-word', whiteSpace: 'normal' }} />
                                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                    {v.file_paths.length > 0 &&
                                                        v.file_paths.map((files, _i) => {
                                                            return (
                                                                <Box key={_i} sx={{ display: 'flex', alignItems: 'center', bgcolor: '#37474f', padding: 1, borderRadius: 1, mt: 1 }}>
                                                                    <ListItemIcon sx={{ minWidth: 40 }}>
                                                                        <AttachFile sx={{ color: 'white' }} />
                                                                    </ListItemIcon>
                                                                    <ListItemText primary={files.file_name} sx={{ color: 'white', cursor: 'pointer' }}
                                                                        onClick={() => handleFileClick(files.file_path)}
                                                                    />
                                                                </Box>
                                                            )

                                                        })

                                                    }
                                                </Box>
                                            </Box>
                                        </ListItem>
                                        :
                                        <ListItem sx={{ justifyContent: 'flex-end', }}>
                                            <Box
                                                sx={{
                                                    backgroundColor: '#004d40',
                                                    color: 'white',
                                                    borderRadius: 1,
                                                    padding: 1,
                                                    border: '1px solid',
                                                    borderColor: '#004d40',
                                                    maxWidth: '80%',
                                                    textAlign: 'right',
                                                }}
                                            >
                                                <ListItemText primary={v.message} />
                                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', wordWrap: 'break-word', overflowWrap: 'break-word', whiteSpace: 'normal' }}>
                                                    {v.file_paths.length > 0 &&
                                                        v.file_paths.map((files, _i) => {
                                                            return (
                                                                <Box key={_i} sx={{ display: 'flex', alignItems: 'center', bgcolor: '#00796b', padding: 1, borderRadius: 1, mt: 1 }}>
                                                                    <ListItemIcon sx={{ minWidth: 40 }}>
                                                                        <AttachFile sx={{ color: 'white' }} />
                                                                    </ListItemIcon>
                                                                    <ListItemText primary={files.file_name} sx={{ color: 'white', textAlign: 'start', cursor: 'pointer' }}
                                                                        onClick={() => handleFileClick(files.file_path)}
                                                                    />
                                                                </Box>
                                                            )
                                                        })
                                                    }
                                                </Box>

                                            </Box>
                                        </ListItem>
                                }
                            </div>
                        )

                    })}
                </List>
            </Box>

            <Box display="flex" flexDirection="column" gap={0}>
                <Box sx={{ overflowY: 'auto', maxHeight: 200 }}>
                    {selectedFiles.length > 0 && (
                        <Box display="flex" flexDirection="column">
                            <Typography variant="body2" sx={{ color: 'white', mb: 1 }}>Attached Files:</Typography>
                            <List dense>
                                {selectedFiles.map((file, index) => (
                                    <ListItem key={index} sx={{ display: 'flex', alignItems: 'center' }}>
                                        <ListItemIcon>
                                            <AttachFile sx={{ color: 'white' }} />
                                        </ListItemIcon>
                                        <ListItemText primary={file.name} sx={{ color: 'white' }} />
                                        <IconButton onClick={() => handleFileRemove(index)}>
                                            <DeleteIcon sx={{ color: 'white' }} />
                                        </IconButton>
                                    </ListItem>
                                ))}
                            </List>
                        </Box>
                    )}
                </Box>
                <Box display="flex" flexDirection={'row'} alignItems={'flex-end'} gap={1}>
                    <TextField
                        variant="outlined"
                        placeholder="Type your message..."
                        multiline
                        maxRows={2}
                        fullWidth
                        size='small'
                        sx={{ bgcolor: 'white', color: 'black', borderRadius: 1, }}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    />
                    <Box display="flex" flexDirection="column" gap={1}>
                        <Box display="flex" flexDirection="row" gap={1}>
                            <Button
                                size='small'
                                variant="contained"
                                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', bgcolor: '#00796b', color: 'white' }}
                                onClick={SendMessage}
                            >
                                <SendIcon />
                            </Button>
                            <Button
                                size='small'
                                variant="outlined"
                                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#00796b', borderColor: '#00796b' }}
                                component="label"
                            >
                                <AttachFileIcon />
                                <input
                                    type="file"
                                    hidden
                                    multiple
                                    onChange={handleFileChange}
                                />
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>

            {isLoading && <LoadingSpinner />}
        </Paper >
    );
}


const mapStateToPros = (state) => ({
    token: state.AuthReducer.token,
    user_data: state.AuthReducer.user_data,
})
const mapStateToDispatch = {
    AddUserQueries,
    getUserFlowUps
}


export default connect(mapStateToPros, mapStateToDispatch)(UserFlowUp)
