import { ACTIVE_PORTAL_USER, ADD_NEW_SERVICE_CATEGORY, ADD_NOTIFICATION, ADD_SERVICE, ALL_DROPDOWNS, ALL_SERVICE_DATA, CHANGE_SERVICE_STATUS, DASHBOARD_COUNT, DROPDOWN_TYPES, INPUT_TYPES, NOTIFICATIONS, READ_NOTIFICATIONS, REGISTRATIONS, SERVICE_CATEGORY, TRUST_CATEGORY, TRUST_TYPES, UPDATE_INSTANCE_STATUS, USER_ROLE } from "../actionType";

// reducers/rootReducer.js
const initialState = {
  serviceFormData: {},
  servicesData: [],
  all_registration: [],
  dashboard_count: null,
  user_role: [],
  trust_types: [],
  trust_category: [],
  input_types: [],
  dropdown_types: [],
  active_portal_user: [],
  all_dropdowns: {},
  notifications: []
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_SERVICE:
      return {
        ...state,
        serviceFormData: action.payload,
      };
    case ALL_SERVICE_DATA:
      return {
        ...state,
        servicesData: action.payload,
      };
    case ADD_NEW_SERVICE_CATEGORY:
      return {
        ...state,
        service_categories: [...state.service_categories, action.payload],
      };
    case REGISTRATIONS:
      return {
        ...state,
        all_registration: action.payload,
      };
    case UPDATE_INSTANCE_STATUS:
      return {
        ...state,
        all_registration: state.all_registration.map(registration => {
          if (registration.instance.id === action.payload.instance_id) {
            return {
              ...registration,
              instance: {
                ...registration.instance,
                status: action.payload.newStatus
              }
            };
          }
          return registration;
        })
      };
    case DASHBOARD_COUNT:
      return {
        ...state,
        dashboard_count: action.payload,
      };

    case CHANGE_SERVICE_STATUS:
      const updatedServices = state.servicesData.map(service => {
        if (service.id === action.payload.id) {
          return {
            ...service,
            is_active: action.payload.is_active,
          };
        }
        return service;
      });
      return {
        ...state,
        servicesData: updatedServices,
      };
    case USER_ROLE:
      return {
        ...state,
        user_role: action.payload,
      };
    case DROPDOWN_TYPES:
      return {
        ...state,
        dropdown_types: action.payload,
      };
    case ALL_DROPDOWNS:
      // console.log("reducer",action.payload)
      return {
        ...state,
        all_dropdowns: {
          ...state.all_dropdowns,
          ...action.payload
        },
      };
    case TRUST_TYPES:
      return {
        ...state,
        trust_types: action.payload,
      };
    case TRUST_CATEGORY:
      return {
        ...state,
        trust_category: action.payload,
      };
    case INPUT_TYPES:
      return {
        ...state,
        input_types: action.payload,
      };
    case ACTIVE_PORTAL_USER:
      return {
        ...state,
        active_portal_user: action.payload,
      };
    case NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
      };
    case ADD_NOTIFICATION:
      return {
        ...state,
        notifications: [action.payload, ...state.notifications],
      };
    case READ_NOTIFICATIONS:
      return {
        ...state,
        notifications: state.notifications.map(notification =>
          Array.isArray(action.payload)
            ? action.payload.includes(notification.id)
              ? { ...notification, is_read: true }
              : notification
            : notification.id === action.payload.id
              ? { ...notification, is_read: true }
              : notification
        ),
      };
    default:
      return state;
  }
};

export default rootReducer;
